import baseApi from './base';

const printProjectApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProject: builder.query({
      query: (projectUuid) => ({
        url: `/project/${projectUuid}`,
        method: 'GET'
      })
    }),
    createProject: builder.mutation({
      query: (data) => ({
        url: '/project',
        method: 'POST',
        body: data
      }),
    }),
  })
});

export const {
  useGetProjectQuery,
  useCreateProjectMutation,
} = printProjectApi;
