import PropTypes from 'prop-types';
import React from 'react';
import { BlueInfoIcon } from '../assets/images';
import IconPaddedText from './IconPaddedText';

const FormGenericErrorBanner = ({ children }) => (
  <div className="flex justify-center">
    <div
      className="w-full rounded bg-lightBlue px-4 py-4 text-photobookBlue"
    >
      <IconPaddedText
        icon={<img src={BlueInfoIcon} alt="info icon" />}
        className="!ml-4"
        text={children}
      />
    </div>
  </div>
);

FormGenericErrorBanner.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FormGenericErrorBanner;
