import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NotFoundIcon } from '../assets/images';
import { Button } from '../components/button';
import { TRANS_NAMESPACES } from '../services/i18next';

const NotFound = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.notFound);
  const navigate = useNavigate();

  return (
    <div className="h-screen flex flex-col justify-center items-center gap-y-8 px-4 py-8">
      <img src={NotFoundIcon} alt="not found" />
      <p className="text-2xl text-photobookBlue uppercase">{t('header', '#404 Error')}</p>
      <div className="flex flex-col text-center">
        <p>{t('content_line1', 'We are sorry, the page you are looking for does not exist.')}</p>
        <p>{t('content_line2', 'Contact our support team at support@photobookworldwide.com and let us know so that we can address this issue. Thank you.')}</p>
      </div>
      <Button
        type="button"
        color="primary"
        variant="contained"
        onClick={() => navigate('/')}
      >
        {t('go_back_cta', 'Go back to home page')}
      </Button>
    </div>
  );
};

export default NotFound;
