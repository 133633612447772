import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { WhiteTickIcon } from '../../assets/images';
import useUserType from '../../hooks/useUserType';
import { enterSelectionMode, addToSelection } from '../../slices/download';
import { setAuthModalOpen } from '../../slices/modal';

const DownloadSelectionToggle = ({
  photoUuid, visible, on, setShowSelectionToggle, inSelectionModes
}) => {
  const { isAccountHolder } = useUserType();
  const dispatch = useDispatch();

  if (!visible) {
    return null;
  }

  const handleClick = () => {
    if (isAccountHolder) {
      dispatch(enterSelectionMode());
      dispatch(addToSelection(photoUuid));
      setShowSelectionToggle(false);
    } else {
      dispatch(setAuthModalOpen());
    }
  };

  if (on) {
    return (
      <button type="button" className="absolute top-2 left-2 w-8 h-8 rounded-full bg-photobookBlue flex justify-center items-center">
        <img className="w-5" src={WhiteTickIcon} alt="white tick" />
      </button>
    );
  }

  return (
    <button {...(inSelectionModes ? {} : { onClick: handleClick })} type="button" className="absolute top-2 left-2 w-8 h-8 rounded-full border-2 border-white" aria-label="select" data-abtestid="selectPhotoButton" />
  );
};

DownloadSelectionToggle.propTypes = {
  photoUuid: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  on: PropTypes.bool.isRequired,
  setShowSelectionToggle: PropTypes.func.isRequired,
  inSelectionModes: PropTypes.bool.isRequired
};

export default DownloadSelectionToggle;
