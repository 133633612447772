import PropTypes from 'prop-types';
import {
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from 'pure-react-carousel';
import React, { useEffect, useRef, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SlideshowLeftArrow,
  SlideshowRightArrow,
  SlideshowCloseIcon,
  SlideshowPlay,
  SlideshowPause,
} from '../../assets/images';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setIsPlaying } from '../../slices/liveMode';
import { noop } from '../../utils/generic';

const dontShowContextMenu = (e) => e.preventDefault();

const LiveMode = ({ currentPhotoIndex, photoList }) => {
  const controlsTimeout = useRef(null);
  const { photobookUrlIdentifier } = useParams();
  const isPlaying = useSelector((state) => state.liveMode.isPlaying);
  const dispatch = useDispatch();
  const isLargeScreen = useLargeScreenMediaQuery();
  const [showControls, setShowControls] = useState(false);
  const { t } = useTranslation(TRANS_NAMESPACES.liveMode);
  const navigate = useNavigate();
  const CONTROLS_DISAPPEAR_INTERVAL = 3000;
  const photoUuidExistCount = [];

  const constructName = () => {
    const image = photoList[currentPhotoIndex];
    if (image === undefined) {
      return '';
    }

    const name = `${image.user.first_name} ${image.user.last_name}`;
    return name;
  };

  const handleShowControlsAndHideAfterInterval = () => {
    clearTimeout(controlsTimeout.current);
    setShowControls(true);
    controlsTimeout.current = setTimeout(() => {
      clearTimeout(controlsTimeout.current);
      setShowControls(false);
    }, CONTROLS_DISAPPEAR_INTERVAL);
  };

  const handleHideControls = () => {
    clearTimeout(controlsTimeout.current);
    setShowControls(false);
  };

  const handleShowControls = () => {
    clearTimeout(controlsTimeout.current);
    setShowControls(true);
  };

  const getPhotoUuidUniqueKey = (photoUuid) => {
    const photoUuidCount = photoUuidExistCount[photoUuid];
    if (photoUuidCount !== undefined) {
      photoUuidExistCount[photoUuid] += 1;
    } else {
      photoUuidExistCount[photoUuid] = 1;
    }
    return `${photoUuid}-${photoUuidExistCount[photoUuid]}`;
  };

  useEffect(() => () => clearTimeout(controlsTimeout), []);

  return (
    <div className="bg-black">
      <Slider
        onMouseMove={handleShowControlsAndHideAfterInterval}
        {...(isLargeScreen && { onMouseOut: handleHideControls })}
      >
        {photoList.map((photo, i) => (
          <Slide
            key={`${getPhotoUuidUniqueKey(photo.uuid)}`}
            innerClassName="flex justify-center items-center"
            index={i}
          >
            <Image onContextMenu={dontShowContextMenu} className="max-h-full object-scale-down" alt={`photo ${i + 1}`} src={photo.image.print} />
          </Slide>
        ))}
      </Slider>
      {
        showControls && (
          <>
            <div
              className="max-w-[96%] lg:max-w-[99%] absolute bottom-2 left-2 rounded-lg bg-[rgba(68,68,68,0.48)] drop-shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]"
              onMouseOver={handleShowControls}
              onFocus={noop}
            >
              <p className="overflow-hidden py-2 px-4 text-center font-bold text-white text-ellipsis whitespace-nowrap" data-testid="uploaderName" data-abtestid="uploaderName">
                {constructName()}
              </p>
            </div>
            <div
              className="absolute top-2 left-2 rounded-lg bg-[rgba(68,68,68,0.48)] drop-shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]"
              onMouseOver={handleShowControls}
              onFocus={noop}
            >
              <p className="py-2 px-4 text-center text-white" data-testid="numPhotos" data-abtestid="numPhotos">
                {t('live_mode_num_photos', `${currentPhotoIndex + 1} of ${photoList.length}`, { currentPhoto: currentPhotoIndex + 1, photoCount: photoList.length })}
              </p>
            </div>
            <button
              className="absolute top-2 right-2 flex justify-center items-center h-14 w-14 rounded-full bg-[rgba(68,68,68,0.48)] drop-shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]"
              onMouseOver={handleShowControls}
              onClick={() => navigate(`/photobook/${photobookUrlIdentifier}`)}
              onFocus={noop}
              data-testid="close-button"
              data-abtestid="closeButton"
              type="button"
            >
              <img
                src={SlideshowCloseIcon}
                alt="slideshow close icon"
              />
            </button>
            <ButtonBack
              className="absolute top-1/2 left-2 -translate-y-1/2 flex justify-center items-center h-14 w-14 rounded-full bg-[rgba(68,68,68,0.48)] drop-shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]"
              {...(isLargeScreen && { onMouseOver: handleShowControls })}
              {...(!isLargeScreen && { onTouchEnd: handleShowControlsAndHideAfterInterval })}
              onClick={() => dispatch(setIsPlaying(false))}
              data-abtestid="liveModePreviousImage"
            >
              <img alt="slideshow left arrow" src={SlideshowLeftArrow} />
            </ButtonBack>
            <ButtonNext
              id="test"
              className="absolute top-1/2 right-2 -translate-y-1/2 flex justify-center items-center h-14 w-14 rounded-full bg-[rgba(68,68,68,0.48)] drop-shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]"
              {...(isLargeScreen && { onMouseOver: handleShowControls })}
              {...(!isLargeScreen && { onTouchEnd: handleShowControlsAndHideAfterInterval })}
              onClick={() => dispatch(setIsPlaying(false))}
              data-abtestid="liveModeNextImage"
            >
              <img alt="slideshow right arrow" src={SlideshowRightArrow} />
            </ButtonNext>
            <button
              type="button"
              className="absolute inset-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center items-center h-14 w-14 rounded-full bg-[rgba(68,68,68,0.48)] drop-shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]"
              {...(isLargeScreen && { onMouseOver: handleShowControls })}
              {...(!isLargeScreen && { onTouchEnd: handleShowControlsAndHideAfterInterval })}
              data-abtestid="liveModePlayPause"
              onClick={() => dispatch(setIsPlaying(!isPlaying))}
            >
              {
              (isPlaying)
                ? <img alt="slideshow pause" src={SlideshowPause} />
                : <img alt="slideshow play" src={SlideshowPlay} />
              }
            </button>
          </>
        )
      }
    </div>
  );
};

LiveMode.propTypes = {
  currentPhotoIndex: PropTypes.number.isRequired,
  photoList: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    image: PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
      print: PropTypes.string.isRequired
    }),
    user: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  })),
};

export default LiveMode;
