import { useMemo, useEffect, useState } from 'react';

const useIsImageOwner = (gallery, photoUuid, userUuid, guestToken) => {
  const [isImageOwner, setIsImageOwner] = useState(false);
  const targetedImage = useMemo(() => gallery.find(
    (photo) => photo.uuid === photoUuid
  ), [gallery, photoUuid]);

  useEffect(() => {
    setIsImageOwner(targetedImage && (userUuid === targetedImage.user.uuid
      || guestToken === targetedImage.user.uuid));
  }, [targetedImage, userUuid, guestToken]);

  return isImageOwner;
};

export default useIsImageOwner;
