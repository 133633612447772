import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setAddPhotosModalClose } from '../../slices/modal';
import { commaSeparateNumber } from '../../utils/generic';
import {
  EmptyPhotoGallery,
  PhotoGallery,
  SortOptions,
  SortOptionsMobile
} from '../photo-gallery';
import AddPhotosModal from './AddPhotosModal';

const PhotoPane = ({ list }) => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const photoCount = list.length;
  const addPhotosModalOpen = useSelector((state) => state.modal.addPhotosModalOpen);
  const isLargeScreen = useLargeScreenMediaQuery();
  const dispatch = useDispatch();

  return (
    photoCount > 0
      ? (
        <div className="relative flex flex-col flex-1 mt-[60px]">
          <div className="flex items-center h-6 px-4 lg:px-8 mt-3 lg:mt-6 mb-1 lg:mb-2">
            <p data-abtestid="photoPanePhotoCount" className="leading-6 text-darkGrey">{t('photo_pane_photo_count', `${commaSeparateNumber(photoCount)} ${photoCount === 1 ? 'photo' : 'photos'}`, { count: photoCount })}</p>
            {
            isLargeScreen
              ? <SortOptions />
              : <SortOptionsMobile />
            }
          </div>
          <PhotoGallery list={list} />
          <AddPhotosModal
            open={addPhotosModalOpen}
            handleClose={() => dispatch(setAddPhotosModalClose())}
          />
        </div>
      )
      : <EmptyPhotoGallery />
  );
};

export default PhotoPane;

PhotoPane.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    image: PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
      print: PropTypes.string
    }),
    user: PropTypes.shape({
      uuid: PropTypes.string,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  })),
};
