import PropTypes from 'prop-types';
import React from 'react';

const IconPaddedText = ({
  icon,
  text,
  className = '',
  textOnLargeScreenOnly = false
}) => (
  <span className="flex items-center">
    {icon}
    <span className={`${textOnLargeScreenOnly ? 'hidden lg:block' : ''} ${className} ml-2`}>{text}</span>
  </span>
);

IconPaddedText.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  textOnLargeScreenOnly: PropTypes.bool,
};

export default IconPaddedText;
