import PropTypes from 'prop-types';
import React from 'react';
import Nav from './Nav';

const Navs = ({
  items,
  className,
  itemClassName,
  activeItemClassName,
  activeTab,
  setActiveTab
}) => {
  const navs = items.map((item) => {
    const isActive = activeTab === item.id;

    return (
      <Nav
        className={isActive ? `cursor-auto ${itemClassName} ${activeItemClassName}` : `cursor-pointer ${itemClassName}`}
        key={item.id}
        onClick={isActive ? undefined : () => setActiveTab(item.id)}
        abTestId={item.id}
      >
        {item.text}
      </Nav>
    );
  });

  return (
    <nav className={className}>
      {navs}
    </nav>
  );
};

Navs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string.isRequired,
  itemClassName: PropTypes.string.isRequired,
  activeItemClassName: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default Navs;
