import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANS_NAMESPACES } from '../../services/i18next';

const LiveModeTransitionPage = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.liveMode);
  return (
    <div className="h-full bg-black" data-abtestid="liveModeTransitionPage">
      <div className="absolute inset-1/2 flex h-fit w-fit -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full bg-white ">
        <p className="px-8 py-4 whitespace-nowrap" data-abtestid="liveModeTransitionPagePrompt">{t('live_mode_transition_page_prompt', 'Entering Live Mode')}</p>
      </div>
    </div>
  );
};

export default LiveModeTransitionPage;
