import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PhotobookLiveLogo } from '../../assets/images';
import { TRANS_NAMESPACES } from '../../services/i18next';

const AccountTopNavigation = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.account);
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-start border border-fog h-[60px] py-[18px] px-4 lg:px-8">
      <div className="w-36 hover:cursor-pointer" onClick={() => (navigate('/'))}>
        <img src={PhotobookLiveLogo} alt="Photobook Live logo" data-abtestid="accountTopNavigationHomeLogo" />
      </div>
      <div className="flex items-center justify-center mx-2 text-xl h-7 w-52">
        {t('account_information', 'Account Information')}
      </div>
    </div>
  );
};

export default AccountTopNavigation;
