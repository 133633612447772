import baseApi from './base';

const libraryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLibrary: builder.query({
      query: (params) => ({
        url: `/library?${new URLSearchParams(params).toString()}`,
        method: 'GET',
      }),
      transformResponse: (response, meta, args) => {
        const { details } = args;

        if (details) {
          const sortedData = response.data.sort(
            (a, b) => b.updated_at - a.updated_at
          );

          return { ...response, data: sortedData };
        }

        return response;
      }
    }),
    addToLibrary: builder.mutation({
      query: (data) => ({
        url: '/library',
        method: 'POST',
        body: data
      })
    }),
    removeFromLibrary: builder.mutation({
      query: (data) => ({
        url: '/library',
        method: 'DELETE',
        body: data
      })
    })
  })
});

export const {
  useGetLibraryQuery,
  useAddToLibraryMutation,
  useRemoveFromLibraryMutation
} = libraryApi;
