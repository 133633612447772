import PropTypes from 'prop-types';
import React from 'react';

const Nav = ({
  children,
  className,
  onClick,
  abTestId,
}) => (
  <div onClick={onClick} className={className} data-abtestid={abTestId}>{children}</div>
);

Nav.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  abTestId: PropTypes.string,
};

export default Nav;
