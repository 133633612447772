import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';
import { TRANS_NAMESPACES } from '../../services/i18next';

const TermsOfUseModal = ({ open, closeModal }) => {
  const { t } = useTranslation(TRANS_NAMESPACES.termsOfUse);

  return (
    <Modal open={open} className="lg:my-12 lg:w-[880px]" handleClose={closeModal}>
      <div className="relative w-fit bottom-8">
        <span className="text-2xl">{t('terms_of_use', 'Terms of use')}</span>
      </div>
      <div className="lg:-mx-8">
        <div className="lg:px-8 h-[90vh] lg:h-[570px] overflow-y-auto">
          <p className="mb-4">{t('terms_of_use_description_1')}</p>
          <p className="mb-4">{t('terms_of_use_description_2')}</p>
          <span className="font-bold">{t('legal_age_requirement')}</span>
          <p className="mb-4">{t('legal_age_requirement_description')}</p>
          <span className="font-bold">{t('software_license')}</span>
          <p className="mb-4">{t('software_license_description')}</p>
          <span className="font-bold">{t('third_party_links')}</span>
          <p className="mb-4">{t('third_party_links_description')}</p>
          <span className="font-bold">{t('about_your_content')}</span>
          <p className="mb-4">{t('about_your_content_description_1')}</p>
          <p className="mb-4">{t('about_your_content_description_2')}</p>
          <p className="mb-4">{t('about_your_content_description_3')}</p>
          <p className="mb-4">{t('about_your_content_description_4')}</p>
          <p className="mb-4">{t('about_your_content_description_5')}</p>
          <p className="mb-4">{t('about_your_content_description_6')}</p>
          <p className="mb-4">{t('about_your_content_description_7')}</p>
          <p className="mb-4">{t('about_your_content_description_8')}</p>
          <span className="font-bold">{t('order_policy')}</span>
          <p className="mb-4">{t('order_policy_description')}</p>
          <span className="font-bold">{t('fee_and_payment_terms')}</span>
          <p className="mb-4">{t('fee_and_payment_terms_1')}</p>
          <p className="mb-4">{t('fee_and_payment_terms_2')}</p>
          <p className="mb-4">{t('fee_and_payment_terms_3')}</p>
          <div className="mb-4">
            <Trans Trans ns={TRANS_NAMESPACES.termsOfUse} i18nKey="fee_and_payment_terms_4">
              In cases of disputes, you must alert us by writing to our
              <a className="text-photobookBlue cursor-pointer" href="https://www.photobookworldwide.com/support/contact">Support Team</a>
              within 7 days after receiving your credit card statement,
              if you dispute any of our charges on that statement.
              If Photobook Worldwide does not receive payment from your credit card issuer,
              you are obliged to pay all amounts due upon demand by Photobook Worldwide.
            </Trans>
          </div>
          <span className="font-bold">{t('delivery_of_products')}</span>
          <p className="mb-4">{t('delivery_of_products_description')}</p>
          <span className="font-bold">{t('force_majeure')}</span>
          <p className="mb-4">{t('force_majeure_description')}</p>
          <span className="font-bold">{t('satisfaction_guarantee')}</span>
          <p className="mb-4">{t('satisfaction_guarantee_description_1')}</p>
          <p className="mb-4">{t('satisfaction_guarantee_description_2')}</p>
          <p className="mb-4">{t('satisfaction_guarantee_description_3')}</p>
          <p className="mb-4">{t('satisfaction_guarantee_description_4')}</p>
          <p className="mb-4">{t('satisfaction_guarantee_description_5')}</p>
          <span className="font-bold">{t('acceptance_of_agreement')}</span>
          <div className="mb-4">
            <Trans Trans ns={TRANS_NAMESPACES.termsOfUse} i18nKey="acceptance_of_agreement_description">
              YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL ITS TERMS AND
              CONDITIONS. Photobook Worldwide reserves the right, at our sole discretion,
              to update or make modifications to this Terms of Service from time to time
              without prior notice. The date of modification will be reflected at the top of
              this page. If you have a question regarding our Terms of Service, please contact
              us through our
              <a className="text-photobookBlue cursor-pointer" href="https://www.photobookworldwide.com/support/contact">Support</a>
              page.
            </Trans>
          </div>
          <span className="font-bold">{t('copyright_information')}</span>
          <div className="mb-4">
            <Trans ns={TRANS_NAMESPACES.termsOfUse} i18nKey="copyright_information_description">
              Copyright © 2005 - 2023. All Rights Reserved. The logos and trademarks displayed on
              the Website and/or products are the sole property of Photobook Worldwide.
              Usage of these marks is strictly prohibited.
              Photobook Worldwide also reserves the right to print their
              logo on a limited number of products including the
              <strong>Mini Square Softcovers</strong>
              range.
            </Trans>
          </div>
        </div>
      </div>
    </Modal>
  );
};

TermsOfUseModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default TermsOfUseModal;
