import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import { TRANS_NAMESPACES } from '../../services/i18next';
import {
  setTermofuseModalOpen,
  setPrivacypolicyModalClose
} from '../../slices/modal';

const PrivacyPolicyModal = ({ open, closeModal }) => {
  const { t } = useTranslation(TRANS_NAMESPACES.privacyPolicy);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setTermofuseModalOpen());
    dispatch(setPrivacypolicyModalClose());
  };

  return (
    <Modal open={open} className="lg:my-12 lg:w-[880px]" handleClose={closeModal}>
      <div className="relative w-fit bottom-8">
        <span className="text-2xl">{t('privacy_policy', 'Privacy Policy')}</span>
      </div>
      <div className="lg:-mx-8">
        <div className="lg:px-8 h-[90vh] lg:h-[570px] overflow-y-auto">
          <p className="mb-4">{t('privacy_policy_description_1')}</p>
          <p className="mb-4">{t('privacy_policy_description_2')}</p>
          <p className="mb-4">{t('privacy_policy_description_3')}</p>
          <span className="font-bold">{t('policy_num_1')}</span>
          <p className="mb-4">{t('policy_num_1_description_1')}</p>
          <p className="mb-4">{t('policy_num_1_description_2')}</p>
          <ul className="pl-10 mb-4 list-disc list-outside">
            <li>{t('policy_num_1_item_1')}</li>
            <li>{t('policy_num_1_item_2')}</li>
            <li>{t('policy_num_1_item_3')}</li>
            <li>{t('policy_num_1_item_4')}</li>
            <li>{t('policy_num_1_item_5')}</li>
            <li>{t('policy_num_1_item_6')}</li>
            <li>{t('policy_num_1_item_7')}</li>
            <li>{t('policy_num_1_item_8')}</li>
            <li>{t('policy_num_1_item_9')}</li>
            <li>{t('policy_num_1_item_10')}</li>
            <li>{t('policy_num_1_item_11')}</li>
            <li>{t('policy_num_1_item_12')}</li>
            <li>{t('policy_num_1_item_13')}</li>
            <li>{t('policy_num_1_item_14')}</li>
          </ul>
          <span className="font-bold">{t('policy_num_2')}</span>
          <p className="mb-4">{t('policy_num_2_description')}</p>
          <ul className="pl-10 mb-4 list-disc list-outside">
            <li>{t('policy_num_2_item_1')}</li>
            <li>{t('policy_num_2_item_2')}</li>
            <li>{t('policy_num_2_item_3')}</li>
            <li>{t('policy_num_2_item_4')}</li>
            <li>{t('policy_num_2_item_5')}</li>
            <li>{t('policy_num_2_item_6')}</li>
          </ul>
          <span className="font-bold">{t('policy_num_3')}</span>
          <p className="mb-4">{t('policy_num_3_description_1')}</p>
          <ul className="pl-10 mb-4 list-disc list-outside">
            <li>{t('policy_num_3_item_1')}</li>
            <li>{t('policy_num_3_item_2')}</li>
            <li>{t('policy_num_3_item_3')}</li>
            <li>{t('policy_num_3_item_4')}</li>
            <li>{t('policy_num_3_item_5')}</li>
            <li>{t('policy_num_3_item_6')}</li>
            <li>{t('policy_num_3_item_7')}</li>
            <li>{t('policy_num_3_item_8')}</li>
            <li>{t('policy_num_3_item_9')}</li>
            <li>{t('policy_num_3_item_10')}</li>
          </ul>
          <p className="mb-4">{t('policy_num_3_description_2')}</p>
          <span className="font-bold">{t('policy_num_4')}</span>
          <p className="mb-4">{t('policy_num_4_description_1')}</p>
          <ul className="pl-10 mb-4 list-disc list-outside">
            <li>{t('policy_num_4_item_1')}</li>
            <li>{t('policy_num_4_item_2')}</li>
            <li>{t('policy_num_4_item_3')}</li>
          </ul>
          <p className="mb-4">{t('policy_num_4_description_2')}</p>
          <p className="mb-4">{t('policy_num_4_description_3')}</p>
          <span className="font-bold">{t('policy_num_5')}</span>
          <p className="mb-4">{t('policy_num_5_description_1')}</p>
          <p className="mb-4">{t('policy_num_5_description_2')}</p>
          <span className="font-bold">{t('policy_num_6')}</span>
          <p className="mb-4">{t('policy_num_6_description_1')}</p>
          <p className="mb-4">{t('policy_num_6_description_2')}</p>
          <span className="font-bold">{t('policy_num_7')}</span>
          <div className="mb-4">
            <Trans ns={TRANS_NAMESPACES.privacyPolicy} i18nKey="policy_num_7_description_1">
              Upon uploading your project and/or photos, you hereby acknowledge that the
              Content of the photo product conforms to the rules and regulations as stated in our
              <span className="text-photobookBlue cursor-pointer" onClick={handleClick}>Terms of Service</span>
              . Photobook Worldwide and all its subsidiaries reserve the right to refuse
              printing or delete any photos/images of any project that contains Content that
              breaches any of the agreements and privacy policies of the company.
            </Trans>
          </div>
          <p className="mb-4">{t('policy_num_7_description_2')}</p>
          <p className="mb-4">{t('policy_num_7_description_3')}</p>
          <p className="mb-4">{t('policy_num_7_description_4')}</p>
          <span className="font-bold">{t('policy_num_7_point_1')}</span>
          <p className="mb-4">{t('policy_num_7_point_1_description_1')}</p>
          <span className="font-bold">{t('policy_num_7_point_2')}</span>
          <p className="mb-4">{t('policy_num_7_point_2_description_1')}</p>
          <span className="font-bold">{t('policy_num_7_point_3')}</span>
          <p className="mb-4">{t('policy_num_7_point_3_description_1')}</p>
          <span className="font-bold">{t('policy_num_8')}</span>
          <p className="mb-4">{t('policy_num_8_description_1')}</p>
          <p className="mb-4">{t('policy_num_8_description_2')}</p>
          <p className="mb-4">{t('policy_num_8_description_3')}</p>
          <span className="font-bold">{t('policy_num_9')}</span>
          <div className="mb-4">
            <Trans ns={TRANS_NAMESPACES.privacyPolicy} i18nKey="policy_num_9_description_1">
              If you feel you have received an e-mail from us in error, please write to us at
              <a className="text-photobookBlue cursor-pointer break-all" href="mailto:support-ww@photobookworldwide.com">support-ww@photobookworldwide.com</a>
              . At any time, if you no longer wish to receive a specific form of email,
              follow the “Unsubscribe” instructions located near the bottom of each email.
            </Trans>
          </div>
          <p className="mb-4">{t('policy_num_9_description_2')}</p>
          <span className="font-bold">{t('policy_num_10')}</span>
          <p className="mb-4">{t('policy_num_10_description_1')}</p>
          <p className="mb-4">{t('policy_num_10_description_2')}</p>
          <p className="mb-4">{t('policy_num_10_description_3')}</p>
          <span className="font-bold">{t('policy_num_11')}</span>
          <p className="mb-4">{t('policy_num_11_description_1')}</p>
          <span className="font-bold">{t('policy_num_12')}</span>
          <div>
            <Trans ns={TRANS_NAMESPACES.privacyPolicy} i18nKey="policy_num_12_description">
              If you have a question regarding our Privacy Policy, or would like to
              request for your data to be deleted, or have questions that are not answered,
              please write to us. Click
              <a className="text-photobookBlue cursor-pointer" href="https://www.photobookworldwide.com/support/contact">here</a>
              for address details.
            </Trans>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PrivacyPolicyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PrivacyPolicyModal;
