import camelize from 'camelize';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetLibraryQuery } from '../../api/library';
import Loader from '../../components/Loader';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useMergeAccountConfirmationModal from '../../hooks/useMergeAccountConfirmationModal';
import useToast from '../../hooks/useToast';
import LibraryFooterBar from '../footer-bar/LibraryFooterBar';
import { LibraryTopNavigation } from '../top-navigation';
import CreatePhotobookModal from './CreatePhotobookModal';
import EmptyLibrary from './EmptyLibrary';
import PhotobookCard from './PhotobookCard';

const Library = () => {
  const {
    data,
    error,
    isError,
    isLoading,
    isSuccess
  } = useGetLibraryQuery({ details: true }, { refetchOnMountOrArgChange: true });

  useMergeAccountConfirmationModal(isLoading);

  const [photobooks, setPhotobooks] = useState([]);
  const createPhotobookModalOpen = useSelector((state) => state.modal.createPhotobookModalOpen);
  const { whoopsToast } = useToast();
  const clearAuthTokens = useClearAuthTokens();

  useEffect(() => {
    if (isSuccess) {
      setPhotobooks(camelize(data.data));
      return;
    }

    if (isError) {
      if (error.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isSuccess,
    data,
    isError,
    error,
    whoopsToast,
    clearAuthTokens
  ]);

  return (
    isLoading
      ? <Loader />
      : (
        <div className="flex flex-col h-full">
          <LibraryTopNavigation />
          <div className="h-full bg-white mt-[60px]">
            {
              photobooks.length === 0
                ? <EmptyLibrary />
                : (
                  <div className="grid grid-cols-2 lg:grid-cols-[repeat(auto-fill,192px)] gap-x-2 gap-y-4 lg:gap-8 mx-2 lg:mx-8 mt-4 lg:mt-8 pb-20">
                    { photobooks.map((item, index) => (
                      <PhotobookCard photobook={item} key={item.urlIdentifier} index={index} />)) }
                  </div>
                )
            }
          </div>
          <LibraryFooterBar />
          <CreatePhotobookModal
            open={createPhotobookModalOpen}
          />
        </div>
      )
  );
};

export default Library;
