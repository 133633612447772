import PropTypes from 'prop-types';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { CloseButton } from './button';

const PORTAL_ID = 'modal-portal';

const Modal = ({
  open,
  handleClose,
  children,
  withClosebutton,
  className = ''
}) => {
  if (!open) {
    return null;
  }

  // https://github.com/testing-library/react-testing-library/issues/62#issuecomment-438653348
  let portal = document.getElementById(PORTAL_ID);
  if (!portal) {
    portal = document.createElement('div');
    portal.setAttribute('id', PORTAL_ID);
    document.body.appendChild(portal);
  }

  return ReactDOM.createPortal(
    <>
      <div className="fixed bg-black/50 inset-0 z-50" data-testid="modal-backdrop" onClick={handleClose} />
      <div className={`${className} absolute w-full lg:w-[600px] min-h-full lg:min-h-0 p-4 lg:p-8 lg:my-24 bg-white top-0 left-1/2 translate-x-[-50%] z-50 lg:rounded`} data-testid="modal-dialog">
        { withClosebutton
          && (
          <div className="flex justify-end mb-2">
            <CloseButton onClick={handleClose} />
          </div>
          )}
        {children}
      </div>
    </>,
    document.getElementById(PORTAL_ID)
  );
};

Modal.defaultProps = {
  withClosebutton: true
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  withClosebutton: PropTypes.bool,
  className: PropTypes.string
};

export default Modal;
