import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeletePhotoMutation } from '../../api/photobook';
import { WarningIcon } from '../../assets/images';
import { Button, SubmitButton } from '../../components/button';
import Modal from '../../components/Modal';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import getPhotoUuidFromUrl from './getPhotoUuidFromUrl';

const DeletePhotoModal = ({
  open, handleClose, nextPhotoUuid
}) => {
  const { infoToast, whoopsToast } = useToast();
  const { photobookUrlIdentifier } = useParams();
  const photoUuid = getPhotoUuidFromUrl();
  const navigate = useNavigate();
  const { t } = useTranslation(TRANS_NAMESPACES.galleryViewer);

  const {
    handleSubmit,
    formState: { isValidating, isSubmitting },
  } = useForm();

  const [
    deletePhoto,
    {
      error,
      isError,
      isLoading,
      isSuccess
    }
  ] = useDeletePhotoMutation();

  useEffect(
    () => {
      if (isSuccess) {
        infoToast(t('photo_is_removed', 'Photo is removed'), 'photoRemovedToast');
        handleClose();

        if (nextPhotoUuid === '') {
          navigate(`/photobook/${photobookUrlIdentifier}#photos`);
          return;
        }
        navigate(`/photobook/${photobookUrlIdentifier}/photo/${nextPhotoUuid}`);
        return;
      }
      if (isError) {
        if (error.status === 404) {
          navigate('/404');
          return;
        }
        whoopsToast();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, infoToast, isError, isSuccess, t, whoopsToast]
  );

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      withClosebutton={false}
    >
      <img className="mx-auto" src={WarningIcon} alt="Warning icon" />
      <div className="mt-8 text-center">
        <strong>{t('remove_photo', 'Remove Photo?')}</strong>
      </div>
      <div className="mt-8 text-center">
        {t('remove_photo_description', 'This photo will be permanently deleted. This action cannot be reversed.')}
      </div>
      <div className="grid lg:grid-cols-2 gap-4 mt-9">
        <Button
          color="tertiary"
          variant="outlined"
          onClick={() => handleClose()}
          abTestId="deletePhotoCancel"
        >
          {t('cancel', 'cancel')}
        </Button>
        <form className="flex" onSubmit={handleSubmit(() => deletePhoto({ imageUuid: photoUuid }))}>
          <SubmitButton
            color="primary"
            variant="contained"
            className="w-full"
            disabled={isLoading || isValidating || isSubmitting}
            abTestId="deletePhotoSubmit"
          >
            {t('delete_photo', 'Delete Photo')}
          </SubmitButton>
        </form>
      </div>
    </Modal>
  );
};

DeletePhotoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  nextPhotoUuid: PropTypes.string.isRequired,
};

export default DeletePhotoModal;
