import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMergeGuestMutation } from '../../api/auth';
import { BlueProfileIcon } from '../../assets/images';
import { Button, SubmitButton } from '../../components/button';
import Modal from '../../components/Modal';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { clearGuest } from '../../slices/auth';
import { setMergeModalClose } from '../../slices/modal';
import { simulateHttpRedirect } from '../../utils/generic';

const MergeModal = ({ open }) => {
  const { t } = useTranslation(TRANS_NAMESPACES.auth);
  const { whoopsToast } = useToast();
  const dispatch = useDispatch();
  const guestToken = useSelector((state) => state.auth.guest.token);
  const guestName = useSelector((state) => state.auth.guest.name);
  const userEmail = useSelector((state) => state.auth.user.email);

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValidating, isSubmitting },
  } = useForm();

  const [
    mergeGuest,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useMergeGuestMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearGuest());
      dispatch(setMergeModalClose());
      simulateHttpRedirect(window.location.pathname);
      return;
    }

    if (isError) {
      whoopsToast();
    }
  }, [
    dispatch,
    isError,
    isSuccess,
    resData,
    resError,
    setError,
    whoopsToast,
  ]);

  return (
    <Modal
      open={open}
      handleClose={() => {
        dispatch(clearGuest());
        dispatch(setMergeModalClose());
      }}
      withClosebutton={false}
    >
      <img className="mx-auto" src={BlueProfileIcon} alt="Photobook Live logo" />
      <div className="mt-8 text-center">
        <Trans ns={TRANS_NAMESPACES.auth} i18nKey="merge_account_description" values={{ name: guestName, email: userEmail }}>
          All ownership of contributed photos from account
          {' '}
          <strong>{{ guestName }}</strong>
          {' '}
          will be moved over to
          {' '}
          <strong>{{ userEmail }}</strong>
          {' '}
          . Take note, if you choose not to move the account it will be lost
          and this action cannot be reversed.
        </Trans>
      </div>
      <div className="grid lg:grid-cols-2 gap-4 mt-9">
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            dispatch(clearGuest());
            dispatch(setMergeModalClose());
          }}
          abTestId="mergeCancel"
        >
          {t('no', 'no')}
        </Button>
        <form className="flex" onSubmit={handleSubmit((data) => mergeGuest(data))}>
          <input type="hidden" {...register('guest_token', { value: guestToken })} />
          <SubmitButton
            color="primary"
            variant="contained"
            className="w-full"
            disabled={isLoading || isValidating || isSubmitting}
            abTestId="mergeOkay"
          >
            {t('okay', 'okay')}
          </SubmitButton>
        </form>
      </div>
    </Modal>
  );
};

MergeModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default MergeModal;
