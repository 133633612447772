import { createSlice } from '@reduxjs/toolkit';

const projectCreationSlice = createSlice({
  name: 'projectCreation',
  initialState: {
    inCoverSelectionMode: false,
    selectedCoverPhoto: undefined,
    inPrintSelectionMode: false,
    selectedPrintPhotos: [],
    printOddPhotos: false,
  },
  reducers: {
    enterCoverSelectionMode: (state) => ({ ...state, inCoverSelectionMode: true }),
    exitCoverSelectionMode: (state) => ({ ...state, inCoverSelectionMode: false }),
    addOrReplaceCoverSelection: (state, { payload }) => ({ ...state, selectedCoverPhoto: payload }),
    clearCoverSelection: (state) => ({ ...state, selectedCoverPhoto: undefined }),

    // TODO: similar to download slice -- reuse reducer logic ie https://redux.js.org/usage/structuring-reducers/reusing-reducer-logic
    enterPrintSelectionMode: (state) => ({ ...state, inPrintSelectionMode: true }),
    exitPrintSelectionMode: (state) => ({ ...state, inPrintSelectionMode: false }),
    addToPrintSelection: (state, { payload }) => {
      const selectedPrintPhotos = [...state.selectedPrintPhotos, payload];
      return { ...state, selectedPrintPhotos };
    },
    removeFromPrintSelection: (state, { payload }) => {
      const selectedPrintPhotos = state.selectedPrintPhotos.filter((photo) => photo !== payload);
      return { ...state, selectedPrintPhotos };
    },
    replacePrintSelection: (state, { payload }) => ({ ...state, selectedPrintPhotos: payload }),
    clearPrintSelection: (state) => ({ ...state, selectedPrintPhotos: [] }),

    allowPrintOddPhotos: (state) => ({ ...state, printOddPhotos: true }),
    disallowPrintOddPhotos: (state) => ({ ...state, printOddPhotos: false }),
  }
});

export const {
  enterCoverSelectionMode,
  exitCoverSelectionMode,
  addOrReplaceCoverSelection,
  clearCoverSelection,
  enterPrintSelectionMode,
  exitPrintSelectionMode,
  addToPrintSelection,
  removeFromPrintSelection,
  replacePrintSelection,
  clearPrintSelection,
  allowPrintOddPhotos,
  disallowPrintOddPhotos,
} = projectCreationSlice.actions;

export default projectCreationSlice;
