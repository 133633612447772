import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DownloadIcon } from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { enterSelectionMode } from '../../slices/download';
import { setAuthModalOpen } from '../../slices/modal';

const PhotoSelectionModeButton = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const { isAccountHolder } = useUserType();
  const dispatch = useDispatch();

  return (
    <button
      onClick={
        () => (isAccountHolder ? dispatch(enterSelectionMode()) : dispatch(setAuthModalOpen()))
      }
      type="button"
      data-abtestid="ellipsisMenuPhotoSelectionModeButton"
    >
      <IconPaddedText
        className="text-charcoal font-bold"
        icon={<img className="w-5 h-5" src={DownloadIcon} alt="Download" />}
        text={t('photo_gallery_download', 'Download')}
      />
    </button>
  );
};

export default PhotoSelectionModeButton;
