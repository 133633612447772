import camelize from 'camelize';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLoginUserMutation } from '../../api/auth';
import { Button, SubmitButton } from '../../components/button';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setUser } from '../../slices/auth';
import {
  setAuthModalClose,
  setPrivacypolicyModalOpen,
  setSendForgotPasswordEmailModalOpen,
  setTermofuseModalOpen
} from '../../slices/modal';
import { simulateHttpRedirect } from '../../utils/generic';

const LoginForm = ({ defaultValues = {}, redirectTo, setGenericError }) => {
  const { t } = useTranslation([TRANS_NAMESPACES.auth, TRANS_NAMESPACES.validation]);
  const { whoopsToast } = useToast();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValidating, isSubmitting, errors },
  } = useForm({ defaultValues });

  const [
    loginUser,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useLoginUserMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      const {
        user,
        token
      } = camelize(resData.data);

      dispatch(setUser({ token, ...user }));
      simulateHttpRedirect(redirectTo);
      return;
    }

    if (isError) {
      if (resError.status === 401 && resError.data.error_code === 10003) {
        setGenericError(resError.data.errors.messages[0]);
        return;
      }

      if (resError.status === 422) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [field, [error]] of Object.entries(resError.data.errors)) {
          setError(field, { message: error });
        }

        return;
      }

      whoopsToast();
    }
  }, [
    isSuccess,
    isError,
    resData,
    resError,
    dispatch,
    setError,
    setGenericError,
    redirectTo,
    whoopsToast,
  ]);

  const termModalHandleClick = () => {
    dispatch(setAuthModalClose());
    dispatch(setTermofuseModalOpen());
  };

  const privacyModalHandleClick = () => {
    dispatch(setAuthModalClose());
    dispatch(setPrivacypolicyModalOpen());
  };

  return (
    <form className="-mt-4" onSubmit={handleSubmit((data) => loginUser(data))}>
      <input
        {...register('email', {
          required: t('required_field_validator', 'This field is required.', {
            ns: TRANS_NAMESPACES.validation,
          }),
        })}
        className={`mt-4 w-full rounded-lg border py-3 px-4 ${
          errors.email ? 'border-blush' : 'border-grey'
        }`}
        type="email"
        placeholder={t('login_form_placeholder_email', 'Email')}
        autoComplete="username"
        data-abtestid="loginEmail"
      />
      {errors.email && (
        <div className="mt-2 text-blush">{errors.email.message}</div>
      )}

      <input
        {...register('password', {
          required: t('required_field_validator', 'This field is required.', {
            ns: TRANS_NAMESPACES.validation,
          }),
        })}
        className={`mt-4 w-full rounded-lg border py-3 px-4 ${
          errors.password ? 'border-blush' : 'border-grey'
        }`}
        type="password"
        placeholder={t('login_form_placeholder_password', 'Password')}
        autoComplete="current-password"
        data-abtestid="loginPassword"
      />
      {errors.password && (
        <div className="mt-2 text-blush">{errors.password.message}</div>
      )}

      <div className="mt-4 text-center lg:text-left">
        <Button
          variant="text"
          color="secondary"
          className="!p-0"
          onClick={() => {
            dispatch(setAuthModalClose());
            dispatch(setSendForgotPasswordEmailModalOpen());
          }}
          abTestId="loginForgotPasswordLink"
        >
          {t('login_form_forgot_password', 'Forgot password?')}
        </Button>
      </div>

      <SubmitButton
        color="primary"
        variant="contained"
        className="mt-4 w-full"
        disabled={isLoading || isValidating || isSubmitting}
        abTestId="loginSubmit"
      >
        {t('login_form_button_text_continue', 'Continue')}
      </SubmitButton>

      <div className="mt-4 text-center">
        <Trans ns={TRANS_NAMESPACES.auth} i18nKey="auth_tnc_reminder">
          By continuing, you agree to Photobook&apos;s
          {' '}
          <span
            className="cursor-pointer font-bold text-photobookBlue"
            onClick={termModalHandleClick}
            data-abtestid="loginTermsOfUseLink"
          >
            Terms of Use
          </span>
          {' '}
          and
          {' '}
          <span
            className="cursor-pointer font-bold text-photobookBlue"
            onClick={privacyModalHandleClick}
            data-abtestid="loginPrivacyPolicyLink"
          >
            Privacy Policy
          </span>
          .
        </Trans>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  defaultValues: PropTypes.objectOf(PropTypes.string),
  redirectTo: PropTypes.string.isRequired,
  setGenericError: PropTypes.func.isRequired,
};

export default LoginForm;
