import * as qr from '@bitjson/qr-code';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetPhotobookQuery } from '../../api/photobook';
import { Button } from '../../components/button';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import buildInvitationLink from '../../utils/buildInvitationLink';
import copyToClipboard from '../../utils/copyToClipboard';
import SocialMediaIcons from './SocialMediaIcons';

const InviteMembersModal = ({
  open,
  handleClose,
}) => {
  const { photobookUrlIdentifier } = useParams();
  const { t } = useTranslation(TRANS_NAMESPACES.sharePhotobook);
  const { infoToast } = useToast();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data,
    isSuccess
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });

  const inviteLink = buildInvitationLink(photobookUrlIdentifier);

  const handleCopyLinkClick = () => {
    copyToClipboard(inviteLink);
    infoToast(t('copy_link_success', 'Photobook link copied.'), 'inviteMembersModalCopyLinkToast');
  };

  useEffect(() => qr.defineCustomElements(window), []);

  return (
    <FullscreenModal open={open} handleClose={handleClose} abTestId="inviteMembersModal">
      <div className="min-h-screen flex flex-col items-center justify-center gap-y-8 py-4 mx-4 lg:mx-auto lg:w-[560px]">
        <div className="flex flex-col gap-y-4 items-center">
          <div>
            <qr-code contents={inviteLink} style={{ width: '120px', height: '120px' }} mask-x-to-y-ratio="2" data-abtestid="inviteMembersModalQrCode">
              <img src="/qrCodeLogo.png" slot="icon" alt="qr code logo" />
            </qr-code>
          </div>
          <div className="flex flex-col text-center gap-y-1">
            <p className="font-bold">{t('invite_members_modal_header', 'Invite more members')}</p>
            <p className="">{t('invite_members_modal_description', 'Add as many members as you wish, anyone can contribute photos. Let your friends and family scan the QR code to join your Photobook, or share this Photobook link via any social media.')}</p>
          </div>
          <div data-testid="copyLink" className="flex flex-col self-stretch lg:self-auto">
            <Button
              color="primary"
              variant="contained"
              onClick={handleCopyLinkClick}
              abTestId="inviteMembersModalCopyLink"
            >
              {t('copy_link', 'Copy Link')}
            </Button>
          </div>
        </div>
        {isSuccess
          && (
          <SocialMediaIcons
            link={inviteLink}
            title={data.data.photobook.name}
            abTestId="inviteMembersModal"
          />
          )}
      </div>
    </FullscreenModal>
  );
};

InviteMembersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InviteMembersModal;
