import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ImageAlertIcon } from '../../assets/images';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setPrintPhotoMaxLimitModalClose } from '../../slices/modal';

const PrintPhotoMaxLimitModal = ({ open }) => {
  const dispatch = useDispatch();
  const isLargeScreen = useLargeScreenMediaQuery();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);

  const renderContent = () => (
    <div className="flex flex-col gap-y-8 items-center">
      <div className="flex flex-col gap-y-2 text-center">
        <img className="mx-auto" src={ImageAlertIcon} alt="image alert icon" />
        <div className="text-2xl">
          {t('print_photo_max_limit_modal_header', 'Ops!')}
        </div>
        <div>
          {t('print_photo_max_limit_modal_description', 'Maximum of 150 photos can be selected.')}
        </div>
      </div>
      <Button
        className="w-full lg:w-52"
        onClick={() => dispatch(setPrintPhotoMaxLimitModalClose())}
        abTestId="printPhotoMaxLimitModalOkay"
      >
        {t('print_photo_max_limit_modal_okay', 'Okay')}
      </Button>
    </div>
  );

  return (
    (isLargeScreen) ? (
      <FixedWidthModal
        className="w-full"
        open={open}
        handleClose={() => dispatch(setPrintPhotoMaxLimitModalClose())}
        abTestId="printPhotoMaxLimitModal"
      >
        {renderContent()}
      </FixedWidthModal>
    ) : (
      <FullscreenModal
        className="p-4"
        open={open}
        handleClose={() => dispatch(setPrintPhotoMaxLimitModalClose())}
        abTestId="printPhotoMaxLimitModal"
      >
        {renderContent()}
      </FullscreenModal>
    )
  );
};

PrintPhotoMaxLimitModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default PrintPhotoMaxLimitModal;
