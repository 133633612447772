import camelize from 'camelize';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetRecentVisitsQuery } from '../api/recentVisit';
import Loader from '../components/Loader';
import { DeleteRecentVisitModal, DeleteRecentVisitsModal, PhotobookCard } from '../features/recent-visits';
import { RecentVisitsTopNavigation } from '../features/top-navigation';
import useRedirectIfNotLoggedIn from '../hooks/useRedirectIfNotLoggedIn';
import useToast from '../hooks/useToast';

const RecentVisits = () => {
  useRedirectIfNotLoggedIn();

  const deleteRecentVisitModalOpen = useSelector((state) => state.modal.deleteRecentVisitModalOpen);
  const deleteRecentVisitsModalOpen = useSelector(
    (state) => state.modal.deleteRecentVisitsModalOpen
  );
  const [photobookUrlIdentifier, setPhotobookUrlIdentifier] = useState(null);
  const [allInLibrary, setAllInLibrary] = useState(true);
  const { whoopsToast } = useToast();

  const {
    data,
    isSuccess,
    isError,
    isLoading,
  } = useGetRecentVisitsQuery({}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccess) {
      if (data.data.some((item) => !item.in_library)) {
        setAllInLibrary(false);
        return;
      }
      setAllInLibrary(true);
      return;
    }

    if (isError) {
      whoopsToast();
    }
  }, [
    isSuccess,
    isError,
    whoopsToast,
    data,
  ]);

  return (
    isLoading
      ? <Loader />
      : (
        <div className="flex flex-col h-full">
          <RecentVisitsTopNavigation allInLibrary={allInLibrary} />
          <div className="flex justify-center bg-white">
            <div className="flex flex-col w-full lg:w-defaultLgContainerWidth divide-y lg:mt-4">
              {(data && data.data ? camelize(data.data) : []).map((item, index) => (
                <PhotobookCard
                  photobook={item}
                  key={item.urlIdentifier}
                  index={index}
                  setPhotobookUrlIdentifier={setPhotobookUrlIdentifier}
                />
              ))}
            </div>
          </div>
          <DeleteRecentVisitModal
            open={deleteRecentVisitModalOpen}
            photobookUrlIdentifier={photobookUrlIdentifier}
          />
          <DeleteRecentVisitsModal
            open={deleteRecentVisitsModalOpen}
          />
        </div>
      )
  );
};

export default RecentVisits;
