import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CharcoalProfileIcon } from '../../assets/images';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setAuthModalOpen, setAuthModalLoginTab, setAuthModalRegisterTab } from '../../slices/modal';
import RegisterGuestForm from './RegisterGuestForm';

const GuestRegistrationModal = ({
  closeModal,
  open,
}) => {
  const isLargeScreen = useLargeScreenMediaQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.auth);

  const renderContent = () => (
    <>
      <img className="mx-auto w-12 h-12" src={CharcoalProfileIcon} alt="Photobook Live logo" />
      <div className="mt-4 text-center font-bold">
        {t('guest_registration_modal_header', 'Continue As Guest')}
      </div>
      <div className="mt-1 text-center">
        {t('guest_registration_modal_description', 'You can contribute photos without having an account. To enjoy everything Photobook Live has to offer, do create an account soon!')}
      </div>
      <div className="mt-4">
        <RegisterGuestForm />
      </div>
      <div className="h-4 border-b-2 border-gray text-xl text-center text-darkGrey mt-5">
        <span className="bg-white px-5">{t('or', 'Or')}</span>
      </div>
      <div className="grid lg:grid-cols-2 gap-4 mt-9">
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => { dispatch(setAuthModalRegisterTab()); dispatch(setAuthModalOpen()); }}
          abTestId="guestModalRegister"
        >
          {t('create_an_account', 'Create an Account')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { dispatch(setAuthModalLoginTab()); dispatch(setAuthModalOpen()); }}
          abTestId="guestModalLogin"
        >
          {t('login', 'Login')}
        </Button>
      </div>
    </>
  );

  return isLargeScreen ? (
    <FixedWidthModal
      open={open}
      handleClose={closeModal}
      abTestId="guestRegistrationModal"
    >
      {renderContent()}
    </FixedWidthModal>
  ) : (
    <FullscreenModal
      className="p-4"
      open={open}
      handleClose={closeModal}
      abTestId="guestRegistrationModal"
    >
      {renderContent()}
    </FullscreenModal>
  );
};

GuestRegistrationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default GuestRegistrationModal;
