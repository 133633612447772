import React, {
  useCallback, useContext, useEffect, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetApStoreCountryQuery } from '../../api/ap';
import generic from '../../api/generic';
import { useGetPhotobookQuery } from '../../api/photobook';
import { useCreateProjectMutation, useGetProjectQuery } from '../../api/print-project';
import {
  BackIcon,
  GearIcon,
  FeedbackIcon,
} from '../../assets/images';
import { Button, CloseButton } from '../../components/button';
import IconPaddedText from '../../components/IconPaddedText';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import usePromptWhenUploading from '../../hooks/usePromptWhenUploading';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { clearSelection, exitSelectionMode } from '../../slices/download';
import {
  setPrintErrorModalOpen,
  setPrintLoadingModalClose,
  setPrintLoadingModalOpen,
  setPrintOddPhotosModalOpen
} from '../../slices/modal';
import {
  disallowPrintOddPhotos, enterPrintSelectionMode, exitCoverSelectionMode, exitPrintSelectionMode
} from '../../slices/projectCreation';
import { PRINT_SELECTION_MAX_PHOTOS, PRINT_SELECTION_MIN_PHOTOS } from '../../utils/constants';
import download from '../../utils/download';
import { commaSeparateNumber } from '../../utils/generic';
import { GalleryUploaderContext } from '../gallery-upload';
import DownloadSelectedPhotosButton from '../photo-gallery/DownloadSelectedPhotosButton';
import AccountDropdown from './AccountDropdown';
import AddPhotos from './AddPhotos';
import SharePhotobook from './SharePhotobook';

const HomepageTopNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { photobookUrlIdentifier } = useParams();
  const { t } = useTranslation([TRANS_NAMESPACES.photobookHomepage, TRANS_NAMESPACES.misc]);
  const galleryUploader = useContext(GalleryUploaderContext);
  const { promptWhenUploadingPhoto } = usePromptWhenUploading(galleryUploader);
  const isLargeScreen = useLargeScreenMediaQuery();
  const inSelectionMode = useSelector((state) => state.download.inSelectionMode);
  const selectedPhotoCount = useSelector((state) => state.download.selectedPhotos).length;
  const {
    errorToast, whoopsToast, infoToast, removeToast, toastIds
  } = useToast();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data: photobookData,
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });
  const inCoverSelectionMode = useSelector((state) => state.projectCreation.inCoverSelectionMode);
  const coverSelected = useSelector((state) => state.projectCreation.selectedCoverPhoto);
  const inPrintSelectionMode = useSelector((state) => state.projectCreation.inPrintSelectionMode);
  const selectedCoverPhoto = useSelector((state) => state.projectCreation.selectedCoverPhoto);
  const selectedPrintPhotos = useSelector((state) => state.projectCreation.selectedPrintPhotos);
  const printOddPhotos = useSelector((state) => state.projectCreation.printOddPhotos);
  const minPrintPhotosReached = selectedPrintPhotos.length >= PRINT_SELECTION_MIN_PHOTOS;
  const shouldPoll = useRef(false);

  const [createZip, {
    isSuccess,
    isError,
    isLoading,
    data,
  }] = generic.useCreateZipMutation();

  const {
    data: apStoreCountryData
  } = useGetApStoreCountryQuery();

  const [
    createProject,
    {
      isSuccess: isCreateProjectSuccess,
      isError: isCreateProjectError,
      data: createProjectResponse,
    },
  ] = useCreateProjectMutation();

  const {
    isSuccess: isGetProjectSuccess,
    isError: isGetProjectError,
    data: getProjectResponse,
  } = useGetProjectQuery(
    createProjectResponse?.data?.uuid,
    { skip: !shouldPoll.current, pollingInterval: 10000 }
  );

  const handleExitSelectionModeButtonClick = () => {
    dispatch(exitSelectionMode());
    dispatch(clearSelection());
  };

  useEffect(() => {
    if (isLoading) {
      infoToast(t('downloading_photos', 'Preparing photos for download...', { ns: TRANS_NAMESPACES.misc }), 'downloadProgressToast', toastIds.preparingDownload, Infinity);
      return;
    }

    removeToast(toastIds.preparingDownload);

    if (isSuccess) {
      const { downloadUrl, failedCount } = data.data;
      const now = new Date();
      const fileName = `download-${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}T${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
      download(downloadUrl, fileName);

      if (failedCount > 0) {
        errorToast(t('download_failed_gallery', ` ${failedCount === 1 ? 'Failed to download 1 photo. The photo may have been removed by the owner.' : `Failed to download ${commaSeparateNumber(failedCount)} photos. The photos may have been removed by the owner.`}`, { count: failedCount }, { ns: TRANS_NAMESPACES.misc }));
      }
      return;
    }

    if (isError) {
      whoopsToast();
    }
  }, [isSuccess, isError, data, isLoading, errorToast, infoToast, t,
    toastIds,
    removeToast, whoopsToast]);

  const startToPrint = useCallback(() => {
    dispatch(setPrintLoadingModalOpen());
    dispatch(disallowPrintOddPhotos());
    createProject({
      store_code: apStoreCountryData?.data?.code ?? 'PBWW',
      cover_url: photobookData.data.photobook.images.list.find(
        (photo) => photo.uuid === selectedCoverPhoto
      ).image.print,
      image_urls: selectedPrintPhotos.map(
        (photoUuid) => photobookData.data.photobook.images.list.find(
          (photo) => photo.uuid === photoUuid
        ).image.print
      )
    });
  }, [
    apStoreCountryData,
    createProject,
    dispatch,
    photobookData,
    selectedCoverPhoto,
    selectedPrintPhotos
  ]);

  const handlePrintButtonClick = () => {
    if (selectedPrintPhotos.length % 2 !== 0) {
      dispatch(setPrintOddPhotosModalOpen());
      return;
    }

    startToPrint();
  };

  useEffect(() => {
    if (printOddPhotos && selectedPrintPhotos.length % 2 !== 0) {
      startToPrint();
    }
  }, [
    printOddPhotos,
    selectedPrintPhotos,
    startToPrint
  ]);

  useEffect(() => {
    if (isCreateProjectSuccess) {
      shouldPoll.current = true;
      return;
    }
    if (isCreateProjectError) {
      dispatch(setPrintLoadingModalClose());
      dispatch(setPrintErrorModalOpen());
    }
  }, [createProjectResponse, isCreateProjectError, isCreateProjectSuccess, dispatch]);

  useEffect(() => {
    if (isGetProjectSuccess) {
      if (getProjectResponse.data.project.project_status_code === 'IN_CART') {
        shouldPoll.current = false;
        window.location.href = apStoreCountryData?.data?.url
          ? `${apStoreCountryData?.data?.url}/checkout/cart`
          : 'https://www.photobookworldwide.com/checkout/cart';
        return;
      }

      if (getProjectResponse.data.project.project_status_code.startsWith('E_')) {
        shouldPoll.current = false;
        dispatch(setPrintLoadingModalClose());
        dispatch(setPrintErrorModalOpen());
        return;
      }

      return;
    }
    if (isGetProjectError) {
      shouldPoll.current = false;
      dispatch(setPrintLoadingModalClose());
      dispatch(setPrintErrorModalOpen());
    }
  }, [apStoreCountryData, getProjectResponse, isGetProjectError, isGetProjectSuccess, dispatch]);

  const homepageTopLeftRender = () => {
    if (inSelectionMode) {
      return (
        <>
          <CloseButton onClick={handleExitSelectionModeButtonClick} />
          <p
            className="mr-4"
            data-abtestid="topNavPhotosCount"
          >
            {`${commaSeparateNumber(selectedPhotoCount)} ${t('selected', 'selected')}`}
          </p>
        </>
      );
    }
    if (inCoverSelectionMode) {
      return (
        <>
          <Link
            data-abtestid="homepageBack"
            onClick={() => dispatch(exitCoverSelectionMode())}
            to={location.state === 'recent' ? '/recent' : '/'}
          >
            <img
              className="min-w-[20px] w-5 h-5"
              src={BackIcon}
              alt="back"
            />
          </Link>
          <p className="mr-4" data-abtestid="topNavCoverSelectionMode">{t('cover_photo_prompt', 'Choose a cover photo')}</p>
        </>
      );
    }
    if (inPrintSelectionMode) {
      return (
        <>
          <Link
            data-abtestid="homepageBack"
            onClick={() => dispatch(exitPrintSelectionMode())}
            to={location.state === 'recent' ? '/recent' : '/'}
          >
            <img
              className="min-w-[20px] w-5 h-5"
              src={BackIcon}
              alt="back"
            />
          </Link>
          <div className="mr-4 flex flex-col" data-abtestid="topNavPrintSelectionMode">
            <p className="text-xs leading-4 text-charcoal">
              {t('photos_selected_text', `${selectedPrintPhotos.length} photos selected`, { num_photos: selectedPrintPhotos.length })}
            </p>
            <p className="text-xs text-darkGrey">
              {
                selectedPrintPhotos.length < PRINT_SELECTION_MIN_PHOTOS
                  ? t('min_photo_prompt', `Min. ${PRINT_SELECTION_MIN_PHOTOS} photos required`, { min_photos: PRINT_SELECTION_MIN_PHOTOS })
                  : t('max_photo_prompt', `Max. ${PRINT_SELECTION_MAX_PHOTOS} photos`, { max_photos: PRINT_SELECTION_MAX_PHOTOS })
              }
            </p>
          </div>
        </>
      );
    }
    return (
      <>
        <Link
          data-abtestid="homepageBack"
          onClick={promptWhenUploadingPhoto()}
          to={location.state === 'recent' ? '/recent' : '/'}
        >
          <img
            className="min-w-[20px] w-5 h-5"
            src={BackIcon}
            alt="back"
          />
        </Link>
        <p className="mr-4" data-abtestid="topNavPhotobookName">{photobookData.data.photobook.name}</p>
      </>
    );
  };

  const onCoverSelectionNextClick = () => {
    dispatch(exitCoverSelectionMode());
    dispatch(enterPrintSelectionMode());
  };

  const homepageTopRightRender = () => {
    if (inSelectionMode) {
      return (selectedPhotoCount > 0)
        ? <DownloadSelectedPhotosButton createZip={createZip} />
        : <div />;
    }
    if (inCoverSelectionMode) {
      return (coverSelected && (<Button className="ml-4" variant="contained" color="primary" onClick={() => onCoverSelectionNextClick()} abTestId="printPhotosCoverSelectionComplete">{t('next', 'Next')}</Button>));
    }
    if (inPrintSelectionMode) {
      return (<Button className="ml-4" variant="contained" color={minPrintPhotosReached ? 'primary' : 'disabled'} disabled={!minPrintPhotosReached} onClick={() => handlePrintButtonClick()} abTestId="printPhotosSelectionComplete">{t('print', 'Print')}</Button>);
    }
    return (
      <>
        {isLargeScreen && (
          <div className="h-6">
            <AddPhotos />
          </div>
        )}
        {isLargeScreen && <SharePhotobook />}
        <Link className="contents" data-abtestid="photobookSettings" onClick={promptWhenUploadingPhoto()} to={`/photobook/${photobookUrlIdentifier}/settings`} state={location.state}>
          <IconPaddedText
            icon={<img className="min-w-[20px] w-5 h-5" src={GearIcon} alt="Photobook Settings" />}
            text={t('top_navigation_bar_photobook_settings', 'Settings')}
            textOnLargeScreenOnly
          />
        </Link>
        <a href="https://forms.gle/4gPiV8oG23wUwpHM7" target="_blank" rel="noreferrer" className="text-photobookBlue" data-abtestid="shareFeedbackButton">
          <IconPaddedText
            icon={<img className="w-5 h-5" src={FeedbackIcon} alt="share feedback icon" />}
            text={t('share_feedback', 'Share Feedback', { ns: TRANS_NAMESPACES.misc })}
            textOnLargeScreenOnly
          />
        </a>
        <AccountDropdown />
      </>
    );
  };

  return (
    <div
      className="fixed w-full flex items-center justify-between border-b border-fog min-h-[60px] px-4 lg:px-8 bg-white z-40"
      onDrop={(e) => (e.preventDefault())}
    >
      <div className="flex gap-x-4 lg:gap-x-8 items-center">
        {homepageTopLeftRender()}
      </div>
      <div className="flex items-center gap-x-5 lg:gap-x-8">
        {homepageTopRightRender()}
      </div>
    </div>
  );
};

export default HomepageTopNavigation;
