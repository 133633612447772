import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PrintIcon } from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setAuthModalOpen, setPrintModalOpen } from '../../slices/modal';

const PrintModeButton = () => {
  const { isAccountHolder } = useUserType();
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);

  return (
    <button
      type="button"
      onClick={() => (
        isAccountHolder ? dispatch(setPrintModalOpen()) : dispatch(setAuthModalOpen())
      )}
      data-abtestid="ellipsisMenuPrintModeButton"
    >
      <IconPaddedText
        className="text-charcoal font-bold"
        icon={<img className="w-5 h-5" src={PrintIcon} alt="Print" />}
        text={t('photo_gallery_print', 'Print')}
      />
    </button>
  );
};

export default PrintModeButton;
