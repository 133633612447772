import baseApi from './base';

const photobookApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPhotobook: builder.mutation({
      query: (data) => ({
        url: '/photobook',
        method: 'POST',
        body: data,
      }),
    }),
    getPhotobook: builder.query({
      query: ({ photobookUrlIdentifier }) => ({
        url: `/photobook/${photobookUrlIdentifier}`,
        method: 'GET'
      }),
      transformResponse: (response, meta, arg) => {
        const { sortOption } = arg;
        if (sortOption) {
          const sortCompareFn = sortOption === 'asc' ? (a, b) => a.taken_at - b.taken_at : (a, b) => b.taken_at - a.taken_at;
          const sortedImages = response.data.photobook.images.list.sort(
            sortCompareFn
          );
          return {
            ...response,
            data:
                {
                  photobook:
                    {
                      ...response.data.photobook,
                      images:
                        { ...response.data.photobook.images, list: sortedImages }
                    }
                }
          };
        }
        return response;
      },
      providesTags: ['Photobook']
    }),
    updatePhotobook: builder.mutation({
      query: ({ urlIdentifier, ...data }) => ({
        url: `/photobook/${urlIdentifier}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Photobook'],
    }),
    deletePhotobook: builder.mutation({
      query: (urlIdentifier) => ({
        url: `/photobook/${urlIdentifier}`,
        method: 'DELETE'
      }),
    }),
    generatePresignedUrls: builder.mutation({
      query: ({ urlIdentifier, ...data }) => ({
        url: `/photobook/${urlIdentifier}/generate_upload_presigned_url`,
        method: 'POST',
        body: data,
      }),
    }),
    uploadPhoto: builder.mutation({
      query: ({ file, to }) => ({
        url: to,
        method: 'PUT',
        body: file,
      }),
    }),
    deletePhoto: builder.mutation({
      query: ({ imageUuid }) => ({
        url: `/photobook/image/${imageUuid}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Photobook'],
    }),
    regenerateUrlIdentifier: builder.mutation({
      query: (urlIdentifier) => ({
        url: `/photobook/${urlIdentifier}/regenerate_url_identifier`,
        method: 'POST'
      }),
    }),
  }),
});

export const {
  useCreatePhotobookMutation,
  useGetPhotobookQuery,
  useUpdatePhotobookMutation,
  useDeletePhotobookMutation,
  useGeneratePresignedUrlsMutation,
  useUploadPhotoMutation,
  useDeletePhotoMutation,
  useRegenerateUrlIdentifierMutation,
} = photobookApi;
