import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import usePromptWhenUploading from '../../hooks/usePromptWhenUploading';
import { addToSelection, removeFromSelection } from '../../slices/download';
import { setPrintPhotoMaxLimitModalOpen } from '../../slices/modal';
import { addOrReplaceCoverSelection, addToPrintSelection, removeFromPrintSelection } from '../../slices/projectCreation';
import { PRINT_SELECTION_MAX_PHOTOS } from '../../utils/constants';
import { GalleryUploaderContext } from '../gallery-upload';
import DownloadSelectionToggle from './DownloadSelectionToggle';

const NAME_MAX_CHARS = 35;
const GRID_HEIGHT = 10;
const GRID_WIDTH_LARGE_SCREEN = 240;
const VERTICAL_PADDING_DESKTOP_VIEW = 32;
const VERTICAL_PADDING_OTHER = 16;

const constructName = (firstName, lastName) => {
  const name = `${firstName} ${lastName}`;
  if (name.length > NAME_MAX_CHARS) {
    return `${name.slice(0, NAME_MAX_CHARS)}...`;
  }
  return name;
};

const PhotoCard = ({
  item,
  mobileViewGridWidth,
  index,
  isTargetImage,
  targetImageRef,
  setTargetImageLoaded,
  lastCardRef = null
}) => {
  const {
    uuid,
    data,
    image: { thumbnail },
    user: { first_name: firstName, last_name: lastName }
  } = item;
  const imageWidthData = data.find((entry) => entry.name === 'thumbnail_width');
  const imageHeightData = data.find((entry) => entry.name === 'thumbnail_height');
  const imageWidth = imageWidthData ? parseInt(imageWidthData.value, 10) : 480;
  const imageHeight = imageHeightData ? parseInt(imageHeightData.value, 10) : 480;
  const [imageLoaded, setImageLoaded] = useState(false);
  const isLargeScreen = useLargeScreenMediaQuery();
  const location = useLocation();
  const galleryUploader = useContext(GalleryUploaderContext);
  const { promptWhenUploadingPhoto } = usePromptWhenUploading(galleryUploader);
  const inSelectionMode = useSelector((state) => state.download.inSelectionMode);
  const [showSelectionToggle, setShowSelectionToggle] = useState(false);
  const selectedPhotos = useSelector((state) => state.download.selectedPhotos);
  const dispatch = useDispatch();
  const inCoverSelectionMode = useSelector((state) => state.projectCreation.inCoverSelectionMode);
  const selectedCoverPhoto = useSelector((state) => state.projectCreation.selectedCoverPhoto);
  const selectedAsCoverPhoto = item.uuid === selectedCoverPhoto;
  const selectedPrintPhotos = useSelector((state) => state.projectCreation.selectedPrintPhotos);
  const inPrintSelectionMode = useSelector((state) => state.projectCreation.inPrintSelectionMode);
  const inSelectionModes = inSelectionMode || inCoverSelectionMode || inPrintSelectionMode;
  const printSelectionLimitReached = selectedPrintPhotos.length >= PRINT_SELECTION_MAX_PHOTOS;

  const photoSelected = () => {
    if (inSelectionMode) {
      return selectedPhotos.includes(item.uuid);
    }
    if (inCoverSelectionMode) {
      return selectedAsCoverPhoto;
    }
    if (inPrintSelectionMode) {
      return selectedPrintPhotos.includes(item.uuid);
    }
    return false;
  };

  const renderPhoto = () => (
    <>
      <img
        src={thumbnail}
        className={`object-fill rounded-lg w-full h-full ${inSelectionModes && photoSelected() ? 'border-4 border-photobookBlue' : ''}`}
        alt={`photo ${index + 1}`}
        data-abtestid={`photo${index + 1}`}
      />
      <div
        className="absolute w-full h-[34px] bottom-[1px] p-2 bg-card rounded-b-lg text-xs text-white font-bold"
        data-abtestid={`photoUploader${index + 1}`}
      >
        {constructName(firstName, lastName)}
      </div>
    </>
  );

  useEffect(() => {
    const image = new Image();

    image.onload = () => {
      setImageLoaded(true);
      if (isTargetImage) {
        setTargetImageLoaded(true);
      }
    };

    image.src = thumbnail;
  }, [thumbnail, isTargetImage, setTargetImageLoaded]);

  const gridWidth = isLargeScreen ? GRID_WIDTH_LARGE_SCREEN : mobileViewGridWidth;

  const heightAdjustment = isLargeScreen ? VERTICAL_PADDING_DESKTOP_VIEW : VERTICAL_PADDING_OTHER;

  const gridSpan = Math.ceil(
    (((gridWidth / imageWidth) * imageHeight) + heightAdjustment) / GRID_HEIGHT
  );

  const style = {
    gridRowEnd: `span ${gridSpan}`,
    height: gridSpan * GRID_HEIGHT,
    width: gridWidth
  };

  const photoCardMode = () => {
    if (inSelectionMode) {
      return {
        onClick: photoSelected()
          ? () => dispatch(removeFromSelection(item.uuid))
          : () => dispatch(addToSelection(item.uuid))
      };
    }
    if (inCoverSelectionMode) {
      return {
        onClick: () => dispatch(addOrReplaceCoverSelection(item.uuid))
      };
    }
    if (inPrintSelectionMode) {
      return {
        onClick: photoSelected()
          ? () => dispatch(removeFromPrintSelection(item.uuid))
          : () => (printSelectionLimitReached
            ? dispatch(setPrintPhotoMaxLimitModalOpen())
            : dispatch(addToPrintSelection(item.uuid))
          )
      };
    }
    return {
      onMouseEnter: () => setShowSelectionToggle(true),
      onMouseLeave: () => setShowSelectionToggle(false)
    };
  };

  return (
    <div style={style} className="py-2 lg:py-4" ref={lastCardRef}>
      {imageLoaded ? (
        <div
          className="relative h-full cursor-pointer"
          ref={isTargetImage ? targetImageRef : null}
          {...photoCardMode()}
        >
          {inSelectionModes && renderPhoto()}

          {!inSelectionModes && (
            <Link onClick={promptWhenUploadingPhoto()} to={`${location.pathname}/photo/${uuid}`} state={location.state}>
              {renderPhoto()}
            </Link>
          )}

          <DownloadSelectionToggle
            photoUuid={item.uuid}
            visible={inSelectionModes || showSelectionToggle}
            on={photoSelected()}
            setShowSelectionToggle={setShowSelectionToggle}
            inSelectionModes={inSelectionModes}
          />
        </div>
      ) : (
        <div className="object-fill rounded-lg w-full h-full bg-lightGrey" />
      )}
    </div>
  );
};

PhotoCard.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      exif: PropTypes.bool,
      name: PropTypes.string,
      value: PropTypes.string
    })),
    uuid: PropTypes.string.isRequired,
    image: PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
      print: PropTypes.string
    }),
    user: PropTypes.shape({
      uuid: PropTypes.string,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    }),
  }),
  mobileViewGridWidth: PropTypes.number.isRequired,
  index: PropTypes.number,
  isTargetImage: PropTypes.bool,
  targetImageRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  setTargetImageLoaded: PropTypes.func,
  lastCardRef: PropTypes.func

};

export default PhotoCard;
