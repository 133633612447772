import PropTypes from 'prop-types';
import React from 'react';

const getTheme = (color) => {
  switch (color) {
    case 'secondary':
      return {
        text: 'photobookBlue',
        background: 'transparent'
      };
    case 'warning':
      return {
        text: 'blush',
        background: 'white'
      };
    case 'grey':
      return {
        text: 'charcoal',
        background: 'transparent'
      };
    case 'lightGrey':
      return {
        text: 'charcoal',
        background: 'lightGrey'
      };
    case 'tertiary':
      return {
        text: 'darkGrey',
        background: 'transparent'
      };
    case 'disabled':
      return {
        text: 'white',
        background: 'grey'
      };
    default:
      return {
        text: 'white',
        background: 'photobookBlue'
      };
  }
};

const getStyle = (variant, color) => {
  const theme = getTheme(color);
  switch (variant) {
    case 'text':
      return `bg-${theme.background} text-${theme.text}`;
    case 'outlined':
      return `bg-${theme.background} text-${theme.text} border border-${theme.text} font-bold rounded-3xl`;
    default:
      return `bg-${theme.background} text-${theme.text} font-bold rounded-3xl`;
  }
};

/* eslint-disable react/button-has-type */
const Button = ({
  children,
  className = '',
  onClick,
  type,
  disabled = false,
  color = 'primary',
  variant = 'contained',
  abTestId = '',
}) => (
  <button
    className={`${className} ${getStyle(variant, color)} py-2 px-4`}
    onClick={onClick}
    type={type}
    disabled={disabled}
    {...(abTestId ? { 'data-abtestid': abTestId } : {})}
  >
    {children}
  </button>
);
/* eslint-enable react/button-has-type */

Button.defaultProps = {
  type: 'button',
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'warning', 'grey', 'lightGrey', 'disabled']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  abTestId: PropTypes.string,
};

export default Button;
