import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CharcoalUploadIcon } from '../../assets/images';
import Dropzone from '../../components/Dropzone';
import { TRANS_NAMESPACES } from '../../services/i18next';

const AddPhotosModal = ({ open, handleClose }) => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);

  useEffect(() => {
    if (open) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Dropzone
      hideDropzone={() => handleClose()}
      abTestId="addPhotosModal"
      noClick
    >
      <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-col items-center">
        <img
          src={CharcoalUploadIcon}
          alt="Upload image"
          className="h-12 w-12"
        />
        <span className="mt-2 text-base">
          {t(
            'add_photos_overlay_instruction',
            'Drop photos anywhere to upload'
          )}
        </span>
      </div>
    </Dropzone>
  );
};

AddPhotosModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddPhotosModal;
