import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearLiveMode } from '../slices/liveMode';
import { clearLiveModeCache } from '../slices/liveModeCache';

const useClearLiveModeData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearLiveMode());
    dispatch(clearLiveModeCache());
  }, [dispatch]);
};

export default useClearLiveModeData;
