import { LIVE_MODE_MAX_PHOTOS } from '../../utils/constants';

const liveModeAlgorithm = (newList, CIList, HList, UList, CList) => {
  let existingList;
  let currentIterationList = [...CIList];
  let historyList = HList;
  let undisplayedList = UList === undefined ? UList : [...UList];
  let currList = [...CList];

  const getNewItemsIndex = (
    NewList,
    CurrentIterationList,
    UndisplayedList,
    CurrList
  ) => {
    // first check it with curr list
    if (CurrList.length !== 0) {
      for (let i = newList.length - 1; i >= 0; i--) {
        if (CurrList.some((item) => item.uuid === NewList[i].uuid)) {
          return i;
        }
      }
    }
    // then check it with undisplayed and currentIterationList list
    const pastList = CurrentIterationList.concat(UndisplayedList);
    if (pastList.length !== 0) {
      for (let i = NewList.length - 1; i >= 0; i--) {
        if (pastList.some((item) => item.uuid === NewList[i].uuid)) {
          return i;
        }
      }
    }
    // else return the whole list
    return 0;
  };

  const getNewItems = (NewList, CurrentIterationList, UndisplayedList, CurrList) => {
    const index = getNewItemsIndex(
      NewList,
      CurrentIterationList,
      UndisplayedList,
      CurrList
    );
    return index === 0 ? [] : newList.slice(index + 1);
  };

  if (undisplayedList === undefined) {
    // on refresh or first load
    undisplayedList = newList.filter(
      (item) => !currentIterationList.some((e) => e.uuid === item.uuid)
    );
    if (undisplayedList.length !== 0) {
      // show the latest undisplayed item
      currList.push(undisplayedList.pop());
    }
  } else {
    // on polling
    const newItems = getNewItems(
      newList,
      currentIterationList,
      undisplayedList,
      currList
    );
    if (newItems.length !== 0) {
      // on polling add new items to curr list
      undisplayedList = undisplayedList.concat(currList);
      currList = newItems;
    } else {
      currList = currList.filter((item) => newList.some((e) => e.uuid === item.uuid));
    }
  }

  // removing deleted images from lists
  currentIterationList = currentIterationList.filter(
    (item) => newList.some((e) => e.uuid === item.uuid)
  );
  historyList = historyList.filter((item) => newList.some((e) => e.uuid === item.uuid));
  undisplayedList = undisplayedList.filter((item) => newList.some((e) => e.uuid === item.uuid));

  if (currList.length !== 0) {
    // if there are new images show the earliest new image
    currentIterationList.push(currList.shift());
  } else if (undisplayedList.length !== 0) {
    // else check if there are images in the undisplayedList
    const randomIndex = Math.floor(Math.random() * undisplayedList.length);
    currentIterationList.push(undisplayedList[randomIndex]);
    undisplayedList.splice(randomIndex, 1);
  } else if (currentIterationList.length > 0 && newList.length > 1) {
    // else no more images to show, shuffle undisplayed list
    undisplayedList = newList.filter(
      (item) => item.uuid !== currentIterationList[currentIterationList.length - 1].uuid
    );
    if (currentIterationList.length < LIVE_MODE_MAX_PHOTOS) {
      historyList = historyList.concat(currentIterationList);
    }
    const randomIndex = Math.floor(Math.random() * undisplayedList.length);
    currentIterationList = [undisplayedList[randomIndex]];
    undisplayedList.splice(randomIndex, 1);
  } else if (newList.length === 1) {
    // special case when there is just 1 image
    currentIterationList = [newList[0]];
    undisplayedList = [];
    historyList = [];
    currList = [];
  }

  // truncate existing list if it is longer than 200
  if (currentIterationList.length > LIVE_MODE_MAX_PHOTOS) {
    existingList = currentIterationList.slice(currentIterationList.length - LIVE_MODE_MAX_PHOTOS);
    historyList = [];
  } else {
    existingList = currentIterationList;
    if (currentIterationList.length + historyList.length > LIVE_MODE_MAX_PHOTOS) {
      historyList = historyList.slice(1);
    }
  }

  return [existingList, historyList, undisplayedList, currList, currentIterationList];
};

export default liveModeAlgorithm;
