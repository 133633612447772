import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setSentForgotPasswordEmailModalClose } from '../../slices/modal';

const SentForgotPasswordEmailModal = ({
  handleClose,
  open,
  forgotPasswordEmail
}) => {
  const { t } = useTranslation([TRANS_NAMESPACES.auth, TRANS_NAMESPACES.validation]);
  const dispatch = useDispatch();

  const isLargeScreen = useLargeScreenMediaQuery();

  const renderContent = () => (
    <div className="p-4 lg:p-0">
      <div className="text-2xl leading-9">
        {t('sent_forgot_password_email_title', "Let's Reset Your Password")}
      </div>
      <div className="mt-2">
        {t(
          'sent_forgot_password_email_description',
          'An email is sent to {{email}} with instructions to reset your password.'
        ).replace('{{email}}', forgotPasswordEmail)}
      </div>
      <div className="mt-8">
        <Button
          color="primary"
          variant="contained"
          className="w-full lg:w-auto lg:float-right"
          onClick={() => dispatch(setSentForgotPasswordEmailModalClose())}
          abTestId="sentForgotPasswordConfirm"
        >
          {t('sent_forgot_password_button_text', 'Okay')}
        </Button>
      </div>
    </div>
  );

  return isLargeScreen ? (
    <FixedWidthModal
      open={open}
      handleClose={handleClose}
      abTestId="sentForgotPasswordEmailModal"
    >
      {renderContent()}
    </FixedWidthModal>
  ) : (
    <FullscreenModal open={open} handleClose={handleClose} abTestId="sentForgotPasswordEmailModal">
      {renderContent()}
    </FullscreenModal>
  );
};

SentForgotPasswordEmailModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  forgotPasswordEmail: PropTypes.string.isRequired
};

export default SentForgotPasswordEmailModal;
