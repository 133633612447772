import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { GalleryUploaderContext } from '../features/gallery-upload';
import useToast from '../hooks/useToast';
import { TRANS_NAMESPACES } from '../services/i18next';
import checkFiles from '../utils/checkFiles';
import { noop } from '../utils/generic';

const Dropzone = ({
  children,
  maxFiles = 0,
  noDrag = false,
  isDefaultStyle = true,
  hideDropzone = noop,
  className = '',
  abTestId = '',
  noClick = false
}) => {
  const { t } = useTranslation(TRANS_NAMESPACES.upload);
  const { uploadProgressToast, errorToast } = useToast();
  const galleryUploader = useContext(GalleryUploaderContext);

  const showErrorToast = (invalidFileCount) => errorToast(t('invalid_file', `Can't upload ${invalidFileCount} ${invalidFileCount > 1 ? 'files' : 'file'}. Please upload JPG, PNG, or HEIC formats only.`, { count: invalidFileCount }));

  const onDrop = async (acceptedFiles, fileRejections) => {
    hideDropzone();
    let invalidFileCount = fileRejections.filter(({ errors }) => errors.some((error) => error.code === 'file-invalid-type')).length;

    if (acceptedFiles.length === 0) {
      showErrorToast(invalidFileCount);
      return;
    }

    const { validFiles, unprocessableFiles } = await checkFiles(acceptedFiles);

    // excluding unprocessable file count on purpose
    invalidFileCount += acceptedFiles.length - validFiles.length - unprocessableFiles.length;

    if (invalidFileCount > 0) {
      showErrorToast(invalidFileCount);
    }

    if (validFiles.length === 0) {
      return;
    }

    galleryUploader.pushToQueue(validFiles);
    uploadProgressToast(galleryUploader.getTotalProcessedItems(), galleryUploader.getTotalItems());
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [
        '.jpeg',
        '.jpg',
        '.png',
        '.heic',
      ],
    },
    maxFiles,
    multiple: maxFiles !== 1,
    noDrag,
    onDrop,
    noClick
  });

  const defaultClass = 'absolute top-0 left-0 z-50 h-full w-full overflow-y-hidden bg-white/[.96]';

  return (
    <div
      data-testid="dropzone"
      {...(abTestId ? { 'data-abtestid': abTestId } : {})}
      className={isDefaultStyle ? defaultClass : className}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

Dropzone.propTypes = {
  children: PropTypes.node.isRequired,
  maxFiles: PropTypes.number,
  noDrag: PropTypes.bool,
  isDefaultStyle: PropTypes.bool,
  hideDropzone: PropTypes.func,
  className: PropTypes.string,
  abTestId: PropTypes.string,
  noClick: PropTypes.bool
};

export default Dropzone;
