import { createSlice } from '@reduxjs/toolkit';

const toastContainerStyleSlice = createSlice({
  name: 'toastContainerStyle',
  initialState: {},
  reducers: {
    setBottomOffset: (state, { payload }) => ({ ...state, bottom: payload }),

    clearBottomOffset: (state) => {
      const { bottom, ...newState } = state;
      return newState;
    },
  }
});

export const {
  setBottomOffset,
  clearBottomOffset,
} = toastContainerStyleSlice.actions;

export default toastContainerStyleSlice;
