import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '../../assets/images';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { noop } from '../../utils/generic';

const PrintErrorModal = ({ open }) => {
  const isLargeScreen = useLargeScreenMediaQuery();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);

  const renderContent = () => (
    <>
      <div className="flex flex-col gap-y-2 items-center text-center">
        <img className="mx-auto" src={WarningIcon} alt="print icon" />
        <div className="text-2xl">
          {t('print_error_modal_header', 'Ops!')}
        </div>
        <div>
          {t('print_error_modal_description', 'An error occured during printing preparation. Please try again.')}
        </div>
      </div>
      <div className="mt-8 text-center">
        <Button
          className="w-52"
          color="primary"
          variant="contained"
          onClick={() => window.location.reload()}
          abTestId="printErrorModalOkay"
        >
          {t('print_error_modal_okay', 'Okay')}
        </Button>
      </div>
    </>
  );

  return (
    (isLargeScreen) ? (
      <FixedWidthModal
        className="w-full"
        open={open}
        handleClose={noop}
        withClosebutton={false}
        abTestId="printErrorModal"
      >
        {renderContent()}
      </FixedWidthModal>
    ) : (
      <FixedWidthModal
        className="mx-4 w-full"
        open={open}
        handleClose={noop}
        widthClass=""
        withClosebutton={false}
        abTestId="printErrorModal"
      >
        {renderContent()}
      </FixedWidthModal>
    )
  );
};

PrintErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default PrintErrorModal;
