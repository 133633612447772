import { createSlice } from '@reduxjs/toolkit';

const liveModeSlice = createSlice({
  name: 'liveMode',
  initialState: {
    isPlaying: true,
    photoIndex: 0,
    undisplayedList: undefined,
    currList: [],
    playTimeout: 3000
  },
  reducers: {
    setIsPlaying: (state, { payload }) => ({ ...state, isPlaying: payload }),
    setPhotoIndex: (state, { payload }) => ({ ...state, photoIndex: payload }),
    setUndisplayedList: (state, { payload }) => ({ ...state, undisplayedList: payload }),
    setCurrList: (state, { payload }) => ({ ...state, currList: payload }),
    setPlayTimeout: (state, { payload }) => ({ ...state, playTimeout: payload }),
    clearLiveMode: (state) => ({ ...state, undisplayedList: undefined, currList: [] }),
  },
});

export const {
  setIsPlaying,
  setPhotoIndex,
  setUndisplayedList,
  setCurrList,
  setPlayTimeout,
  clearLiveMode
} = liveModeSlice.actions;

export default liveModeSlice;
