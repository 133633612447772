import { createSlice } from '@reduxjs/toolkit';

const photobookSlice = createSlice({
  name: 'photobook',
  initialState: {
    isAdmin: false,
    sortOption: 'asc',
    addedToLibrary: false,
  },
  reducers: {
    setIsAdmin: (state, { payload }) => ({ ...state, isAdmin: payload }),
    setSortOption: (state, { payload }) => ({ ...state, sortOption: payload }),
    setAddedToLibrary: (state, { payload }) => ({ ...state, addedToLibrary: payload }),
  }
});

export const {
  setIsAdmin,
  setSortOption,
  setAddedToLibrary,
} = photobookSlice.actions;

export default photobookSlice;
