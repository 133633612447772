import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useRegenerateUrlIdentifierMutation } from '../../api/photobook';
import { WarningIcon } from '../../assets/images';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setLinkRegeneratedModalOpen, setRegenerateLinkModalClose } from '../../slices/modal';

const RegenerateLinkModal = ({ open }) => {
  const { photobookUrlIdentifier } = useParams();
  const isLargeScreen = useLargeScreenMediaQuery();
  const clearAuthTokens = useClearAuthTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookSettings);
  const { errorToast, whoopsToast } = useToast();
  const navigate = useNavigate();

  const [
    regenerateUrlIdentifier,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useRegenerateUrlIdentifierMutation();

  useEffect(() => {
    if (isSuccess) {
      const urlIdentifier = resData.data.url_identifier;
      dispatch(setRegenerateLinkModalClose());
      navigate(`/photobook/${urlIdentifier}/settings`);
      dispatch(setLinkRegeneratedModalOpen());
      return;
    }
    if (isError) {
      if (resError.status === 422) {
        errorToast(resError.data.errors.url_identifier[0]);
        return;
      }
      if (resError.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isSuccess,
    isError,
    resData,
    resError,
    clearAuthTokens,
    dispatch,
    errorToast,
    whoopsToast,
    navigate
  ]);

  const renderContent = () => (
    <>
      <div className="flex flex-col gap-y-2 text-center">
        <img className="mx-auto" src={WarningIcon} alt="warning icon" />
        <div className="leading-9 font-normal text-2xl">
          {t('regenerate_link_modal_header', 'Regenerate Invite Link?')}
        </div>
        <div>
          {t(
            'regenerate_link_modal_description',
            'This will secure your photobook from unwanted parties. Everyone except the owner of this photobook will lose access to this photobook. This action cannot be reversed. Share the newly generated invite link with your friends and family that you wish to have access to this photobook.'
          )}
        </div>
      </div>
      <div className="mt-8 flex flex-col-reverse gap-x-8 gap-y-4 justify-center lg:flex-row">
        <Button
          className="lg:w-52"
          color="primary"
          variant="contained"
          onClick={() => dispatch(setRegenerateLinkModalClose())}
          abTestId="regenerateLinkCancel"
        >
          {t('regenerate_link_modal_cancel_button', 'Cancel')}
        </Button>
        <Button
          className="lg:w-52"
          color="warning"
          variant="outlined"
          onClick={() => regenerateUrlIdentifier(photobookUrlIdentifier)}
          disabled={isLoading}
          abTestId="regenerateLinkSubmit"
        >
          {t('photobook_settings_regenerate_link_button', 'Regenerate Link')}
        </Button>
      </div>
    </>
  );

  return (
    (isLargeScreen) ? (
      <FixedWidthModal open={open} handleClose={() => dispatch(setRegenerateLinkModalClose())} abTestId="regenerateLinkModal">
        {renderContent()}
      </FixedWidthModal>
    ) : (
      <FullscreenModal className="p-4" open={open} handleClose={() => dispatch(setRegenerateLinkModalClose())} abTestId="regenerateLinkModal">
        {renderContent()}
      </FullscreenModal>
    )
  );
};

RegenerateLinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default RegenerateLinkModal;
