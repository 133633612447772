import React, { useCallback } from 'react';
import coreToast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { BlushCloseButton, CloseButton } from '../components/button';
import { TRANS_NAMESPACES } from '../services/i18next';
import useLargeScreenMediaQuery from './useLargeScreenMediaQuery';

const toastIds = {
  newPhoto: 'new-photo',
  uploadProgress: 'upload-progress',
  preparingDownload: 'preparing-download',
};

const useToast = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.misc);
  const isLargeScreen = useLargeScreenMediaQuery();

  const infoToast = useCallback(
    (text, abTestId = '', toastId = undefined, duration = 5000) => coreToast.custom(
      (toast) => (
        <div className="flex w-full lg:w-80 p-3 bg-white rounded-lg shadow-custom" {...(abTestId ? { 'data-abtestid': abTestId } : {})}>
          <div className="basis-1 flex-none bg-photobookBlue rounded" />
          <div className="grow ml-3 mr-4">{text}</div>
          <CloseButton className="flex-none" onClick={() => coreToast.dismiss(toast.id)} />
        </div>
      ),
      {
        id: toastId,
        duration,
        position: isLargeScreen ? 'bottom-right' : 'bottom-center',
      }
    ),
    [isLargeScreen]
  );

  const uploadProgressToast = (current, total) => {
    coreToast.remove(toastIds.newPhoto);
    coreToast.custom(
      <div className="w-full lg:w-80 p-3 bg-white rounded-lg shadow-custom" data-abtestid="uploadProgressToast">
        <div className="flex gap-x-2">
          <div className="mr-auto">{t('uploading_photos', 'Uploading photos...')}</div>
          <div className="text-darkGrey">
            {current}
            /
            {total}
          </div>
        </div>
        <div className="w-full h-2 mt-1 bg-grey rounded-lg">
          <div style={{ width: `${(current / total) * 100}%` }} className="h-full bg-photobookBlue rounded-lg" />
        </div>
      </div>,
      {
        id: toastIds.uploadProgress,
        duration: Infinity,
        position: isLargeScreen ? 'bottom-right' : 'bottom-center',
      }
    );
  };

  const newPhotoToast = useCallback((redirectTo) => {
    coreToast.remove(toastIds.uploadProgress);
    coreToast.custom(
      () => (
        <div className="flex w-full lg:w-80 p-3 bg-white rounded-lg shadow-custom" data-abtestid="newPhotoToast">
          <div className="basis-1 flex-none bg-photobookBlue rounded" />
          <div className="grow ml-3 mr-4">
            {t('new_photos_added', 'There are new photos added.')}
          </div>
          <button
            type="button"
            className="text-photobookBlue whitespace-nowrap"
            onClick={() => { window.location.href = redirectTo; }}
            data-abtestid="viewPhotos"
          >
            {t('view_photos', 'View Photos')}
          </button>
        </div>
      ),
      {
        id: toastIds.newPhoto,
        duration: Infinity,
        position: isLargeScreen ? 'bottom-right' : 'bottom-center',
      }
    );
  }, [isLargeScreen, t]);

  const errorToast = useCallback(
    (text) => coreToast.custom(
      (toast) => (
        <div className="flex w-full lg:w-80 p-3 bg-white rounded-lg shadow-custom">
          <div className="basis-1 flex-none bg-blush rounded" />
          <div className="grow ml-3 mr-4 text-blush">{text}</div>
          <BlushCloseButton className="flex-none" onClick={() => coreToast.dismiss(toast.id)} />
        </div>
      ),
      {
        duration: 5000,
        position: isLargeScreen ? 'bottom-right' : 'bottom-center',
      }
    ),
    [isLargeScreen]
  );

  const whoopsToast = useCallback(
    () => errorToast(t('something_went_wrong', 'Something went wrong')),
    [errorToast, t]
  );

  const removeToast = useCallback((toastId) => {
    coreToast.remove(toastId);
  }, []);

  return {
    toastIds,
    infoToast,
    uploadProgressToast,
    newPhotoToast,
    errorToast,
    whoopsToast,
    removeToast,
  };
};

export default useToast;
