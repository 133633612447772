import { configureStore } from '@reduxjs/toolkit';
import baseApi from '../api/base';
import authSlice from '../slices/auth';
import downloadSlice from '../slices/download';
import liveModeSlice from '../slices/liveMode';
import liveModeCacheSlice from '../slices/liveModeCache';
import modalSlice from '../slices/modal';
import photobookSlice from '../slices/photobook';
import projectCreationSlice from '../slices/projectCreation';
import toastContainerStyleSlice from '../slices/toastContainerStyle';
import translationSlice from '../slices/translation';
import { loadState, saveState } from '../utils/localStorage';

const store = configureStore({
  preloadedState: loadState(),
  reducer: {
    [translationSlice.name]: translationSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [liveModeSlice.name]: liveModeSlice.reducer,
    [liveModeCacheSlice.name]: liveModeCacheSlice.reducer,
    [modalSlice.name]: modalSlice.reducer,
    [photobookSlice.name]: photobookSlice.reducer,
    [toastContainerStyleSlice.name]: toastContainerStyleSlice.reducer,
    [downloadSlice.name]: downloadSlice.reducer,
    [projectCreationSlice.name]: projectCreationSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
});

store.subscribe(() => saveState(
  {
    auth: store.getState().auth,
    liveModeCache: store.getState().liveModeCache
  }
));

export default store;
