const targetType = 'image/jpeg';

const customToBlob = (callback, type, quality) => {
  // From https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob, needed for Safari:
  const binStr = atob(this.toDataURL(type, quality).split(',')[1]);
  const len = binStr.length;
  const arr = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

  callback(new Blob([arr], { type: type || targetType }));
};

const getCanvas = () => {
  const canvasElement = document.createElement('canvas');
  if (!canvasElement.toBlob) {
    canvasElement.toBlob = customToBlob;
  }
  return canvasElement;
};

const imageToDrawnCanvas = (imageElement, width, height) => {
  const canvas = getCanvas();
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  context.drawImage(imageElement, 0, 0, width, height);
  return canvas;
};

const fileToImageElement = (file) => new Promise((resolve, reject) => {
  const imageElement = new Image();
  imageElement.onerror = (error) => {
    URL.revokeObjectURL(imageElement.src);
    reject(error);
  };
  imageElement.onload = () => {
    URL.revokeObjectURL(imageElement.src);
    resolve(imageElement);
  };
  imageElement.src = URL.createObjectURL(file);
});

const imageElementToCanvasBlob = (imageElement, width, height, quality) => new Promise(
  (resolve) => {
    const canvas = imageToDrawnCanvas(imageElement, width, height);
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      targetType,
      quality
    );
  }
);

const getProperDimensions = (width, height, desiredDimension) => {
  const maxDimension = Math.max(width, height);
  const scale = Math.min(desiredDimension / maxDimension, 1);
  return {
    width: Math.round(width * scale),
    height: Math.round(height * scale),
    quality: Math.max(scale, 0.92),
    needsProcess: scale < 1,
  };
};

const getSizeRate = (currentSize, desiredSize) => Math.min(desiredSize / currentSize, 1);

const getConvertedImage = (imageFile, imageElement, newDimensions) => {
  if (newDimensions.needsProcess || (imageFile.type !== targetType)) {
    return imageElementToCanvasBlob(
      imageElement,
      newDimensions.width,
      newDimensions.height,
      newDimensions.quality
    ).then((canvasBlob) => (new File(
      [canvasBlob],
      'resizable-printable',
      { type: targetType }
    )));
  }
  return new Promise((resolve) => { resolve(imageFile); });
};

const resizeImage = (imageFile, maxDimension) => fileToImageElement(imageFile)
  .then((imageElement) => {
    const newDimensions = getProperDimensions(
      imageElement.width,
      imageElement.height,
      maxDimension
    );
    return getConvertedImage(imageFile, imageElement, newDimensions);
  });

const compressImage = (imageFile, maxSize) => fileToImageElement(imageFile)
  .then((imageElement) => {
    const sizeRate = getSizeRate(imageFile.size, maxSize);
    const newDimensions = {
      width: imageElement.width,
      height: imageElement.height,
      quality: sizeRate,
      needsProcess: sizeRate < 1
    };
    return getConvertedImage(
      imageFile,
      imageElement,
      newDimensions
    );
  });

/**
 * Inspired by PoC & https://stackoverflow.com/questions/14672746/how-to-compress-an-image-via-javascript-in-the-browser/44849182#44849182
 * Need to resize & compress only if needed, small image (in pixel & size) means nothing to be done.
 * However, to ensure the right JPEG conversion, we'll resume processing if mime type isn't JPEG.
 * To know the size (width*height), file has to be converted into an image blob.
 * Once original size is known, we compute new dimensions based on one scale led by the largest one.
 * Scaling both dimensions keeps the aspect ratio & ensures the largest one will be small enough.
 * Using the Math.min function, scale will be 1 if largest dimension is less than max allowed.
 * Quality is a value [0.92, 1] passed down to the canvas blob conversion, the proclaim is:
 * if we are reducing pixels count enough then there's no need to reduce quality with same scale.
 * After these parameters are specified, we can create canvas blob by drawing the image blob.
 * Then the canvas blob needs to be converted to a file to get the byte size.
 * With same logic with Math.min, scale will be < 1 if size is large and needs to compressed.
 * Using the same function, we change the image by resizing first and compressing second,
 * In summary, first try is by messing with pixel size, the second by messing with quality.
 * Pipeline: file to image element, draw it on canvas, get canvas's blob, convert back to file.
 */
export const shrink = (imageFile, maxDimension, maxSize) => resizeImage(imageFile, maxDimension)
  .then((resizedImageFile) => compressImage(resizedImageFile, maxSize))
  .catch((e) => {
    console.error(e); // eslint-disable-line no-console
  });

export const packFileData = (file, name, lastModified) => new File([file], name, {
  lastModified,
  lastModifiedDate: new Date(lastModified),
  type: targetType
});
