import baseApi from './base';

const genericApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createZip: builder.mutation({
      query: (data) => ({
        url: '/create-zip',
        method: 'POST',
        body: data,
      }),
    })
  }),
});

export default genericApi;
