import { createSlice } from '@reduxjs/toolkit';

const downloadSlice = createSlice({
  name: 'download',
  initialState: {
    inSelectionMode: false,
    selectedPhotos: [],
  },
  reducers: {
    enterSelectionMode: (state) => ({ ...state, inSelectionMode: true }),
    exitSelectionMode: (state) => ({ ...state, inSelectionMode: false }),

    addToSelection: (state, { payload }) => {
      const selectedPhotos = [...state.selectedPhotos, payload];
      return { ...state, selectedPhotos };
    },

    removeFromSelection: (state, { payload }) => {
      const selectedPhotos = state.selectedPhotos.filter((photo) => photo !== payload);
      return { ...state, selectedPhotos };
    },

    replaceSelection: (state, { payload }) => ({ ...state, selectedPhotos: payload }),
    clearSelection: (state) => ({ ...state, selectedPhotos: [] }),
  }
});

export const {
  addToSelection,
  clearSelection,
  enterSelectionMode,
  exitSelectionMode,
  removeFromSelection,
  replaceSelection,
} = downloadSlice.actions;

export default downloadSlice;
