import PropTypes from 'prop-types';
import React from 'react';
import { CloseIcon } from '../../assets/images';

const CloseButton = ({ className, onClick }) => (
  <button className={className} data-testid="close-button" data-abtestid="closeButton" type="button" onClick={onClick}>
    <img src={CloseIcon} alt="close icon" />
  </button>
);

CloseButton.defaultProps = {
  className: '',
};

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
