import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRemoveRecentVisitMutation } from '../../api/recentVisit';
import { DeleteIcon, EmptyImagePlaceholder } from '../../assets/images';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setDeleteRecentVisitModalOpen } from '../../slices/modal';

const PhotobookCard = ({
  photobook,
  index,
  setPhotobookUrlIdentifier
}) => {
  const {
    coverImage,
    name,
    visitedAt,
    urlIdentifier,
    inLibrary,
  } = photobook;

  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.recentVisits);
  const { infoToast, whoopsToast } = useToast();
  const clearAuthTokens = useClearAuthTokens();

  const [
    removeRecentVisit,
    {
      isLoading,
      isSuccess,
      isError,
      error
    }
  ] = useRemoveRecentVisitMutation();

  useEffect(() => {
    if (isSuccess) {
      infoToast(
        t('delete_recent_visit_modal_success', 'Photobook removed from recent visits'),
        'recentVisitDeletedToast'
      );
      return;
    }

    if (isError) {
      if (error.status === 401) {
        clearAuthTokens();
        return;
      }
      if (error.status === 404) {
        infoToast(
          t('delete_recent_visit_modal_success', 'Photobook removed from recent visits'),
          'recentVisitDeletedToast'
        );
        return;
      }
      whoopsToast();
    }
  }, [
    isError,
    isSuccess,
    error,
    t,
    infoToast,
    whoopsToast,
    clearAuthTokens,
  ]);

  const getVisitedDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' })}`;
  };

  return (
    <div className="flex justify-between gap-x-8 px-4 lg:px-0 py-4">
      <Link to={`/photobook/${urlIdentifier}`} className="flex w-full" state="recent">
        <img src={coverImage ?? EmptyImagePlaceholder} className="self-center aspect-square object-cover rounded-lg w-12 h-12" alt="cover photo" data-abtestid={`photobookThumbnail${index + 1}`} />
        <div className="mx-2">
          <p className="break-words" data-abtestid={`photobookName${index + 1}`}>{name}</p>
          <p className="text-xs text-darkGrey">
            {t('last_visited_on', 'Last visited on')}
            {' '}
            {getVisitedDate(visitedAt)}
          </p>
        </div>
      </Link>
      <button
        type="button"
        className="self-center w-5 h-5"
        disabled={isLoading}
        onClick={() => {
          if (!inLibrary) {
            setPhotobookUrlIdentifier(urlIdentifier);
            dispatch(setDeleteRecentVisitModalOpen());
          } else {
            removeRecentVisit({ photobook_url_identifier: urlIdentifier });
          }
        }}
      >
        <img className="min-w-[20px] w-5 h-5" src={DeleteIcon} alt="Delete" />
      </button>
    </div>
  );
};

PhotobookCard.propTypes = {
  photobook: PropTypes.shape({
    coverImage: PropTypes.string,
    name: PropTypes.string.isRequired,
    visitedAt: PropTypes.number.isRequired,
    urlIdentifier: PropTypes.string.isRequired,
    inLibrary: PropTypes.bool.isRequired,
  }),
  index: PropTypes.number.isRequired,
  setPhotobookUrlIdentifier: PropTypes.func.isRequired,
};

export default PhotobookCard;
