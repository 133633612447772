import * as qr from '@bitjson/qr-code';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetPhotobookQuery } from '../../api/photobook';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setLinkRegeneratedModalClose } from '../../slices/modal';
import buildInvitationLink from '../../utils/buildInvitationLink';
import copyToClipboard from '../../utils/copyToClipboard';
import { SocialMediaIcons } from '../share-photobook';

const LinkRegeneratedModal = ({ open }) => {
  const isLargeScreen = useLargeScreenMediaQuery();
  const { photobookUrlIdentifier } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation([
    TRANS_NAMESPACES.photobookDetails,
    TRANS_NAMESPACES.sharePhotobook
  ]);
  const { infoToast } = useToast();

  const inviteLink = buildInvitationLink(photobookUrlIdentifier);
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data,
    isSuccess
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });

  const handleCopyLinkClick = () => {
    copyToClipboard(inviteLink);
    infoToast(t('copy_link_success', 'Photobook link copied.', { ns: TRANS_NAMESPACES.sharePhotobook }), 'linkRegeneratedModalCopyLinkToast');
  };

  useEffect(() => qr.defineCustomElements(window), []);

  const renderContent = () => (
    <div className="flex flex-col gap-y-8 items-center">
      <div className="flex flex-col gap-y-2 items-center">
        <qr-code contents={inviteLink} style={{ width: '120px', height: '120px' }} mask-x-to-y-ratio="2" data-abtestid="linkRegeneratedModalQrCode">
          <img src="/qrCodeLogo.png" slot="icon" alt="qr code logo" />
        </qr-code>
        <div className="leading-9 font-normal text-center text-2xl">
          {t('link_regenerated_modal_header', 'Invite Link Regenerated!')}
        </div>
        <div className="font-bold">
          {t('invite_members_modal_header', 'Invite more members', { ns: TRANS_NAMESPACES.sharePhotobook })}
        </div>
        <div className="text-center">
          {t(
            'invite_members_modal_description',
            'Add as many members as you wish, anyone can contribute photos. Let your friends and family scan the QR code to join your Photobook, or share this Photobook link via any social media.',
            { ns: TRANS_NAMESPACES.sharePhotobook }
          )}
        </div>
      </div>
      { isSuccess
        && (
        <SocialMediaIcons
          link={inviteLink}
          title={data.data.photobook.name}
          abTestId="linkRegeneratedModal"
        />
        )}
      <div className="flex w-full justify-center">
        <Button
          className="px-16 w-full lg:w-fit"
          color="primary"
          variant="contained"
          onClick={handleCopyLinkClick}
          abTestId="linkRegeneratedModalCopyLink"
        >
          {t('copy_link', 'Copy Link', { ns: TRANS_NAMESPACES.sharePhotobook })}
        </Button>
      </div>
    </div>
  );

  return (
    (isLargeScreen) ? (
      <FixedWidthModal open={open} handleClose={() => dispatch(setLinkRegeneratedModalClose())} abTestId="linkRegeneratedModal">
        {renderContent()}
      </FixedWidthModal>
    ) : (
      <FullscreenModal className="p-4" open={open} handleClose={() => dispatch(setLinkRegeneratedModalClose())} abTestId="linkRegeneratedModal">
        {renderContent()}
      </FullscreenModal>
    )
  );
};

LinkRegeneratedModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default LinkRegeneratedModal;
