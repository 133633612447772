// the prices are as of 3rd/Sept/2023 for product_code: MOB_PB-SSC0808
const prices = {
  PBMY: {
    price: '119.00',
    currencyCode: 'MYR'
  },
  PBAU: {
    price: '39.95',
    currencyCode: 'AUD'
  },
  PBCA: {
    price: '36.99',
    currencyCode: 'CAD'
  },
  PBUS: {
    price: '29.99',
    currencyCode: 'USD'
  },
  PBSG: {
    price: '52.90',
    currencyCode: 'SGD'
  },
  PBBN: {
    price: '52.90',
    currencyCode: 'SGD'
  },
  PBPH: {
    price: '2200.00',
    currencyCode: 'PHP'
  },
  PBHK: {
    price: '258.00',
    currencyCode: 'HKD'
  },
  PBMO: {
    price: '258.00',
    currencyCode: 'HKD'
  },
  PBNZ: {
    price: '49.95',
    currencyCode: 'NZD'
  },
  PBUK: {
    price: '29.95',
    currencyCode: 'GBP'
  },
  PBEU: {
    price: '34.95',
    currencyCode: 'EUR'
  },
  PBJP: {
    price: '4200.00',
    currencyCode: 'JPY'
  },
  PBTW: {
    price: '1088.00',
    currencyCode: 'TWD'
  },
  PBID: {
    price: '450000.00',
    currencyCode: 'IDR'
  },
  PBIN: {
    price: '34.99',
    currencyCode: 'USD'
  },
  PBKR: {
    price: '34.99',
    currencyCode: 'USD'
  },
  PBAE: {
    price: '34.99',
    currencyCode: 'USD'
  },
  PBAF: {
    price: '44.99',
    currencyCode: 'USD'
  },
  PBWW: {
    price: '44.99',
    currencyCode: 'USD'
  },
  PBCN: {
    price: '238.00',
    currencyCode: 'CNY'
  },
  PBVN: {
    price: '650000.00',
    currencyCode: 'VND'
  },
  PBTH: {
    price: '700.00',
    currencyCode: 'THB'
  }
};

const getPriceInfo = (storeCode) => {
  const info = prices[storeCode] ?? prices.PBWW;
  return { price: info.price, currencyCode: info.currencyCode };
};

export default getPriceInfo;
