import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    authModalOpen: false,
    sendForgotPasswordEmailModalOpen: false,
    sentForgotPasswordEmailModalOpen: false,
    mergeModalOpen: false,
    guestModalOpen: false,
    deletePhotoModalOpen: false,
    authModalTab: 'register',
    registerRoute: '',
    loginRoute: '',
    termsOfUseModalOpen: false,
    privacyPolicyModalOpen: false,
    addPhotosModalOpen: false,
    inviteMembersModalOpen: false,
    regenerateLinkModalOpen: false,
    linkRegeneratedModalOpen: false,
    deleteRecentVisitsModalOpen: false,
    deleteRecentVisitModalOpen: false,
    createPhotobookModalOpen: false,
    printModalOpen: false,
    printPhotoMaxLimitModalOpen: false,
    printLoadingModalOpen: false,
    printErrorModalOpen: false,
    printOddPhotosModalOpen: false,
  },
  reducers: {
    setAuthModalOpen: (state) => ({ ...state, authModalOpen: true }),
    setAuthModalClose: (state) => ({ ...state, authModalOpen: false }),
    setSendForgotPasswordEmailModalOpen: (state) => (
      { ...state, sendForgotPasswordEmailModalOpen: true }
    ),
    setSendForgotPasswordEmailModalClose: (state) => (
      { ...state, sendForgotPasswordEmailModalOpen: false }
    ),
    setSentForgotPasswordEmailModalOpen: (state) => (
      { ...state, sentForgotPasswordEmailModalOpen: true }
    ),
    setSentForgotPasswordEmailModalClose: (state) => (
      { ...state, sentForgotPasswordEmailModalOpen: false }
    ),
    setMergeModalOpen: (state) => ({ ...state, mergeModalOpen: true }),
    setMergeModalClose: (state) => ({ ...state, mergeModalOpen: false }),
    setGuestModalOpen: (state) => ({ ...state, guestModalOpen: true }),
    setGuestModalClose: (state) => ({ ...state, guestModalOpen: false }),
    setDeletePhotoModalOpen: (state) => ({ ...state, deletePhotoModalOpen: true }),
    setDeletePhotoModalClose: (state) => ({ ...state, deletePhotoModalOpen: false }),
    setAuthModalLoginTab: (state) => ({ ...state, authModalTab: 'login' }),
    setAuthModalRegisterTab: (state) => ({ ...state, authModalTab: 'register' }),
    setTermofuseModalOpen: (state) => ({ ...state, termsOfUseModalOpen: true }),
    setTermofuseModalClose: (state) => ({ ...state, termsOfUseModalOpen: false }),
    setPrivacypolicyModalOpen: (state) => ({ ...state, privacyPolicyModalOpen: true }),
    setPrivacypolicyModalClose: (state) => ({ ...state, privacyPolicyModalOpen: false }),
    setAddPhotosModalOpen: (state) => ({ ...state, addPhotosModalOpen: true }),
    setAddPhotosModalClose: (state) => ({ ...state, addPhotosModalOpen: false }),
    setInviteMembersModalOpen: (state) => ({ ...state, inviteMembersModalOpen: true }),
    setInviteMembersModalClose: (state) => ({ ...state, inviteMembersModalOpen: false }),
    setRegenerateLinkModalOpen: (state) => ({ ...state, regenerateLinkModalOpen: true }),
    setRegenerateLinkModalClose: (state) => ({ ...state, regenerateLinkModalOpen: false }),
    setLinkRegeneratedModalOpen: (state) => ({ ...state, linkRegeneratedModalOpen: true }),
    setLinkRegeneratedModalClose: (state) => ({ ...state, linkRegeneratedModalOpen: false }),
    setDeleteRecentVisitsModalOpen: (state) => ({ ...state, deleteRecentVisitsModalOpen: true }),
    setDeleteRecentVisitsModalClose: (state) => ({ ...state, deleteRecentVisitsModalOpen: false }),
    setDeleteRecentVisitModalOpen: (state) => ({ ...state, deleteRecentVisitModalOpen: true }),
    setDeleteRecentVisitModalClose: (state) => ({ ...state, deleteRecentVisitModalOpen: false }),
    setCreatePhotobookModalOpen: (state) => ({ ...state, createPhotobookModalOpen: true }),
    setCreatePhotobookModalClose: (state) => ({ ...state, createPhotobookModalOpen: false }),
    setPrintModalOpen: (state) => ({ ...state, printModalOpen: true }),
    setPrintModalClose: (state) => ({ ...state, printModalOpen: false }),
    setPrintPhotoMaxLimitModalOpen: (state) => ({ ...state, printPhotoMaxLimitModalOpen: true }),
    setPrintPhotoMaxLimitModalClose: (state) => ({ ...state, printPhotoMaxLimitModalOpen: false }),
    setPrintLoadingModalOpen: (state) => ({ ...state, printLoadingModalOpen: true }),
    setPrintLoadingModalClose: (state) => ({ ...state, printLoadingModalOpen: false }),
    setPrintErrorModalOpen: (state) => ({ ...state, printErrorModalOpen: true }),
    setPrintErrorModalClose: (state) => ({ ...state, printErrorModalOpen: false }),
    setPrintOddPhotosModalOpen: (state) => ({ ...state, printOddPhotosModalOpen: true }),
    setPrintOddPhotosModalClose: (state) => ({ ...state, printOddPhotosModalOpen: false }),
  },
});

export const {
  setAuthModalOpen,
  setAuthModalClose,
  setSendForgotPasswordEmailModalOpen,
  setSendForgotPasswordEmailModalClose,
  setSentForgotPasswordEmailModalOpen,
  setSentForgotPasswordEmailModalClose,
  setMergeModalOpen,
  setMergeModalClose,
  setGuestModalOpen,
  setGuestModalClose,
  setDeletePhotoModalOpen,
  setDeletePhotoModalClose,
  setAuthModalLoginTab,
  setAuthModalRegisterTab,
  setTermofuseModalOpen,
  setTermofuseModalClose,
  setPrivacypolicyModalOpen,
  setPrivacypolicyModalClose,
  setAddPhotosModalOpen,
  setAddPhotosModalClose,
  setInviteMembersModalOpen,
  setInviteMembersModalClose,
  setRegenerateLinkModalOpen,
  setRegenerateLinkModalClose,
  setLinkRegeneratedModalOpen,
  setLinkRegeneratedModalClose,
  setDeleteRecentVisitsModalOpen,
  setDeleteRecentVisitsModalClose,
  setDeleteRecentVisitModalOpen,
  setDeleteRecentVisitModalClose,
  setCreatePhotobookModalOpen,
  setCreatePhotobookModalClose,
  setPrintModalOpen,
  setPrintModalClose,
  setPrintPhotoMaxLimitModalOpen,
  setPrintPhotoMaxLimitModalClose,
  setPrintLoadingModalOpen,
  setPrintLoadingModalClose,
  setPrintErrorModalOpen,
  setPrintErrorModalClose,
  setPrintOddPhotosModalOpen,
  setPrintOddPhotosModalClose,
} = modalSlice.actions;

export default modalSlice;
