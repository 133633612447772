import React from 'react';
import { PhotobookLiveLogo } from '../../assets/images';

const NotFoundTopNavigation = () => (
  <div className="flex justify-center border-b border-fog min-h-[60px] px-4 lg:px-8">
    <img className="w-36" src={PhotobookLiveLogo} alt="Photobook Live logo" />
  </div>
);

export default NotFoundTopNavigation;
