import React, { useRef, useState } from 'react';
import { Carousel, TopNavigation } from '../features/gallery-viewer';
import useWindowSize from '../hooks/useWindowSize';

const GalleryViewer = () => {
  const { width: windowWidth = 0, height: windowHeight = 0 } = useWindowSize();
  const topNavigationRef = useRef(null);
  const topNavigationHeight = (topNavigationRef.current && topNavigationRef.current.offsetHeight)
    ?? 0;

  const [downloadData, setDownloadData] = useState({
    from: null,
    saveAs: null,
  });

  // TODO
  const sidebarWidth = 0;

  return (
    <>
      <div ref={topNavigationRef}>
        <TopNavigation downloadData={downloadData} />
      </div>

      <Carousel
        width={windowWidth - sidebarWidth}
        height={windowHeight - topNavigationHeight}
        setDownloadData={setDownloadData}
      />
    </>
  );
};

export default GalleryViewer;
