import camelize from 'camelize';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreatePhotobookMutation } from '../../api/photobook';
import { GreyInfoIcon } from '../../assets/images';
import { Button, SubmitButton } from '../../components/button';
import IconPaddedText from '../../components/IconPaddedText';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setCreatePhotobookModalClose } from '../../slices/modal';

const CreatePhotobookModal = ({ open }) => {
  const dispatch = useDispatch();
  const isLargeScreen = useLargeScreenMediaQuery();
  const { t } = useTranslation([TRANS_NAMESPACES.library, TRANS_NAMESPACES.validation]);

  const {
    handleSubmit,
    register,
    setError,
    setFocus,
    setValue,
    formState: { isValidating, isSubmitting, errors },
  } = useForm();

  const { whoopsToast } = useToast();

  const navigate = useNavigate();
  const clearAuthTokens = useClearAuthTokens();

  const [
    createPhotobook,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useCreatePhotobookMutation();

  useEffect(() => {
    if (isSuccess) {
      const {
        photobook: {
          urlIdentifier = ''
        }
      } = camelize(resData.data);
      dispatch(setCreatePhotobookModalClose());
      navigate(`/photobook/${urlIdentifier}`);
      return;
    }
    if (isError) {
      if (resError.status === 422) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [field, [error]] of Object.entries(resError.data.errors)) {
          setError(field, { message: error });
        }
        return;
      }
      if (resError.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isSuccess,
    isError,
    resData,
    resError,
    setError,
    navigate,
    whoopsToast,
    clearAuthTokens,
    dispatch
  ]);

  useEffect(() => {
    if (open) {
      setFocus('name');
    }
  }, [open, setFocus]);

  const handleClose = () => {
    setValue('name', '');
    dispatch(setCreatePhotobookModalClose());
  };

  const renderContent = () => (
    <div className="p-4 lg:p-0">
      <form onSubmit={handleSubmit((data) => createPhotobook(data))}>
        <input
          className={`w-full px-4 py-3 text-base text-charcoal placeholder-darkGrey border-[1px] rounded-lg focus:outline-none ${errors.name ? 'border-blush' : 'border-grey'}`}
          type="text"
          placeholder={t('create_photobook_modal_name_input', 'Photobook name')}
          autoComplete="off"
          data-abtestid="photobookName"
          {...register('name', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })}
        />
        {errors.name && <div className="mt-2 text-blush">{errors.name.message}</div>}
        <div className="px-4 py-3 mt-4 text-darkGrey text-base border border-grey rounded-lg">
          <IconPaddedText
            icon={<img className="mr-2" src={GreyInfoIcon} alt="More information" />}
            text={t('create_photobook_modal_private_photobook_message', 'This is a private Photobook, only members with the link can view and add photos into this album.')}
          />
        </div>
        <div className="mt-8 flex flex-col-reverse justify-end gap-y-4 lg:flex-row">
          <Button
            color="tertiary"
            variant="text"
            onClick={handleClose}
            abTestId="createPhotobookCancel"
          >
            {t('create_photobook_modal_cancel_button', 'Cancel')}
          </Button>
          <SubmitButton
            className="w-100 lg:ml-4 lg:w-52"
            color="primary"
            variant="outlined"
            disabled={isLoading || isValidating || isSubmitting}
            abTestId="createPhotobookSubmit"
          >
            {t('create_photobook_modal_button', 'Create Photobook')}
          </SubmitButton>
        </div>
      </form>
    </div>
  );

  return isLargeScreen ? (
    <FixedWidthModal
      open={open}
      handleClose={handleClose}
      withClosebutton={false}
      abTestId="createPhotobookModal"
    >
      {renderContent()}
    </FixedWidthModal>
  ) : (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      withClosebutton={false}
      abTestId="createPhotobookModal"
    >
      {renderContent()}
    </FullscreenModal>
  );
};

CreatePhotobookModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default CreatePhotobookModal;
