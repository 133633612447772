import { createSlice } from '@reduxjs/toolkit';

const translationSlice = createSlice({
  name: 'translation',
  initialState: {},
  reducers: {
    setTranslation: (state, action) => ({ ...state, ...action.payload })
  }
});

export const { setTranslation } = translationSlice.actions;

export default translationSlice;
