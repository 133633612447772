import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRegisterGuestMutation } from '../../api/auth';
import { SubmitButton } from '../../components/button';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setGuest } from '../../slices/auth';
import { setGuestModalClose, setPrivacypolicyModalOpen, setTermofuseModalOpen } from '../../slices/modal';

const RegisterGuestForm = () => {
  const { t } = useTranslation([TRANS_NAMESPACES.auth, TRANS_NAMESPACES.validation]);
  const { whoopsToast } = useToast();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValidating, isSubmitting, errors },
  } = useForm();

  const [
    registerGuest,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useRegisterGuestMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      const {
        uuid: token,
        name
      } = resData.data.guest;

      dispatch(setGuest({ token, name }));
      dispatch(setGuestModalClose());
      return;
    }

    if (isError) {
      if (resError.status === 422) {
        setError('name', { message: resError.data.errors.name[0] });
        return;
      }

      whoopsToast();
    }
  }, [
    dispatch,
    isError,
    isSuccess,
    resData,
    resError,
    setError,
    whoopsToast,
  ]);

  const termModalHandleClick = () => {
    dispatch(setGuestModalClose());
    dispatch(setTermofuseModalOpen());
  };

  const privacyModalHandleClick = () => {
    dispatch(setGuestModalClose());
    dispatch(setPrivacypolicyModalOpen());
  };

  return (
    <form className="-mt-4" onSubmit={handleSubmit((data) => registerGuest(data))}>
      <input {...register('name', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })} className={`w-full py-3 px-4 mt-4 border rounded-lg ${errors.name ? 'border-blush' : 'border-grey'}`} placeholder={t('register_guest_form_field_name', 'Name')} autoComplete="nickname" data-abtestid="guestRegisterName" />
      {errors.name && <div className="mt-2 text-blush">{errors.name.message}</div>}

      <SubmitButton color="primary" variant="contained" className="w-full mt-4" disabled={isLoading || isValidating || isSubmitting} abTestId="guestRegisterSubmit">{t('register_guest_form_submit_button', 'Continue As Guest')}</SubmitButton>

      <div className="mt-4 text-center">
        <Trans ns={TRANS_NAMESPACES.auth} i18nKey="auth_tnc_reminder">
          By continuing, you agree to Photobook&apos;s
          {' '}
          <span className="cursor-pointer font-bold text-photobookBlue" onClick={termModalHandleClick} data-abtestid="registerGuestTermsOfUseLink">Terms of Use</span>
          {' '}
          and
          {' '}
          <span className="cursor-pointer font-bold text-photobookBlue" onClick={privacyModalHandleClick} data-abtestid="registerGuestPrivacyPolicyLink">Privacy Policy</span>
          .
        </Trans>
      </div>
    </form>
  );
};

export default RegisterGuestForm;
