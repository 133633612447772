import PropTypes from 'prop-types';
import React from 'react';
import Pane from './Pane';

const Panes = ({
  items,
  activeTab,
  className = '',
}) => {
  const pane = items.find((item) => activeTab === item.id);

  return (
    <Pane className={className}>
      {pane.content}
    </Pane>
  );
};

Panes.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
  })).isRequired,
  className: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
};

export default Panes;
