import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetApStoreCountryQuery } from '../../api/ap';
import { useGetPhotobookQuery } from '../../api/photobook';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setPrintModalClose } from '../../slices/modal';
import {
  addOrReplaceCoverSelection,
  clearCoverSelection,
  clearPrintSelection,
  disallowPrintOddPhotos,
  enterCoverSelectionMode,
} from '../../slices/projectCreation';
import getPriceInfo from '../../utils/priceInfo';

const PrintModal = ({ open }) => {
  const dispatch = useDispatch();
  const isLargeScreen = useLargeScreenMediaQuery();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const { photobookUrlIdentifier } = useParams();
  const { whoopsToast } = useToast();

  const {
    data,
    isSuccess
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });

  const {
    data: apStoreCountryData,
    isError: apStoreCountryError
  } = useGetApStoreCountryQuery();

  useEffect(() => {
    if (apStoreCountryError) {
      whoopsToast();
    }
  }, [apStoreCountryError, whoopsToast]);

  const { price, currencyCode } = getPriceInfo(apStoreCountryData?.data?.code);

  const beginPrintFlow = () => {
    dispatch(clearCoverSelection());
    dispatch(clearPrintSelection());
    dispatch(disallowPrintOddPhotos());
    if (isSuccess) {
      if (data.data?.photobook.images.list && data.data.photobook.images.list.length > 0) {
        dispatch(addOrReplaceCoverSelection(data.data.photobook.images.list[0].uuid));
      }
    }
    dispatch(enterCoverSelectionMode());
    dispatch(setPrintModalClose());
  };

  const renderContent = () => (
    <>
      <div className="flex flex-col items-center text-center">
        <div className="grid gap-y-4">
          <div className="text-2xl">
            {t('print_modal_header', 'Print Memories in a Few Steps')}
          </div>
          <div className="text-base">
            {t('print_modal_description', 'Print a Simple Book 8" x 8" Imagewrap Hardcover by selecting a cover photo and minimum of 20 to maximum of 150 content photos, and then your physical photo book is ready for you.')}
          </div>
          <div className="text-base">
            {t('print_modal_amount', `From ${currencyCode}${price}`)}
          </div>
        </div>
      </div>
      <div className="pt-8 grid grid-cols-1 lg:grid-cols-2 text-center">
        <div className="order-last lg:order-first pt-4 lg:pt-0 lg:pr-4 flex flex-col items-center">
          <Button
            className="w-full"
            color="secondary"
            variant="outlined"
            onClick={() => dispatch(setPrintModalClose())}
            abTestId="printModalCancel"
          >
            {t('print_modal_cancel_button', 'Cancel')}
          </Button>
        </div>
        <div className="lg:pl-4 flex flex-col items-center">
          <Button
            className="w-full"
            color="primary"
            variant="contained"
            onClick={() => beginPrintFlow()}
            abTestId="printModalStart"
          >
            {t('print_modal_start_button', "Let's Get Started")}
          </Button>
        </div>
      </div>
    </>
  );

  return (
    (isLargeScreen) ? (
      <FixedWidthModal
        open={open}
        handleClose={() => dispatch(setPrintModalClose())}
        withClosebutton={false}
        abTestId="printModal"
        modalImage={`${process.env.REACT_APP_S3_ASSETS_URL}/general/print-modal-image.jpeg`}
      >
        {renderContent()}
      </FixedWidthModal>
    ) : (
      <FullscreenModal
        className="p-4"
        open={open}
        handleClose={() => dispatch(setPrintModalClose())}
        withClosebutton={false}
        abTestId="printModal"
        modalImage={`${process.env.REACT_APP_S3_ASSETS_URL}/general/print-modal-image.jpeg`}
      >
        {renderContent()}
      </FullscreenModal>
    )
  );
};

PrintModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default PrintModal;
