import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetPhotobookQuery } from '../../api/photobook';
import { BackIcon, DeleteIcon } from '../../assets/images';
import useIsImageOwner from '../../hooks/useIsImageOwner';
import { setDeletePhotoModalClose, setDeletePhotoModalOpen } from '../../slices/modal';
import DeletePhotoModal from './DeletePhotoModal';
import DownloadPhotoButton from './DownloadPhotoButton';
import getPhotoUuidFromUrl from './getPhotoUuidFromUrl';

const TopNavigation = ({ downloadData }) => {
  const { photobookUrlIdentifier } = useParams();
  const location = useLocation();
  const photoUuid = getPhotoUuidFromUrl();
  const guestToken = useSelector((state) => state.auth.guest.token);
  const userUuid = useSelector((state) => state.auth.user.uuid);
  const isAdmin = useSelector((state) => state.photobook.isAdmin);
  const deletePhotoModalOpen = useSelector((state) => state.modal.deletePhotoModalOpen);
  const dispatch = useDispatch();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const [nextPhotoUuid, setNextPhotoUuid] = useState('');
  const {
    data,
    isSuccess
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });
  const canDelete = (useIsImageOwner(
    data?.data.photobook.images.list ?? [],
    photoUuid,
    userUuid,
    guestToken
  ) || isAdmin);

  useEffect(() => {
    if (isSuccess) {
      const deletedPhotoIndex = data.data.photobook.images.list.findIndex(
        (photo) => photo.uuid === photoUuid
      );
      setNextPhotoUuid(
        data.data.photobook.images.list[deletedPhotoIndex + 1]?.uuid
        ?? data.data.photobook.images.list[deletedPhotoIndex - 1]?.uuid
        ?? ''
      );
    }
  }, [isSuccess, data, photoUuid]);

  return (
    <div className="flex items-center border-b border-fog h-[60px] px-4 lg:px-8 gap-x-8">
      <Link data-abtestid="galleryViewBack" className="mr-auto" to={`/photobook/${photobookUrlIdentifier}#photos`} state={location.state}>
        <img src={BackIcon} alt="back icon" />
      </Link>
      {isSuccess && <DownloadPhotoButton downloadData={downloadData} />}
      {canDelete && (
      <button type="button" data-abtestid="deletePhoto" onClick={() => dispatch(setDeletePhotoModalOpen())}>
        <img className="w-5 h-5" src={DeleteIcon} alt="delete icon" />
      </button>
      )}
      {canDelete && (
      <DeletePhotoModal
        open={deletePhotoModalOpen}
        handleClose={() => dispatch(setDeletePhotoModalClose())}
        nextPhotoUuid={nextPhotoUuid}
      />
      )}
    </div>
  );
};

TopNavigation.propTypes = {
  downloadData: PropTypes.exact({
    from: PropTypes.string,
    saveAs: PropTypes.string,
  }),
};

export default TopNavigation;
