import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CharcoalInviteIcon } from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setInviteMembersModalOpen } from '../../slices/modal';

const InviteMembersButton = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setInviteMembersModalOpen());
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      data-abtestid="ellipsisMenuInviteMembersButton"
    >
      <IconPaddedText
        className="text-charcoal font-bold"
        icon={<img className="w-5 h-5" src={CharcoalInviteIcon} alt="Invite members" />}
        text={t('photo_gallery_invite_members', 'Invite Members')}
      />
    </button>
  );
};

export default InviteMembersButton;
