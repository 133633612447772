import FileSaver from 'file-saver';

const download = (url, saveAs) => fetch(url, {
  /**
   * Setting to 'no-cache' is needed to prevent the browser from reusing the previous
   * GET request (to load the slide image) which causes a CORS error
   */
  cache: 'no-cache',
})
  .then((res) => {
    if (res.ok) {
      return res.blob();
    }
    throw Error('file can not be downloaded');
  })
  .then((blob) => FileSaver.saveAs(blob, saveAs));

export default download;
