import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon, EllipsisIcon } from '../../assets/images';
import { Button } from '../../components/button';
import FloatingButton from '../../components/button/FloatingButton';
import { TOAST_GUTTER } from '../../components/ToastContainer';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useOutsideElementClick from '../../hooks/useOutsideElementClick';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { clearBottomOffset as clearToastContainerBottomOffset, setBottomOffset as setToastContainerBottomOffset } from '../../slices/toastContainerStyle';
import { noop } from '../../utils/generic';
import InviteMembersButton from './InviteMembersButton';
import LiveModeButton from './LiveModeButton';
import PhotoSelectionModeButton from './PhotoSelectionModeButton';
import PrintModeButton from './PrintModeButton';

const EllipsisMenuButton = () => {
  const [defaultOffset, setDefaultOffset] = useState(0);
  const isLargeScreen = useLargeScreenMediaQuery();
  const [toggleOnEllipsisMenu, setToggleOnEllipsisMenu] = useState(false);
  const containerRef = useRef(null);
  const mobileDropUpRef = useRef(null);
  const previousOffset = useSelector((state) => state.toastContainerStyle.bottom);
  useOutsideElementClick(containerRef, () => setToggleOnEllipsisMenu(false));
  useOutsideElementClick(mobileDropUpRef, () => setToggleOnEllipsisMenu(false));
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);

  useEffect(() => {
    if (isLargeScreen) {
      return noop;
    }

    if (toggleOnEllipsisMenu) {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const dropUpOffset = vh - mobileDropUpRef.current.getBoundingClientRect().y;

      dispatch(setToastContainerBottomOffset(dropUpOffset + TOAST_GUTTER));
    } else {
      dispatch(setToastContainerBottomOffset(defaultOffset));
    }

    return () => dispatch(clearToastContainerBottomOffset());
  }, [defaultOffset, dispatch, isLargeScreen, toggleOnEllipsisMenu]);

  const handleClick = () => {
    if (defaultOffset === 0) {
      setDefaultOffset(previousOffset);
    }
    setToggleOnEllipsisMenu((previousToggleOnEllipsisMenu) => !previousToggleOnEllipsisMenu);
  };

  return (
    <div ref={containerRef}>
      <FloatingButton
        onClick={handleClick}
        circleShaped
        abTestId="floatingEllipsisMenu"
      >
        <img className="w-5 h-5 mx-auto" src={toggleOnEllipsisMenu ? CloseIcon : EllipsisIcon} alt="Ellipsis menu" />
      </FloatingButton>
      {toggleOnEllipsisMenu && (
        isLargeScreen ? (
          <div className="p-4 bg-white absolute bottom-20 -translate-x-[36%] -z-10 w-[200px] rounded-lg shadow" data-abtestid="ellipsisMenu">
            <div className="flex flex-col text-center gap-y-4">
              <PrintModeButton />
              <PhotoSelectionModeButton />
              <LiveModeButton />
              <InviteMembersButton />
            </div>
          </div>
        ) : (
          <div className="fixed bg-black/60 inset-0 z-50">
            <div ref={mobileDropUpRef} className="absolute w-full max-h-1/2 p-4 bg-white bottom-0 left-1/2 rounded-t-lg shadow-custom translate-x-[-50%] z-50" data-abtestid="ellipsisMenuMobile">
              <div className="flex flex-col mb-8 gap-y-4">
                <PrintModeButton />
                <PhotoSelectionModeButton />
                <LiveModeButton />
                <InviteMembersButton />
              </div>
              <Button
                type="button"
                color="lightGrey"
                variant="contained"
                className="w-full"
                abTestId="ellipsisMenuMobileClose"
                onClick={() => setToggleOnEllipsisMenu(false)}
              >
                {t('photo_gallery_close', 'Close')}
              </Button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default EllipsisMenuButton;
