import { CarouselContext } from 'pure-react-carousel';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPhotoIndex } from '../../slices/liveMode';

const LiveModeSlideChangedListener = () => {
  const carouselContext = useContext(CarouselContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const onChange = () => {
      dispatch(setPhotoIndex(carouselContext.state.currentSlide));
    };
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, dispatch]);

  // renders nothing
};

export default LiveModeSlideChangedListener;
