import { throwIfErroneousResponse } from '../../utils/generic';
import { getThumbnailAndPrintFiles, getImageDimension } from '../../utils/imageUpload';

const createGalleryUploader = (
  photobookUrlIdentifier,
  isProcessingRef,
  pendingItemsRef,
  generatePresignedUrls,
  uploadPhoto,
  totalItemsRef,
  onProcessedHook
) => {
  const isProcessing = () => isProcessingRef.current;
  const getTotalItems = () => totalItemsRef.current;
  const getTotalProcessedItems = () => getTotalItems() - pendingItemsRef.current.length;

  const getPresignedUrls = async (metadata) => {
    const response = await generatePresignedUrls({
      urlIdentifier: photobookUrlIdentifier,
      set: 'photo',
      metadata,
    });

    throwIfErroneousResponse(response);

    const {
      presigned_url: {
        thumbnail: thumbnailPresignedUrl,
        print: printPresignedUrl,
      },
    } = response.data.data[0];

    return { thumbnailPresignedUrl, printPresignedUrl };
  };

  const uploadThumbnailPhoto = async (file, to) => {
    const response = await uploadPhoto({ file, to });
    throwIfErroneousResponse(response);
  };

  const uploadPrintPhoto = async (file, to) => {
    const response = await uploadPhoto({ file, to });
    throwIfErroneousResponse(response);
  };

  const processNext = async () => {
    isProcessingRef.current = true; // eslint-disable-line no-param-reassign

    try {
      const [
        thumbnailFile,
        printFile
      ] = await getThumbnailAndPrintFiles(pendingItemsRef.current[0]);

      const thumbnailDimension = await getImageDimension(thumbnailFile);
      const takenAt = thumbnailFile.lastModified;

      const metadata = {
        taken_at: takenAt,
        thumbnail_width: thumbnailDimension.w,
        thumbnail_height: thumbnailDimension.h,
      };

      const { thumbnailPresignedUrl, printPresignedUrl } = await getPresignedUrls(metadata);
      await uploadThumbnailPhoto(thumbnailFile, thumbnailPresignedUrl);
      await uploadPrintPhoto(printFile, printPresignedUrl);

      // TODO: hook
    } catch (err) {
      // TODO: hook
    } finally {
      // eslint-disable-next-line no-param-reassign
      pendingItemsRef.current = pendingItemsRef.current.slice(1);

      onProcessedHook(getTotalProcessedItems(), getTotalItems());
    }

    if (pendingItemsRef.current.length > 0) {
      await processNext();
      return;
    }

    totalItemsRef.current = 0; // eslint-disable-line no-param-reassign
    isProcessingRef.current = false; // eslint-disable-line no-param-reassign

    // TODO: hook
  };

  const pushToQueue = (items) => {
    // eslint-disable-next-line no-param-reassign
    pendingItemsRef.current = [...pendingItemsRef.current, ...items];

    totalItemsRef.current += items.length; // eslint-disable-line no-param-reassign

    if (!isProcessing()) {
      processNext();
    }
  };

  const clearQueue = () => {
    if (isProcessing()) {
      // eslint-disable-next-line no-param-reassign
      pendingItemsRef.current = [pendingItemsRef.current[0]];
    } else {
      pendingItemsRef.current = []; // eslint-disable-line no-param-reassign
    }
  };

  const galleryUploader = {
    pushToQueue,
    clearQueue,
    getTotalItems,
    getTotalProcessedItems,
    isProcessing,
  };

  return galleryUploader;
};

export default createGalleryUploader;
