import camelize from 'camelize';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUserInfoQuery } from '../api/account';
import { AccountInformationCard } from '../features/account';
import { AccountTopNavigation } from '../features/top-navigation';
import useClearAuthTokens from '../hooks/useClearAuthTokens';
import useRedirectIfNotLoggedIn from '../hooks/useRedirectIfNotLoggedIn';
import useToast from '../hooks/useToast';
import { updateUserInformation } from '../slices/auth';

const UserAccount = () => {
  useRedirectIfNotLoggedIn();

  const {
    data,
    error,
    isError,
    isSuccess
  } = useUserInfoQuery();

  const dispatch = useDispatch();
  const { whoopsToast } = useToast();
  const clearAuthTokens = useClearAuthTokens();

  useEffect(() => {
    if (isSuccess) {
      dispatch(updateUserInformation(camelize(data.data)));
      return;
    }

    if (isError) {
      if (error.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [isSuccess, isError, data, dispatch, error, whoopsToast, clearAuthTokens]);

  return (
    <div className="h-full">
      <AccountTopNavigation />
      <div className="flex flex-col bg-cloud h-full p-4 gap-y-4 lg:py-8 lg:px-72 lg:gap-y-8">
        <AccountInformationCard />
      </div>
    </div>
  );
};

export default UserAccount;
