import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRemoveRecentVisitsMutation } from '../../api/recentVisit';
import { WarningIcon } from '../../assets/images';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setDeleteRecentVisitsModalClose } from '../../slices/modal';

const DeleteRecentVisitsModal = ({ open }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.recentVisits);
  const isLargeScreen = useLargeScreenMediaQuery();
  const { infoToast, whoopsToast } = useToast();
  const clearAuthTokens = useClearAuthTokens();

  const [
    removeRecentVisits,
    {
      isLoading,
      isSuccess,
      isError,
      error
    }
  ] = useRemoveRecentVisitsMutation();

  useEffect(() => {
    if (isSuccess) {
      infoToast(
        t('delete_recent_visits_modal_success', 'Your Recent Visits are deleted'),
        'recentVisitsDeletedToast'
      );
      return;
    }

    if (isError) {
      if (error.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isError,
    isSuccess,
    error,
    t,
    infoToast,
    whoopsToast,
    clearAuthTokens,
  ]);

  const renderContent = () => (
    <div className="p-4 lg:p-0">
      <img className="mx-auto" src={WarningIcon} alt="Warning icon" />
      <div className="mt-2 text-center text-2xl font-normal leading-9">
        {t('delete_recent_visits_modal_title', 'Clear Recent Visits?')}
      </div>
      <div className="mt-2 text-center text-base font-normal leading-6">
        {t(
          'delete_recent_visits_modal_description',
          "Clearing will remove all Photobooks that haven't been added to your Library. This action cannot be reversed."
        )}
      </div>
      <div className="mt-8 flex flex-col-reverse justify-center gap-y-4 lg:flex-row">
        <Button
          className="w-100 lg:mr-4 lg:w-52"
          color="primary"
          variant="contained"
          onClick={() => dispatch(setDeleteRecentVisitsModalClose())}
          abTestId="deleteRecentVisitsCancel"
        >
          {t('delete_modal_cancel_button', 'Cancel')}
        </Button>
        <Button
          className="w-100 lg:ml-4 lg:w-52"
          color="warning"
          variant="outlined"
          disabled={isLoading}
          onClick={() => {
            removeRecentVisits();
            dispatch(setDeleteRecentVisitsModalClose());
          }}
          abTestId="deleteRecentVisitsSubmit"
        >
          {t('delete_recent_visits_modal_button', 'Clear Recent Visits')}
        </Button>
      </div>
    </div>
  );

  return isLargeScreen ? (
    <FixedWidthModal
      open={open}
      handleClose={() => dispatch(setDeleteRecentVisitsModalClose())}
      abTestId="deleteRecentVisitsModal"
    >
      {renderContent()}
    </FixedWidthModal>
  ) : (
    <FullscreenModal
      open={open}
      handleClose={() => dispatch(setDeleteRecentVisitsModalClose())}
      abTestId="deleteRecentVisitsModal"
    >
      {renderContent()}
    </FullscreenModal>
  );
};

DeleteRecentVisitsModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DeleteRecentVisitsModal;
