import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CharcoalUploadIcon } from '../../assets/images';
import FloatingButton from '../../components/button/FloatingButton';
import Dropzone from '../../components/Dropzone';
import IconPaddedText from '../../components/IconPaddedText';
import { TOAST_GUTTER } from '../../components/ToastContainer';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setGuestModalOpen } from '../../slices/modal';
import { clearBottomOffset as clearToastContainerBottomOffset, setBottomOffset as setToastContainerBottomOffset } from '../../slices/toastContainerStyle';
import { noop } from '../../utils/generic';

const AddPhotosButton = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const { isRegistered } = useUserType();
  const isLargeScreen = useLargeScreenMediaQuery();
  const dispatch = useDispatch();
  const buttonRef = useRef(null);

  useEffect(() => {
    if (isLargeScreen) {
      return noop;
    }

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    // button's y bottom offset https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    const buttonOffset = vh - buttonRef.current.getBoundingClientRect().y;

    dispatch(setToastContainerBottomOffset(buttonOffset + TOAST_GUTTER));

    return () => dispatch(clearToastContainerBottomOffset());
  }, [
    dispatch,
    isLargeScreen,
  ]);

  const renderButton = (handleClickFn) => (
    <div ref={buttonRef}>
      <FloatingButton
        className="text-charcoal"
        onClick={handleClickFn}
        abTestId="floatingAddPhotos"
      >
        <IconPaddedText
          icon={<img className="w-5 h-5" src={CharcoalUploadIcon} alt="Upload image" />}
          text={t('add_photos', 'Add Photos')}
        />
      </FloatingButton>
    </div>
  );

  if (isRegistered) {
    return (
      <Dropzone noDrag isDefaultStyle={false}>
        {renderButton(noop)}
      </Dropzone>
    );
  }

  return renderButton(() => dispatch(setGuestModalOpen()));
};

export default AddPhotosButton;
