import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateUserMutation } from '../../api/account';
import { GreyEditIcon } from '../../assets/images';
import { Button, SubmitButton } from '../../components/button';
import Card from '../../components/Card';
import IconPaddedText from '../../components/IconPaddedText';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { updateUserInformation } from '../../slices/auth';

const AccountInformationCard = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.account);
  const [editMode, setEditMode] = useState(false);
  const userFirstName = useSelector((state) => state.auth.user.firstName);
  const userLastName = useSelector((state) => state.auth.user.lastName);
  const userEmail = useSelector((state) => state.auth.user.email);
  const fullName = userFirstName.concat(' ', userLastName);
  const dispatch = useDispatch();
  const { infoToast, whoopsToast } = useToast();
  const clearAuthTokens = useClearAuthTokens();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    setFocus,
    formState: { isValidating, isSubmitting, errors },
  } = useForm();

  const [
    updateUserInfo,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useUpdateUserMutation();

  useEffect(() => {
    setValue('first_name', '');
    setValue('last_name', '');
    if (editMode) {
      if (userFirstName && userLastName) {
        setValue('first_name', userFirstName);
        setValue('last_name', userLastName);
        setFocus('first_name');
      }
    } else {
      clearErrors();
    }
  }, [editMode, clearErrors, userFirstName, userLastName, setFocus, setValue]);

  useEffect(() => {
    if (isSuccess) {
      setEditMode(false);
      infoToast(t('your_name_is_updated', 'Your name is updated.'), 'accountInfoUpdatedToast');
      return;
    }

    if (isError) {
      if (resError.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [infoToast, isError, isSuccess, resData, resError, t, whoopsToast, dispatch, clearAuthTokens]);

  const onSubmit = (data) => {
    dispatch(updateUserInformation({
      firstName: data.first_name,
      lastName: data.last_name,
      email: userEmail
    }));
    updateUserInfo({ ...data });
  };

  return (
    <Card>
      {!editMode && (
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <p className="font-bold">{t('name', 'Name')}</p>
            <p className="mt-1" data-abtestid="userFullName">{fullName}</p>
          </div>
          <div className="flex justify-end min-w-[100px]">
            <Button className="pr-0" variant="text" color="grey" type="button" onClick={() => setEditMode(true)} abTestId="editAccountInfo">
              <IconPaddedText
                icon={<img src={GreyEditIcon} alt="edit user name" />}
                text={t('edit', 'Edit')}
              />
            </Button>
          </div>
        </div>
      )}

      {editMode && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="first_name" className="font-bold">{t('name', 'Name')}</label>
          <div className="lg:grid gap-x-4 grid-cols-2">
            <div>
              <input id="first_name" {...register('first_name', { required: 'This field is required.' })} className={`w-full py-3 px-4 mt-1 border rounded-lg ${errors.first_name ? 'border-blush' : 'border-grey'}`} type="text" placeholder="Text" data-abtestid="editAccountFirstName" />
              {errors.first_name && <div className="mt-2 text-blush">{errors.first_name.message}</div>}
            </div>
            <div>
              <input id="last_name" {...register('last_name', { required: 'This field is required.' })} className={`w-full py-3 px-4 mt-1 border rounded-lg ${errors.last_name ? 'border-blush' : 'border-grey'}`} type="text" placeholder="Text" data-abtestid="editAccountLastName" />
              {errors.last_name && <div className="mt-2 text-blush">{errors.last_name.message}</div>}
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button variant="text" color="secondary" onClick={() => setEditMode(false)} abTestId="editAccountCancel">{t('cancel', 'Cancel')}</Button>
            <SubmitButton className="ml-4" variant="contained" color="primary" disabled={isLoading || isValidating || isSubmitting} abTestId="editAccountSubmit">{t('save', 'Save')}</SubmitButton>
          </div>
        </form>
      )}

      <div className="mt-8">
        <p className="font-bold">{t('email', 'Email')}</p>
        <p className="mt-1" data-abtestid="userEmail">{userEmail}</p>
      </div>
    </Card>
  );
};

export default AccountInformationCard;
