import baseApi from './base';

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (data) => ({
        url: '/user/register',
        method: 'POST',
        body: data,
      }),
    }),
    loginUser: builder.mutation({
      query: (data) => ({
        url: '/login',
        method: 'POST',
        body: data,
      }),
    }),
    sendForgotPasswordEmail: builder.mutation({
      query: (data) => ({
        url: '/forgot-password',
        method: 'POST',
        body: data,
      }),
    }),
    registerGuest: builder.mutation({
      query: (data) => ({
        url: '/guest/register',
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST'
      }),
    }),
    mergeGuest: builder.mutation({
      query: (data) => ({
        url: '/user/merge',
        method: 'PUT',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/reset-password',
        method: 'POST',
        body: data
      })
    })
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useSendForgotPasswordEmailMutation,
  useRegisterGuestMutation,
  useLogoutMutation,
  useMergeGuestMutation,
  useResetPasswordMutation
} = authApi;
