import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  PhotobookLiveLogo, ClockIcon, PlusIcon, FeedbackIcon
} from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setAuthModalOpen, setCreatePhotobookModalOpen } from '../../slices/modal';
import AccountDropdown from './AccountDropdown';

const LibraryTopNavigation = () => {
  const { t } = useTranslation([TRANS_NAMESPACES.library, TRANS_NAMESPACES.misc]);
  const { isAccountHolder } = useUserType();
  const dispatch = useDispatch();

  return (
    <div className="fixed w-full flex items-center justify-between border-b border-fog min-h-[60px] px-4 lg:px-8 bg-white z-40">
      <img className="w-36" src={PhotobookLiveLogo} alt="Photobook Live logo" />
      <div className="flex items-center gap-x-5 lg:gap-x-8">
        <div className="hover:cursor-pointer" data-abtestid="libraryTopNavigationCreatePhotobookModalButton" onClick={() => (isAccountHolder ? dispatch(setCreatePhotobookModalOpen()) : dispatch(setAuthModalOpen()))}>
          <IconPaddedText
            icon={<img className="w-5 h-5" src={PlusIcon} alt="Create Photobook" />}
            text={t('create_photobook_cta', 'Create Photobook')}
            textOnLargeScreenOnly
          />
        </div>
        {
          isAccountHolder && (
            <Link to="/recent" data-abtestid="libraryTopNavigationRecentVisits">
              <IconPaddedText
                icon={<img className="w-5 h-5" src={ClockIcon} alt="Recent Visits" />}
                text={t('recent_visits_cta', 'Recent Visits')}
                textOnLargeScreenOnly
              />
            </Link>
          )
        }
        <a href="https://forms.gle/4gPiV8oG23wUwpHM7" target="_blank" rel="noreferrer" className="text-photobookBlue" data-abtestid="shareFeedbackButton">
          <IconPaddedText
            icon={<img className="w-5 h-5" src={FeedbackIcon} alt="share feedback icon" />}
            text={t('share_feedback', 'Share Feedback', { ns: TRANS_NAMESPACES.misc })}
            textOnLargeScreenOnly
          />
        </a>
        <AccountDropdown />
      </div>
    </div>
  );
};

export default LibraryTopNavigation;
