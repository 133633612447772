import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetPhotobookQuery } from '../../api/photobook';
import { DownloadIcon } from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import useToast from '../../hooks/useToast';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { clearSelection, exitSelectionMode } from '../../slices/download';
import { setAuthModalOpen } from '../../slices/modal';
import download from '../../utils/download';
import { commaSeparateNumber } from '../../utils/generic';

const DownloadSelectedPhotosButton = ({ createZip }) => {
  const dispatch = useDispatch();
  const { isAccountHolder } = useUserType();
  const selectedPhotos = useSelector((state) => state.download.selectedPhotos);
  const { errorToast } = useToast();
  const { t } = useTranslation([TRANS_NAMESPACES.photobookHomepage, TRANS_NAMESPACES.misc]);
  const { photobookUrlIdentifier } = useParams();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });

  const handleClick = async () => {
    if (!isAccountHolder) {
      dispatch(setAuthModalOpen());
      return;
    }

    const selectedPhotoUrls = selectedPhotos.map(
      (photoUuid) => ({
        url: data.data.photobook.images.list.find((photo) => photo.uuid === photoUuid).image.print,
        uuid: photoUuid
      })
    );

    let failedCount = 0;

    if (selectedPhotoUrls.length < 2) {
      const results = await Promise.allSettled(
        selectedPhotoUrls.map((photo) => download(photo.url, photo.uuid))
      );

      results.forEach((result) => {
        if (result.status === 'rejected') {
          failedCount++;
        }
      });

      if (failedCount > 0) {
        errorToast(t('download_failed_gallery', ` ${failedCount === 1 ? 'Failed to download 1 photo. The photo may have been removed by the owner.' : `Failed to download ${commaSeparateNumber(failedCount)} photos. The photos may have been removed by the owner.`}`, { count: failedCount }, { ns: TRANS_NAMESPACES.misc }));
      }
    } else {
      createZip({ image_urls: (selectedPhotoUrls.map((photo) => photo.url)) });
    }
    dispatch(exitSelectionMode());
    dispatch(clearSelection());
  };

  return (
    <button type="button" onClick={handleClick} data-abtestid="downloadSelectedPhotosButton">
      <IconPaddedText
        icon={<img className="w-5 h-5" src={DownloadIcon} alt="download icon" />}
        text={t('download', 'Download')}
        textOnLargeScreenOnly
      />
    </button>
  );
};

DownloadSelectedPhotosButton.propTypes = {
  createZip: PropTypes.func.isRequired,
};

export default DownloadSelectedPhotosButton;
