import baseApi from './base';

const AP_BE_URL = process.env.REACT_APP_AP_BACKEND_API_URL;

const apApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getApStoreCountry: builder.query({
      query: () => ({
        url: `${AP_BE_URL}/pbww/store/country`,
        method: 'GET',
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetApStoreCountryQuery } = apApi;
