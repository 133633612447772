import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  token: '',
  uuid: '',
  email: '',
  firstName: '',
  lastName: ''
};

const initialGuestState = {
  token: '',
  name: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    foundExistingAccount: null,
    user: initialUserState,
    guest: initialGuestState
  },
  reducers: {
    setUser: (state, { payload }) => ({ ...state, user: payload }),
    updateUserInformation: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName
      }
    }),
    clearUser: (state) => ({ ...state, user: initialUserState }),
    setGuest: (state, { payload }) => ({ ...state, guest: payload }),
    clearGuest: (state) => ({ ...state, guest: initialGuestState }),
    setFoundExistingAccount: (state, { payload }) => ({ ...state, foundExistingAccount: payload }),
    clearFoundExistingAccount: (state) => ({ ...state, foundExistingAccount: null }),
  },
});

export const {
  setUser,
  updateUserInformation,
  clearUser,
  setGuest,
  clearGuest,
  setFoundExistingAccount,
  clearFoundExistingAccount,
} = authSlice.actions;

export default authSlice;
