import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlueTickIcon,
  UpArrowIcon,
  DownArrowIcon,
  SortIcon
} from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import useOutsideElementClick from '../../hooks/useOutsideElementClick';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setSortOption } from '../../slices/photobook';

const SortOptions = () => {
  const [showSortMenu, setShowSortMenu] = useState(false);
  const currentSortOption = useSelector((state) => state.photobook.sortOption);
  const elementRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  useOutsideElementClick(elementRef, () => setShowSortMenu(false));

  const sortImages = (sortOption) => {
    if (currentSortOption !== sortOption) {
      dispatch(setSortOption(sortOption));
    }
    setShowSortMenu(false);
  };

  const sortOptionAsc = t('photo_gallery_time_taken_ascending', 'Time Taken (Ascending)');
  const sortOptionDesc = t('photo_gallery_time_taken_descending', 'Time Taken (Descending)');
  const isSortAsc = currentSortOption === 'asc';

  return (
    <div className="flex items-center ml-auto">
      <img className="my-3 mr-4" src={SortIcon} alt="sort icon" />
      <div className="my-3 mr-4">{t('photo_gallery_sort_by', 'Sort by')}</div>
      <div ref={elementRef} className="grow lg:grow-0">
        <div className={`flex items-center justify-between rounded-[40px] bg-lightGrey hover:cursor-pointer ${showSortMenu ? '' : 'border-fog'} lg:w-[250px]`} onClick={() => setShowSortMenu(!showSortMenu)} data-abtestid="sortOptions">
          <span className="flex py-3 ml-4">{isSortAsc ? sortOptionAsc : sortOptionDesc}</span>
          <img className="mx-4" src={showSortMenu ? UpArrowIcon : DownArrowIcon} alt="sort Option arrow" />
        </div>
        {showSortMenu && (
          <div className="absolute flex flex-col left-2 right-2 lg:left-auto lg:right-16 lg:bg-white rounded-lg shadow-custom z-10 lg:w-80">
            <div className={`flex m-4 hover:cursor-pointer ${isSortAsc ? 'text-photobookBlue' : ''}`} onClick={() => sortImages('asc')} data-abtestid="sortOptionsAsc">
              <IconPaddedText
                className={`${!isSortAsc ? '' : 'font-bold'}`}
                icon={<img className={`${isSortAsc ? '' : 'invisible'}`} src={BlueTickIcon} alt="sort asc selected" />}
                text={sortOptionAsc}
              />
            </div>
            <div className={`flex m-4 hover:cursor-pointer ${!isSortAsc ? 'text-photobookBlue' : ''}`} onClick={() => sortImages('desc')} data-abtestid="sortOptionsDesc">
              <IconPaddedText
                className={`${isSortAsc ? '' : 'font-bold'}`}
                icon={<img className={`${!isSortAsc ? '' : 'invisible'}`} src={BlueTickIcon} alt="sort desc selected" />}
                text={sortOptionDesc}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SortOptions;
