import PropTypes from 'prop-types';
import React from 'react';
import Button from './Button';

const SubmitButton = ({
  children,
  className = '',
  disabled = false,
  color = 'primary',
  variant = 'contained',
  abTestId = '',
}) => (
  <Button
    className={className}
    type="submit"
    disabled={disabled}
    color={color}
    variant={variant}
    abTestId={abTestId}
  >
    {children}
  </Button>
);

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'warning']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  abTestId: PropTypes.string,
};

export default SubmitButton;
