import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { PhotobookLiveLogo } from '../../assets/images';
import FormGenericErrorBanner from '../../components/FormGenericErrorBanner';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import { TabNavs, TabPanes } from '../../components/tab';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { clearFoundExistingAccount } from '../../slices/auth';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

const AuthModal = ({ open, handleClose, targetTab }) => {
  const [description, setDescription] = useState('');
  const [genericError, setGenericError] = useState(null);
  const [activeTab, setActiveTab] = useState('register');
  const foundExistingAccount = useSelector((state) => state.auth.foundExistingAccount);
  const guestName = useSelector((state) => state.auth.guest.name);
  const registerRedirect = useSelector((state) => state.modal.registerRoute);
  const loginRedirect = useSelector((state) => state.modal.loginRoute);
  const { t } = useTranslation(TRANS_NAMESPACES.auth);
  const dispatch = useDispatch();
  const isLargeScreen = useLargeScreenMediaQuery();

  const tabNavs = [
    {
      id: 'register',
      text: t('auth_modal_tab_label_signup', 'Sign up'),
    },
    {
      id: 'login',
      text: t('auth_modal_tab_label_login', 'Log in'),
    },
  ];

  const tabPanes = [
    {
      id: 'register',
      content:
  <RegisterForm
    redirectTo={registerRedirect}
    defaultValues={{ first_name: guestName }}
    setGenericError={setGenericError}
  />,
    },
    {
      id: 'login',
      content:
  <LoginForm
    redirectTo={loginRedirect}
    defaultValues={{ email: foundExistingAccount }}
    setGenericError={setGenericError}
  />,
    },
  ];

  const renderContent = () => (
    <div className="p-4 lg:p-0">
      <img
        className="mx-auto h-12 w-48"
        src={PhotobookLiveLogo}
        alt="Photobook Live logo"
      />
      <TabNavs
        items={tabNavs}
        className="flex shrink flex-row justify-center gap-x-8 text-center"
        itemClassName="pt-3 pb-2 border-b-2 border-transparent font-bold capitalize"
        activeItemClassName="!border-photobookBlue !text-photobookBlue"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="flex flex-col gap-y-4 mt-4">
        {genericError && (
        <FormGenericErrorBanner>{genericError}</FormGenericErrorBanner>
        )}
        <div className="text-center">{description}</div>
        <TabPanes items={tabPanes} activeTab={activeTab} />
      </div>
    </div>
  );

  useEffect(() => {
    if (open) {
      setActiveTab(targetTab || 'register');
    }
  }, [open, targetTab]);

  useEffect(() => {
    if (activeTab === 'register') {
      setDescription(t('auth_modal_description_register', 'Proceed to log in if you have a Photobook account.'));
      setGenericError(null);
      dispatch(clearFoundExistingAccount());
    } else {
      setDescription(t('auth_modal_description_login', 'Welcome back!'));
    }
  }, [activeTab, dispatch, t]);

  useEffect(() => {
    if (foundExistingAccount) {
      setActiveTab('login');
    }
  }, [foundExistingAccount]);

  return isLargeScreen ? (
    <FixedWidthModal
      open={open}
      handleClose={handleClose}
      abTestId="authModal"
    >
      {renderContent()}
    </FixedWidthModal>
  ) : (
    <FullscreenModal open={open} handleClose={handleClose} abTestId="authModal">
      {renderContent()}
    </FullscreenModal>
  );
};

AuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  targetTab: PropTypes.string,
};

export default AuthModal;
