import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrintIcon } from '../../assets/images';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { noop } from '../../utils/generic';

const PrintLoadingModal = ({ open }) => {
  const isLargeScreen = useLargeScreenMediaQuery();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);

  const renderContent = () => (
    <div className="flex flex-col gap-y-2 items-center text-center">
      <img className="mx-auto w-12 h-12" src={PrintIcon} alt="print icon" />
      <div className="text-2xl">
        {t('print_loading_modal_header', 'Preparing photos for printing...')}
      </div>
      <div>
        {t('print_loading_modal_description', 'Please do not close or refresh this page.')}
      </div>
    </div>
  );

  return (
    (isLargeScreen) ? (
      <FixedWidthModal
        className="w-full"
        open={open}
        handleClose={noop}
        withClosebutton={false}
        abTestId="printLoadingModal"
      >
        {renderContent()}
      </FixedWidthModal>
    ) : (
      <FixedWidthModal
        className="mx-4 w-full"
        open={open}
        handleClose={noop}
        widthClass=""
        withClosebutton={false}
        abTestId="printLoadingModal"
      >
        {renderContent()}
      </FixedWidthModal>
    )
  );
};

PrintLoadingModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default PrintLoadingModal;
