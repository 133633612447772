import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearGuest, clearUser } from '../slices/auth';
import useToast from './useToast';

const useClearAuthTokens = () => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.user.token);
  const guestToken = useSelector((state) => state.auth.guest.token);
  const navigate = useNavigate();
  const { whoopsToast } = useToast();

  const clearAuthTokens = useCallback(() => {
    if (userToken) {
      dispatch(clearUser());
    } else if (guestToken) {
      dispatch(clearGuest());
    }
    whoopsToast();
    navigate('/');
  }, [
    userToken,
    guestToken,
    dispatch,
    navigate,
    whoopsToast,
  ]);

  return clearAuthTokens;
};

export default useClearAuthTokens;
