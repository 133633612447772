import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '../../assets/images';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import DeletePhotobookForm from './DeletePhotobookForm';

const DeletePhotobookModal = ({ open, handleClose }) => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookDetails);
  const isLargeScreen = useLargeScreenMediaQuery();

  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
    }
  }, [open]);

  const renderContent = () => (
    <div className="m-4 lg:m-0">
      <img className="mx-auto" src={WarningIcon} alt="Warning icon" />
      <div className="mt-2 text-center text-2xl font-normal leading-9">
        {t('delete_photobook_modal_header', 'Delete Photobook?')}
      </div>
      <div className="mt-2 text-center text-base font-normal leading-6">
        {t(
          'delete_photobook_modal_description',
          'This Photobook and all photos will be permanently deleted. You and all members will not be able to access this Photobook. This action cannot be reversed.'
        )}
      </div>
      <div className="mt-8 flex flex-col-reverse justify-center gap-y-4 lg:flex-row">
        <DeletePhotobookForm handleClose={handleClose} />
      </div>
    </div>
  );

  return isLargeScreen ? (
    <FixedWidthModal
      open={open}
      handleClose={handleClose}
      abTestId="deletePhotobookModal"
    >
      {renderContent()}
    </FixedWidthModal>
  ) : (
    <FullscreenModal
      open={open}
      handleClose={handleClose}
      abTestId="deletePhotobookModal"
    >
      {renderContent()}
    </FullscreenModal>
  );
};

DeletePhotobookModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DeletePhotobookModal;
