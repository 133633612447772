import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable react/button-has-type */
const FloatingButton = ({
  children,
  className = '',
  onClick,
  type,
  circleShaped = false,
  disabled = false,
  abTestId = '',
}) => (
  <button
    className={`${className} ${circleShaped ? 'w-14 h-14 rounded-full' : 'p-4 rounded-[56px]'} shadow-custom font-bold bg-white`}
    onClick={onClick}
    type={type}
    disabled={disabled}
    data-abtestid={abTestId}
  >
    {children}
  </button>
);
/* eslint-enable react/button-has-type */

FloatingButton.defaultProps = {
  type: 'button',
};

FloatingButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit']),
  circleShaped: PropTypes.bool,
  disabled: PropTypes.bool,
  abTestId: PropTypes.string,
};

export default FloatingButton;
