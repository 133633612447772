import camelize from 'camelize';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRegisterUserMutation } from '../../api/auth';
import { SubmitButton } from '../../components/button';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setFoundExistingAccount, setUser } from '../../slices/auth';
import { setAuthModalClose, setPrivacypolicyModalOpen, setTermofuseModalOpen } from '../../slices/modal';
import { simulateHttpRedirect } from '../../utils/generic';

const RegisterForm = ({ defaultValues = {}, redirectTo, setGenericError }) => {
  const { t } = useTranslation([TRANS_NAMESPACES.auth, TRANS_NAMESPACES.validation]);
  const { whoopsToast } = useToast();

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { isValidating, isSubmitting, errors },
  } = useForm({ defaultValues });

  const [
    registerUser,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useRegisterUserMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      const {
        user,
        token
      } = camelize(resData.data);

      dispatch(setUser({ token, ...user }));
      simulateHttpRedirect(redirectTo);
      return;
    }

    if (isError) {
      if (resError.status === 409 && resError.data.error_code === 10004) {
        setGenericError(resError.data.errors.messages[0]);
        dispatch(setFoundExistingAccount(getValues('email')));
        return;
      }

      if (resError.status === 422) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [field, [error]] of Object.entries(resError.data.errors)) {
          setError(field, { message: error });
        }

        return;
      }

      whoopsToast();
    }
  }, [
    isSuccess,
    isError,
    resData,
    resError,
    dispatch,
    setError,
    getValues,
    setGenericError,
    redirectTo,
    whoopsToast,
  ]);

  const termModalHandleClick = () => {
    dispatch(setAuthModalClose());
    dispatch(setTermofuseModalOpen());
  };

  const privacyModalHandleClick = () => {
    dispatch(setAuthModalClose());
    dispatch(setPrivacypolicyModalOpen());
  };

  return (
    <form className="-mt-4" onSubmit={handleSubmit((data) => registerUser(data))}>
      <div className="lg:grid gap-x-4 grid-cols-2">
        <div>
          <input {...register('first_name', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })} className={`w-full py-3 px-4 mt-4 border rounded-lg ${errors.first_name ? 'border-blush' : 'border-grey'}`} type="text" placeholder={t('register_form_placeholder_first_name', 'First Name')} data-abtestid="registerFirstName" />
          {errors.first_name && <div className="mt-2 text-blush">{errors.first_name.message}</div>}
        </div>

        <div>
          <input {...register('last_name', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })} className={`w-full py-3 px-4 mt-4 border rounded-lg ${errors.last_name ? 'border-blush' : 'border-grey'}`} type="text" placeholder={t('register_form_placeholder_last_name', 'Last Name')} data-abtestid="registerLastName" />
          {errors.last_name && <div className="mt-2 text-blush">{errors.last_name.message}</div>}
        </div>
      </div>

      <input {...register('email', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })} className={`w-full py-3 px-4 mt-4 border rounded-lg ${errors.email ? 'border-blush' : 'border-grey'}`} type="email" placeholder={t('register_form_placeholder_email', 'Email')} autoComplete="email" data-abtestid="registerEmail" />
      {errors.email && <div className="mt-2 text-blush">{errors.email.message}</div>}

      <input {...register('password', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })} className={`w-full py-3 px-4 mt-4 border rounded-lg ${errors.password ? 'border-blush' : 'border-grey'}`} type="password" placeholder={t('register_form_placeholder_password', 'Password')} autoComplete="new-password" data-abtestid="registerPassword" />
      {errors.password && <div className="mt-2 text-blush">{errors.password.message}</div>}

      <SubmitButton
        color="primary"
        variant="contained"
        className="w-full mt-4"
        disabled={isLoading || isValidating || isSubmitting}
        abTestId="registerSubmit"
      >
        {t('register_form_button_text_continue', 'Continue')}
      </SubmitButton>

      <div className="mt-4 text-center">
        <Trans ns={TRANS_NAMESPACES.auth} i18nKey="auth_tnc_reminder">
          By continuing, you agree to Photobook&apos;s
          {' '}
          <span className="cursor-pointer font-bold text-photobookBlue" onClick={termModalHandleClick} data-abtestid="registerTermsOfUseLink">Terms of Use</span>
          {' '}
          and
          {' '}
          <span className="cursor-pointer font-bold text-photobookBlue" onClick={privacyModalHandleClick} data-abtestid="registerPrivacyPolicyLink">Privacy Policy</span>
          .
        </Trans>
      </div>
    </form>
  );
};

RegisterForm.propTypes = {
  defaultValues: PropTypes.objectOf(PropTypes.string),
  redirectTo: PropTypes.string.isRequired,
  setGenericError: PropTypes.func.isRequired,
};

export default RegisterForm;
