import baseApi from './base';

const recentVisitApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecentVisits: builder.query({
      query: () => ({
        url: 'visit',
        method: 'GET'
      }),
      providesTags: ['RecentVisit'],
    }),
    updateRecentVisit: builder.mutation({
      query: (data) => ({
        url: '/visit/create-or-update',
        method: 'POST',
        body: data
      })
    }),
    removeRecentVisits: builder.mutation({
      query: () => ({
        url: '/visit',
        method: 'DELETE',
      }),
      invalidatesTags: ['RecentVisit'],
    }),
    removeRecentVisit: builder.mutation({
      query: (data) => ({
        url: '/visit',
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: ['RecentVisit'],
    }),
  })
});

export const {
  useGetRecentVisitsQuery,
  useUpdateRecentVisitMutation,
  useRemoveRecentVisitsMutation,
  useRemoveRecentVisitMutation,
} = recentVisitApi;
