import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSendForgotPasswordEmailMutation } from '../../api/auth';
import { Button, SubmitButton } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import FullscreenModal from '../../components/modal/FullscreenModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import {
  setSendForgotPasswordEmailModalClose,
  setSentForgotPasswordEmailModalOpen
} from '../../slices/modal';

const SendForgotPasswordEmailModal = ({
  handleClose,
  open,
  setForgotPasswordEmail
}) => {
  const { t } = useTranslation([TRANS_NAMESPACES.auth, TRANS_NAMESPACES.validation]);
  const dispatch = useDispatch();
  const { whoopsToast } = useToast();
  const isLargeScreen = useLargeScreenMediaQuery();

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { isValidating, isSubmitting, errors },
  } = useForm();

  const [
    sendForgotPasswordEmail,
    {
      isLoading,
      isSuccess,
      isError,
      data: resData,
      error: resError
    }
  ] = useSendForgotPasswordEmailMutation();

  const renderContent = () => (
    <div className="p-4 lg:p-0">
      <div className="text-2xl leading-9">
        {t('send_forgot_password_email_title', "Let's Reset Your Password")}
      </div>
      <div className="mt-2">
        {t(
          'send_forgot_password_email_description',
          'We will send an email to you with instructions on how to reset your password.'
        )}
      </div>
      <div className="mt-4">
        <form onSubmit={handleSubmit((data) => sendForgotPasswordEmail(data))}>
          <input
            {...register('email', {
              required: t(
                'required_field_validator',
                'This field is required.',
                { ns: TRANS_NAMESPACES.validation }
              ),
            })}
            className={`w-full rounded-lg border py-3 px-4 ${
              errors.email ? 'border-blush' : 'border-grey'
            }`}
            type="email"
            placeholder={t(
              'send_forgot_password_form_placeholder_email',
              'Email'
            )}
            autoComplete="username"
            data-abtestid="forgotPasswordEmail"
          />
          {errors.email && (
            <div className="mt-2 text-blush">{errors.email.message}</div>
          )}
          <div className="mt-8 flex flex-col justify-center gap-y-2 lg:flex-row-reverse lg:justify-start lg:gap-x-4">
            <SubmitButton
              color="primary"
              variant="contained"
              className="w-full lg:w-auto"
              disabled={isLoading || isValidating || isSubmitting}
              abTestId="sendForgotPassword"
            >
              {t('send_forgot_password_button_text', 'Send Instructions')}
            </SubmitButton>
            <Button
              variant="text"
              color="tertiary"
              onClick={handleClose}
              abTestId="editPhotobookCancel"
            >
              {t('photobook_details_name_cancel_button', 'Cancel')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );

  useEffect(() => {
    if (isSuccess) {
      setForgotPasswordEmail(getValues('email'));
      setValue('email', '');
      dispatch(setSendForgotPasswordEmailModalClose());
      dispatch(setSentForgotPasswordEmailModalOpen());
      return;
    }

    if (isError) {
      if (resError.status === 422) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [field, [error]] of Object.entries(resError.data.errors)) {
          setError(field, { message: error });
        }
        return;
      }
      whoopsToast();
    }
  }, [
    isSuccess,
    isError,
    resData,
    resError,
    dispatch,
    setError,
    whoopsToast,
    setForgotPasswordEmail,
    getValues,
    setValue,
  ]);

  return isLargeScreen ? (
    <FixedWidthModal
      open={open}
      handleClose={handleClose}
      abTestId="sendForgotPasswordEmailModal"
    >
      {renderContent()}
    </FixedWidthModal>
  ) : (
    <FullscreenModal open={open} handleClose={handleClose} abTestId="sendForgotPasswordEmailModal">
      {renderContent()}
    </FullscreenModal>
  );
};

SendForgotPasswordEmailModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setForgotPasswordEmail: PropTypes.func.isRequired
};

export default SendForgotPasswordEmailModal;
