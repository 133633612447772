import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useAddToLibraryMutation,
  useRemoveFromLibraryMutation,
} from '../../api/library';
import { useGetPhotobookQuery, useUpdatePhotobookMutation } from '../../api/photobook';
import { Button, SubmitButton } from '../../components/button';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useToast from '../../hooks/useToast';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setRegenerateLinkModalOpen } from '../../slices/modal';
import { setAddedToLibrary } from '../../slices/photobook';
import DeletePhotobookModal from './DeletePhotobookModal';
import LinkRegeneratedModal from './LinkRegeneratedModal';
import RegenerateLinkModal from './RegenerateLinkModal';

const PhotobookSettingsBody = () => {
  const { photobookUrlIdentifier } = useParams();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookSettings);
  const [editMode, setEditMode] = useState(false);
  const [deletePhotobookModalOpen, setDeletePhotobookModalOpen] = useState(false);
  const regenerateLinkModalOpen = useSelector((state) => state.modal.regenerateLinkModalOpen);
  const linkRegeneratedModalOpen = useSelector((state) => state.modal.linkRegeneratedModalOpen);
  const { infoToast, whoopsToast } = useToast();
  const { isRegistered } = useUserType();
  const addedToLibrary = useSelector((state) => state.photobook.addedToLibrary);
  const isAdmin = useSelector((state) => state.photobook.isAdmin);
  const dispatch = useDispatch();
  const clearAuthTokens = useClearAuthTokens();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data: getPhotobookData,
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    setFocus,
    formState: { isValidating, isSubmitting, errors },
  } = useForm();

  const [
    updatePhotobook,
    {
      isLoading: isUpdatePhotobookLoading,
      isSuccess: isUpdatePhotobookSuccess,
      isError: isUpdatePhotobookError,
      data: updatePhotobookResponse,
      error: updatePhotobookErrorResponse,
    },
  ] = useUpdatePhotobookMutation();

  const [
    addToLibrary,
    {
      isLoading: isAddToLibraryLoading,
      isSuccess: isAddToLibrarySuccess,
      isError: isAddToLibraryError,
      error: addToLibraryErrorResponse,
    },
  ] = useAddToLibraryMutation();

  const [
    removeFromLibrary,
    {
      isLoading: isRemoveFromLibraryLoading,
      isSuccess: isRemoveFromLibrarySuccess,
      isError: isRemoveFromLibraryError,
      error: removeFromLibraryErrorResponse,
    },
  ] = useRemoveFromLibraryMutation();

  useEffect(() => {
    if (editMode) {
      setValue('name', getPhotobookData.data.photobook.name);
      setFocus('name');
    } else {
      clearErrors();
    }
  }, [editMode, clearErrors, getPhotobookData.data.photobook.name, setFocus, setValue]);

  useEffect(() => {
    if (isUpdatePhotobookSuccess) {
      setEditMode(false);
      infoToast(
        t('photobook_settings_name_updated', 'Your Photobook name is updated.'),
        'photobookNameUpdatedToast'
      );
      return;
    }

    if (isUpdatePhotobookError) {
      if (updatePhotobookErrorResponse.status === 422) {
        setError('name', {
          message: updatePhotobookErrorResponse.data.errors.name[0],
        });
        return;
      }

      whoopsToast();
    }
  }, [
    infoToast,
    isUpdatePhotobookError,
    isUpdatePhotobookSuccess,
    updatePhotobookResponse,
    updatePhotobookErrorResponse,
    setError,
    t,
    whoopsToast,
    dispatch,
  ]);

  useEffect(() => {
    if (isAddToLibrarySuccess) {
      dispatch(setAddedToLibrary(true));
      infoToast(
        t(
          'add_to_library_success_notification',
          'This Photobook is added to your library.'
        ),
        'addToLibrarySuccessToast'
      );
      return;
    }

    if (isAddToLibraryError) {
      if (addToLibraryErrorResponse.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isAddToLibrarySuccess,
    isAddToLibraryError,
    addToLibraryErrorResponse,
    infoToast,
    whoopsToast,
    dispatch,
    t,
    clearAuthTokens,
  ]);

  useEffect(() => {
    if (isRemoveFromLibrarySuccess) {
      dispatch(setAddedToLibrary(false));
      infoToast(
        t(
          'remove_from_library_success_notification',
          'This Photobook is removed from your library.'
        ),
        'removeFromLibrarySuccessToast'
      );
      return;
    }

    if (isRemoveFromLibraryError) {
      if (removeFromLibraryErrorResponse.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isRemoveFromLibrarySuccess,
    isRemoveFromLibraryError,
    removeFromLibraryErrorResponse,
    infoToast,
    whoopsToast,
    dispatch,
    t,
    clearAuthTokens,
  ]);

  const onSubmit = (data) => updatePhotobook({ ...data, urlIdentifier: photobookUrlIdentifier });

  return (
    <div className="flex justify-center">
      <div className="flex flex-col gap-y-4 lg:gap-y-8 mx-4 my-4 lg:my-8 w-full lg:w-defaultLgContainerWidth">
        {!editMode && (
          <div className="flex flex-col gap-y-4 lg:flex-row lg:justify-between lg:items-center">
            <div>
              <div className="justify-start font-bold">
                {t('photobook_settings_name_label', 'Photobook name')}
              </div>
              <div className="mt-1 justify-start" data-abtestid="photobookName">
                {getPhotobookData.data.photobook.name}
              </div>
            </div>

            {isAdmin && (
              <div className="lg:ml-8">
                <Button
                  className="w-full lg:w-max"
                  color="primary"
                  variant="contained"
                  onClick={() => setEditMode(true)}
                  abTestId="editPhotobook"
                >
                  {t('photobook_settings_name_edit_button', 'Edit')}
                </Button>
              </div>
            )}
          </div>
        )}

        {editMode && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="Photobook Name" className="font-bold">
              {t('photobook_settings_name_label', 'Photobook name')}
            </label>
            <input
              id="name"
              {...register('name', { required: 'This field is required.' })}
              className={`mt-1 w-full rounded-lg border py-3 px-4 ${
                errors.name ? 'border-blush' : 'border-grey'
              }`}
              type="text"
              placeholder={t('photobook_settings_name_label', 'Photobook name')}
              data-abtestid="editPhotobookName"
            />
            {errors.name && (
              <div className="mt-2 text-blush">{errors.name.message}</div>
            )}

            <div className="mt-4 flex flex-col justify-center gap-y-2 lg:flex-row-reverse lg:justify-start">
              <SubmitButton
                className="lg:ml-4"
                variant="contained"
                color="primary"
                disabled={
                  isUpdatePhotobookLoading || isValidating || isSubmitting
                }
                abTestId="editPhotobookSubmit"
              >
                {t('photobook_settings_name_save_button', 'Save')}
              </SubmitButton>
              <Button
                variant="text"
                color="tertiary"
                onClick={() => setEditMode(false)}
                abTestId="editPhotobookCancel"
              >
                {t('photobook_settings_name_cancel_button', 'Cancel')}
              </Button>
            </div>
          </form>
        )}

        <hr className="border-fog" />

        {isAdmin && (
          <div className="flex flex-col gap-y-4 lg:flex-row lg:justify-between lg:items-center">
            <div>
              <div className="font-bold">
                {t('photobook_settings_regenerate_invite_link', 'Regenerate invite link')}
              </div>
              <p className="mt-1">
                {t(
                  'photobook_settings_regenerate_invite_link_description',
                  'This will secure your photobook from unwanted parties. Everyone except the owner of this photobook will lose access to this photobook. Share the newly generated invite link with your friends and family that you wish to have access to this photobook.'
                )}
              </p>
            </div>
            <div className="lg:ml-8">
              <Button
                className="w-full lg:w-max"
                variant="outlined"
                color="warning"
                type="button"
                onClick={() => dispatch(setRegenerateLinkModalOpen())}
                abTestId="regenerateLink"
              >
                {t(
                  'photobook_settings_regenerate_invite_link_button',
                  'Regenerate Link'
                )}
              </Button>
            </div>
          </div>
        )}

        {isAdmin ? (
          <div className="flex flex-col gap-y-4 lg:flex-row lg:justify-between lg:items-center">
            <div>
              <div className="font-bold">
                {t('photobook_settings_delete_photobook', 'Delete Photobook')}
              </div>
              <p className="mt-1">
                {t(
                  'photobook_settings_delete_photobook_description',
                  'This Photobook and all photos will be permanently deleted. You and all members will not be able to access this Photobook. This action cannot be reversed.'
                )}
              </p>
            </div>
            <div className="lg:ml-8">
              <Button
                className="w-full lg:w-max"
                variant="outlined"
                color="warning"
                type="button"
                onClick={() => setDeletePhotobookModalOpen(true)}
                abTestId="deletePhotobook"
              >
                {t(
                  'photobook_settings_delete_photobook_button',
                  'Delete Photobook'
                )}
              </Button>
            </div>
          </div>
        ) : (
          isRegistered && (
            <div className="flex flex-col gap-y-4 lg:flex-row lg:justify-between lg:items-center">
              <div>
                <div className="justify-start font-bold">
                  {addedToLibrary
                    ? t(
                      'photobook_settings_remove_from_library',
                      'Remove from Library'
                    )
                    : t('photobook_settings_add_to_library', 'Add to Library')}
                </div>
                <div className="mt-1 justify-start">
                  {addedToLibrary
                    ? t(
                      'photobook_settings_remove_from_library_description',
                      'This Photobook will be removed from your library.'
                    )
                    : t(
                      'photobook_settings_add_to_library_description',
                      'This Photobook will be added to your library. You can browse this Photobook any time from your library.'
                    )}
                </div>
              </div>
              <div className="lg:ml-8">
                {addedToLibrary ? (
                  <Button
                    className="w-full lg:w-max"
                    variant="outlined"
                    color="warning"
                    type="button"
                    disabled={isRemoveFromLibraryLoading}
                    onClick={() => removeFromLibrary({ url_identifier: photobookUrlIdentifier })}
                    abTestId="removeFromLibrary"
                  >
                    {t(
                      'photobook_settings_remove_from_library_button',
                      'Remove from Library'
                    )}
                  </Button>
                ) : (
                  <Button
                    className="w-full lg:w-max"
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={isAddToLibraryLoading}
                    onClick={() => addToLibrary({ url_identifier: photobookUrlIdentifier })}
                    abTestId="addToLibrary"
                  >
                    {t(
                      'photobook_settings_add_to_library_button',
                      'Add to Library'
                    )}
                  </Button>
                )}
              </div>
            </div>
          )
        )}
        <DeletePhotobookModal
          open={deletePhotobookModalOpen}
          handleClose={() => setDeletePhotobookModalOpen(false)}
        />
        <RegenerateLinkModal
          open={regenerateLinkModalOpen}
        />
        <LinkRegeneratedModal
          open={linkRegeneratedModalOpen}
        />
      </div>
    </div>
  );
};

export default PhotobookSettingsBody;
