import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackIcon } from '../../assets/images';
import { TRANS_NAMESPACES } from '../../services/i18next';

const PhotobookSettingsTopNavigation = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookSettings);
  const location = useLocation();
  const { photobookUrlIdentifier } = useParams();

  return (
    <div className="flex min-h-[60px] items-center justify-between border-b border-fog px-4 lg:px-8">
      <div className="flex items-center gap-x-4 lg:gap-x-8">
        <Link
          data-abtestid="photobookSettingsBack"
          to={`/photobook/${photobookUrlIdentifier}`}
          state={location.state}
        >
          <img className="h-6 w-6" src={BackIcon} alt="back" />
        </Link>
        <p data-abtestid="photobookSettingsTitle">
          {t('photobook_settings_title', 'Settings')}
        </p>
      </div>
    </div>
  );
};

export default PhotobookSettingsTopNavigation;
