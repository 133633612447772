import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setMergeModalOpen } from '../slices/modal';
import useUserType from './useUserType';

const useMergeAccountConfirmationModal = (isLoading) => {
  const { isAccountHolder, isGuest } = useUserType();
  const isAccountHolderRef = useRef(isAccountHolder);
  const isGuestRef = useRef(isGuest);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && isAccountHolderRef.current && isGuestRef.current) {
      dispatch(setMergeModalOpen());
    }
  }, [isLoading, dispatch]);
};

export default useMergeAccountConfirmationModal;
