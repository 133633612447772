const getPhotoUuidFromUrl = () => {
  const { pathname } = window.location;
  const splitPathname = pathname.split('/');

  if (splitPathname[splitPathname.length - 2] === 'photo') {
    return splitPathname[splitPathname.length - 1];
  }

  return '';
};

export default getPhotoUuidFromUrl;
