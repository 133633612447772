import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CharcoalUploadIcon } from '../../assets/images';
import Dropzone from '../../components/Dropzone';
import IconPaddedText from '../../components/IconPaddedText';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setGuestModalOpen } from '../../slices/modal';

const AddPhotos = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const { isRegistered } = useUserType();
  const dispatch = useDispatch();

  const addPhotosCTA = () => (
    <IconPaddedText
      icon={<img className="w-5 h-5" src={CharcoalUploadIcon} alt="Add photos" />}
      text={t('top_navigation_bar_add_photos', 'Add Photos')}
      textOnLargeScreenOnly
    />
  );

  return (
    (!isRegistered) ? (
      <button type="button" onClick={() => dispatch(setGuestModalOpen())} data-abtestid="topNavAddPhotos">
        {addPhotosCTA()}
      </button>
    ) : (
      <Dropzone noDrag isDefaultStyle={false}>
        <button type="button" className="h-full">
          {addPhotosCTA()}
        </button>
      </Dropzone>
    )
  );
};

export default AddPhotos;
