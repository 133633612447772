import PropTypes from 'prop-types';
import React from 'react';

const Pane = ({ children, className }) => (
  <div className={className}>{children}</div>
);

Pane.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
};

export default Pane;
