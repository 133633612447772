import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PhotobookIcon } from '../../assets/images';
import { Button } from '../../components/button';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setAuthModalOpen, setCreatePhotobookModalOpen } from '../../slices/modal';

const EmptyLibrary = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.library);
  const { isAccountHolder } = useUserType();
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col justify-center items-center h-full gap-y-8 px-4 lg:px-auto">
      <div className="flex flex-col gap-y-4 items-center">
        <img className="w-8 h-8" src={PhotobookIcon} alt="photobook icon" />
        <p>{t('empty_library', 'There aren\'t any Photobooks in your library yet.')}</p>
      </div>
      <Button
        type="button"
        className="w-full lg:w-auto"
        color="primary"
        variant="contained"
        onClick={() => (
          isAccountHolder
            ? dispatch(setCreatePhotobookModalOpen())
            : dispatch(setAuthModalOpen())
        )}
        abTestId="emptyLibraryCreatePhotobookModalButton"
      >
        {t('create_photobook_cta', 'Create Photobook')}
      </Button>
    </div>
  );
};

export default EmptyLibrary;
