import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANS_NAMESPACES } from '../services/i18next';

const usePromptWhenUploading = (galleryUploader) => {
  const { t } = useTranslation(TRANS_NAMESPACES.misc);
  const promptMessage = t(
    'uploading_photos_interrupt_prompt',
    'Are you sure you want to continue?\nOnly photos that are successfully uploaded to this Photobook will be saved.'
  );

  const promptWhenExitPage = useCallback((e) => {
    if (galleryUploader.isProcessing()) {
      e.preventDefault();
      e.returnValue = '';
    }
  }, [galleryUploader]);

  const promptWhenUploadingPhoto = (fn, params) => (e) => {
    const processing = galleryUploader && galleryUploader.isProcessing();

    // eslint-disable-next-line no-alert
    if (!processing || (processing && window.confirm(promptMessage))) {
      if (fn !== undefined) {
        if (params === undefined) {
          fn(e);
        } else {
          fn(...params);
        }
      }
      return;
    }
    e.preventDefault();
  };

  return {
    promptWhenExitPage,
    promptWhenUploadingPhoto
  };
};

export default usePromptWhenUploading;
