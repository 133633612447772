import React, {
  useEffect, useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPhotobookQuery } from '../api/photobook';
import { useUpdateRecentVisitMutation } from '../api/recentVisit';
import Loader from '../components/Loader';
import { useGalleryUploader, GalleryUploaderContext } from '../features/gallery-upload';
import { PhotoPane } from '../features/homepage';
import PhotobookNotFound from '../features/photobook-not-found';
import { PrintModal } from '../features/print';
import { InviteMembersModal } from '../features/share-photobook';
import { HomepageTopNavigation } from '../features/top-navigation';
import useClearLiveModeData from '../hooks/useClearLiveModeData';
import useLargeScreenMediaQuery from '../hooks/useLargeScreenMediaQuery';
import useMergeAccountConfirmationModal from '../hooks/useMergeAccountConfirmationModal';
import usePromptWhenUploading from '../hooks/usePromptWhenUploading';
import useToast from '../hooks/useToast';
import useUserType from '../hooks/useUserType';
import { setAddPhotosModalClose, setAddPhotosModalOpen, setInviteMembersModalClose } from '../slices/modal';
import { setIsAdmin } from '../slices/photobook';
import decodeJWT from '../utils/jwt';

const getUserUuid = (token) => {
  if (token) {
    return decodeJWT(token).sub;
  }
  return '';
};

const PhotobookHomepage = () => {
  const navigate = useNavigate();
  const { photobookUrlIdentifier } = useParams();
  const token = useSelector((state) => state.auth.user.token);
  const inviteMembersModalOpen = useSelector((state) => state.modal.inviteMembersModalOpen);
  const printModalOpen = useSelector((state) => state.modal.printModalOpen);
  const {
    newPhotoToast,
    whoopsToast,
    removeToast,
    toastIds
  } = useToast();
  const dispatch = useDispatch();
  const { galleryUploader, setFlagToHideUploadProgress } = useGalleryUploader();
  const [photobookNotFound, setPhotobookNotFound] = useState(false);
  const { isAccountHolder, isRegistered } = useUserType();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data,
    error,
    isError,
    isLoading,
    isSuccess
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption }, {
    pollingInterval: photobookNotFound ? 0 : 5000,
  });
  const [latestCreatedAt, setLatestCreatedAt] = useState(null);
  const addPhotosModalOpen = useSelector((state) => state.modal.addPhotosModalOpen);
  const isLargeScreen = useLargeScreenMediaQuery();
  const addPhotosModalTimeout = useRef(null);
  const HIDE_ADD_PHOTOS_INTERVAL = 500;

  const showAddPhotoModal = () => {
    if (isLargeScreen && isRegistered && !addPhotosModalOpen) {
      dispatch(setAddPhotosModalOpen());
    }
  };

  const hideAddPhotoModalAfterDelay = () => {
    clearTimeout(addPhotosModalTimeout.current);
    addPhotosModalTimeout.current = setTimeout(() => {
      clearTimeout(addPhotosModalTimeout.current);
      dispatch(setAddPhotosModalClose());
    }, HIDE_ADD_PHOTOS_INTERVAL);
  };

  const hideAddPhotoModalOnDragLeave = (e) => {
    if (addPhotosModalOpen
      && (e.clientX <= 0
        || e.clientY <= 0
        || e.clientX >= window.innerWidth
        || e.clientY >= window.innerHeight)
    ) {
      clearTimeout(addPhotosModalTimeout.current);
      dispatch(setAddPhotosModalClose());
    }
  };

  const hideAddPhotoModalOnDragStop = (e) => {
    e.preventDefault();
    if (addPhotosModalOpen) {
      clearTimeout(addPhotosModalTimeout.current);
      dispatch(setAddPhotosModalClose());
    }
  };

  const [
    updateRecentVisit
  ] = useUpdateRecentVisitMutation();

  useEffect(() => {
    if (isAccountHolder) {
      updateRecentVisit({ photobook_url_identifier: photobookUrlIdentifier });
    }
  }, [isAccountHolder, photobookUrlIdentifier, updateRecentVisit]);

  const { promptWhenExitPage } = usePromptWhenUploading(galleryUploader);

  useEffect(() => {
    window.addEventListener('beforeunload', promptWhenExitPage);

    return () => {
      window.removeEventListener('beforeunload', promptWhenExitPage);
    };
  }, [promptWhenExitPage]);

  useMergeAccountConfirmationModal(isLoading);

  useClearLiveModeData();

  useEffect(() => () => {
    setFlagToHideUploadProgress();
    galleryUploader.clearQueue();
    removeToast(toastIds.uploadProgress);
    removeToast(toastIds.newPhoto);
  }, [
    galleryUploader,
    removeToast,
    setFlagToHideUploadProgress,
    toastIds
  ]);

  useEffect(
    () => {
      if (isSuccess) {
        const isAdmin = data.data.photobook.admin_uuid === getUserUuid(token);
        dispatch(setIsAdmin(isAdmin));

        let firstNewImageUuid;
        if (latestCreatedAt !== null) {
          // subsequent renders
          firstNewImageUuid = data.data.photobook.images.list.find(
            (photo) => photo.created_at > latestCreatedAt
          )?.uuid;
        } else {
          // first load, search for latest created at
          const newLatestCreatedAt = data.data.photobook.images.list.reduce(
            (acc, crr) => (acc < crr.created_at ? crr.created_at : acc),
            0
          );
          setLatestCreatedAt(newLatestCreatedAt);
        }

        if (!galleryUploader.isProcessing() && firstNewImageUuid) {
          newPhotoToast(`?new_photo_uuid=${firstNewImageUuid}#photos`);
        }
        return;
      }

      if (isError) {
        if ([404, 422].includes(error.status)) {
          setPhotobookNotFound(true);
          return;
        }
        whoopsToast();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSuccess,
      isError,
      error,
      data,
      token,
      newPhotoToast,
      whoopsToast,
      galleryUploader,
      navigate,
      setPhotobookNotFound,
    ]
  );

  const renderContent = () => {
    if (photobookNotFound) {
      return <PhotobookNotFound />;
    }

    if (error) {
      return <div />;
    }

    return (
      <GalleryUploaderContext.Provider value={galleryUploader}>
        <div
          className="h-full w-full flex flex-col"
          onDragEnter={showAddPhotoModal}
          onDragOver={hideAddPhotoModalAfterDelay}
          onDragLeave={hideAddPhotoModalOnDragLeave}
          onDragEnd={hideAddPhotoModalOnDragStop}
          onDrop={hideAddPhotoModalOnDragStop}
        >
          <HomepageTopNavigation />
          <PhotoPane list={data.data.photobook.images.list} />
        </div>
        <InviteMembersModal
          open={inviteMembersModalOpen}
          handleClose={() => dispatch(setInviteMembersModalClose())}
        />
        <PrintModal open={printModalOpen} />
      </GalleryUploaderContext.Provider>
    );
  };

  return (
    (isLoading)
      ? <div className="h-full"><Loader /></div>
      : (
        <div className="h-full">
          { renderContent() }
        </div>
      )
  );
};

export default PhotobookHomepage;
