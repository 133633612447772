import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeletePhotobookMutation } from '../../api/photobook';
import { Button, SubmitButton } from '../../components/button';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';

const DeletePhotobookForm = ({ handleClose }) => {
  const { photobookUrlIdentifier } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation([TRANS_NAMESPACES.photobookSettings]);
  const { infoToast, errorToast, whoopsToast } = useToast();

  const {
    handleSubmit,
    formState: { isValidating, isSubmitting },
  } = useForm();

  const [
    deletePhotobook,
    {
      isLoading,
      isSuccess,
      isError,
      error: resError
    }
  ] = useDeletePhotobookMutation();

  useEffect(() => {
    if (isSuccess) {
      infoToast(
        t('delete_photobook_modal_success', 'Your Photobook is deleted'),
        'photobookDeletedToast'
      );
      navigate('/');
      return;
    }

    if (isError) {
      if (resError.status === 422) {
        errorToast(resError.data.errors.url_identifier[0]);
        return;
      }
      if (resError.status === 404) {
        infoToast(
          t('delete_photobook_modal_success', 'Your Photobook is deleted'),
          'photobookDeletedToast'
        );
        navigate('/');
        return;
      }
      whoopsToast();
    }
  }, [
    errorToast,
    infoToast,
    isError,
    isSuccess,
    navigate,
    resError,
    t,
    whoopsToast,
  ]);

  return (
    <>
      <Button
        className="w-100 lg:mr-4 lg:w-52"
        color="primary"
        variant="contained"
        onClick={() => handleClose()}
        abTestId="deletePhotobookCancel"
      >
        {t('delete_photobook_modal_cancel_button', 'Cancel')}
      </Button>
      <form
        className="w-100 lg:ml-4 lg:w-52"
        onSubmit={handleSubmit(() => deletePhotobook(photobookUrlIdentifier))}
      >
        <SubmitButton
          className="w-full"
          color="warning"
          variant="outlined"
          disabled={isLoading || isValidating || isSubmitting}
          abTestId="deletePhotobookSubmit"
        >
          {t('photobook_settings_delete_photobook', 'Delete Photobook')}
        </SubmitButton>
      </form>
    </>
  );
};

DeletePhotobookForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default DeletePhotobookForm;
