import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  RouterProvider,
  Route
} from 'react-router-dom';
import ToastContainer from './components/ToastContainer';
import ModalBase from './features/modal/ModalBase';
import GalleryViewer from './routes/GalleryViewer';
import Main from './routes/Main';
import NotFound from './routes/NotFound';
import PhotobookHomepage from './routes/PhotobookHomepage';
import PhotobookLiveMode from './routes/PhotobookLiveMode';
import PhotobookSettings from './routes/PhotobookSettings';
import RecentVisits from './routes/RecentVisits';
import ResetPassword from './routes/ResetPassword';
import UserAccount from './routes/UserAccount';
import './assets/index.css';
import './services/i18next';
import store from './services/reduxStore';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/" element={<Main />} />
    <Route path="photobook/:photobookUrlIdentifier/photo/:photoUuid" element={<GalleryViewer />} />
    <Route path="photobook/:photobookUrlIdentifier" element={<PhotobookHomepage />} />
    <Route path="photobook/:photobookUrlIdentifier/settings" element={<PhotobookSettings />} />
    <Route path="photobook/:photobookUrlIdentifier/livemode" element={<PhotobookLiveMode />} />
    <Route path="recent" element={<RecentVisits />} />
    <Route path="account" element={<UserAccount />} />
    <Route path="account/resetpassword" element={<ResetPassword />} />
    <Route path="404" element={<NotFound />} />
    <Route path="*" element={<Navigate to="404" />} />
  </>
));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ModalBase />
      <RouterProvider router={router} />
      <ToastContainer />
    </Provider>
  </React.StrictMode>
);
