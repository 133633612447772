import processImageByShrinking from './image-shrink/ShrinkingImageProcessor';

const getThumbnailAndPrintFiles = async (file) => {
  const { thumbnail, printable } = await processImageByShrinking(file);
  return [thumbnail, printable];
};

const getImageDimension = (file) => new Promise((resolve) => {
  // https://stackoverflow.com/a/7460303
  const fr = new FileReader();

  fr.onload = () => {
    const img = new Image();
    img.onload = () => resolve({ w: img.width, h: img.height });
    img.onerror = () => { throw new Error('error loading image'); };
    img.src = fr.result;
  };

  fr.onerror = () => { throw new Error('error reading file'); };
  fr.readAsDataURL(file);
});

export {
  getThumbnailAndPrintFiles,
  getImageDimension
};
