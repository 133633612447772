import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import useOutsideElementClick from '../../hooks/useOutsideElementClick';
import { CloseButton } from '../button';

const PORTAL_ID = 'modal-portal';

const FixedWidthModal = ({
  children,
  className,
  open,
  handleClose,
  widthClass,
  withClosebutton,
  abTestId,
  modalImage = '',
}) => {
  const elementRef = useRef(null);
  useOutsideElementClick(elementRef, handleClose);

  useEffect(() => {
    if (open) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [open]);

  if (!open) {
    return null;
  }

  // https://github.com/testing-library/react-testing-library/issues/62#issuecomment-438653348
  let portal = document.getElementById(PORTAL_ID);
  if (!portal) {
    portal = document.createElement('div');
    portal.setAttribute('id', PORTAL_ID);
    document.body.appendChild(portal);
  }

  return ReactDOM.createPortal(
    <div className="fixed bg-black/50 inset-0 z-50 overflow-y-auto" data-testid="fixed-width-modal-backdrop">
      <div className={`modal-centered ${widthClass} flex items-center justify-center`}>
        <div
          ref={elementRef}
          className={`${className} relative rounded-lg bg-white shadow-custom`}
          data-testid="fixed-width-modal-dialog"
          data-abtestid={abTestId}
        >
          { modalImage
            && (
              <img src={modalImage} className="rounded-t-lg" alt="modal image" />
            )}
          <div className={modalImage ? 'pt-4 pb-8 px-8' : 'p-8'}>
            { withClosebutton
              && (
              <div className="absolute top-4 lg:top-8 right-4 lg:right-8">
                <CloseButton onClick={handleClose} />
              </div>
              )}
            {children}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById(PORTAL_ID)
  );
};

FixedWidthModal.defaultProps = {
  className: '',
  widthClass: 'w-[560px]',
  withClosebutton: true,
};

FixedWidthModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  widthClass: PropTypes.string,
  withClosebutton: PropTypes.bool,
  abTestId: PropTypes.string.isRequired,
  modalImage: PropTypes.string,
};

export default FixedWidthModal;
