import { useSelector } from 'react-redux';

const useUserType = () => {
  const userToken = useSelector((state) => state.auth.user.token);
  const guestToken = useSelector((state) => state.auth.guest.token);

  return {
    isAccountHolder: Boolean(userToken),
    isGuest: Boolean(guestToken),
    isRegistered: Boolean(userToken || guestToken)
  };
};

export default useUserType;
