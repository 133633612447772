import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRemoveRecentVisitsMutation } from '../../api/recentVisit';
import {
  BackIcon, DeleteIcon
} from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import useClearAuthTokens from '../../hooks/useClearAuthTokens';
import useToast from '../../hooks/useToast';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setDeleteRecentVisitsModalOpen } from '../../slices/modal';

const RecentVisitsTopNavigation = ({ allInLibrary }) => {
  const { t } = useTranslation(TRANS_NAMESPACES.recentVisits);
  const dispatch = useDispatch();
  const { infoToast, whoopsToast } = useToast();
  const clearAuthTokens = useClearAuthTokens();

  const [
    removeRecentVisits,
    {
      isLoading,
      isSuccess,
      isError,
      error
    }
  ] = useRemoveRecentVisitsMutation();

  useEffect(() => {
    if (isSuccess) {
      infoToast(
        t('delete_recent_visits_modal_success', 'Your Recent Visits are deleted'),
        'recentVisitsDeletedToast'
      );
      return;
    }

    if (isError) {
      if (error.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isError,
    isSuccess,
    error,
    t,
    infoToast,
    whoopsToast,
    clearAuthTokens,
  ]);

  return (
    <div className="flex items-center justify-between border-b border-fog min-h-[60px] px-4 lg:px-8">
      <div className="flex gap-x-4 lg:gap-x-8 items-center">
        <Link data-abtestid="homepageBack" to="/"><img className="min-w-[20px] w-5 h-5" src={BackIcon} alt="back" /></Link>
        <p className="mr-4" data-abtestid="topNavRecentVisitsTitle">{t('top_navigation_bar_recent_visits', 'Recent Visits')}</p>
      </div>
      <button
        type="button"
        data-abtestid="deleteRecentVisitsButton"
        className="flex gap-x-8"
        disabled={isLoading}
        onClick={() => {
          if (!allInLibrary) {
            dispatch(setDeleteRecentVisitsModalOpen());
          } else {
            removeRecentVisits();
          }
        }}
      >
        <IconPaddedText
          icon={<img className="min-w-[20px] w-5 h-5" src={DeleteIcon} alt="Clear Recent Visits" />}
          text={t('top_navigation_bar_clear_recent_visits', 'Clear Recent Visits')}
          textOnLargeScreenOnly
        />
      </button>
    </div>
  );
};

RecentVisitsTopNavigation.propTypes = {
  allInLibrary: PropTypes.bool.isRequired,
};

export default RecentVisitsTopNavigation;
