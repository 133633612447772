// Note: make sure to return unless it's the last line within a function
// https://stackoverflow.com/a/10525646
const simulateHttpRedirect = (to) => window.location.replace(to);

// https://github.com/vercel/next.js/discussions/18072#discussioncomment-109059
const noRenderReplaceUrl = (url) => window.history.replaceState({ ...window.history.state }, '', url);

const commaSeparateNumber = (number) => (new Intl.NumberFormat('en-US')).format(number);

const throwIfErroneousResponse = (response) => {
  // https://stackoverflow.com/a/39283005
  if (Object.prototype.hasOwnProperty.call(response, 'error')) {
    throw response.error.error;
  }
};

const noop = () => {};
export {
  commaSeparateNumber,
  noRenderReplaceUrl,
  simulateHttpRedirect,
  throwIfErroneousResponse,
  noop,
};
