import piexif from 'piexifjs';
import { shrink, packFileData } from './LowLevelConversion';

const maximumByteSize = 500 * 1024; // 500 KB
const maximumThumbnailPXDimension = 480;
const maximumPrintablePXDimension = 2048;

// TagId=274 is for Orientation. Adding this tag will cause the image to rotate since
// the image produced by the Canvas is assumed to be in position 1 without any rotation.
// for more information about rotation positions: https://jdhao.github.io/2019/07/31/image_rotation_exif_info/
// Orientation Tag definition: https://www.media.mit.edu/pia/Research/deepview/exif.html
const exifTagIdsToIgnore = [274];

const getModifiedDateByMetaData = (tags) => {
  try {
    // images have different keys for date, based on type & origin and some even don't have any
    const foundDate = tags.DateCreated
      || tags.DateTimeOriginal
      || tags.DateTime;
    if (foundDate && typeof foundDate.description === 'string') {
      const parsedDate = foundDate.description.replace(/(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3');
      return (new Date(parsedDate)).getTime();
    }
  } catch (error) {
    console.error('problem getting metadata from', error); // eslint-disable-line no-console
  }
  return null;
};

async function convertToThumbnail(sourceImage, name, lastModified) {
  const compressedThumbnail = await shrink(
    sourceImage,
    maximumThumbnailPXDimension,
    maximumByteSize
  );
  return packFileData(compressedThumbnail, name, lastModified);
}

async function convertToPrintable(sourceImage, name, lastModified, exifData) {
  const compressedPrintable = await shrink(
    sourceImage,
    maximumPrintablePXDimension,
    maximumByteSize
  );

  let response;

  try {
    const zeroth = {};
    const exif = {};
    const gps = {};
    const interop = {};

    Object.keys(exifData).forEach((key) => {
      if (!exifData[key].id) {
        return;
      }
      const { id } = exifData[key];
      if (exifTagIdsToIgnore.includes(id)) {
        return;
      }
      if (piexif.ImageIFD[key] && piexif.TAGS.Image[id]) {
        if ((piexif.TAGS.Image[id].type === 'Undefined')) { // tags with undefined type are ignored due to a bug in the piexifjs@1.0.6 during writing exif as it corrupt the whole exif bytes
          return;
        }
        zeroth[piexif.ImageIFD[key]] = exifData[key].value;
      } else if (piexif.ExifIFD[key] && piexif.TAGS.Exif[id]) {
        if (piexif.TAGS.Exif[id].type === 'Undefined') {
          return;
        }
        exif[piexif.ExifIFD[key]] = exifData[key].value;
      } else if (piexif.GPSIFD[key] && piexif.TAGS.GPS[id]) {
        if (piexif.TAGS.GPS[id].type === 'Undefined') {
          return;
        }
        gps[piexif.GPSIFD[key]] = exifData[key].value;
      } else if (piexif.InteropIFD[key] && piexif.TAGS.Interop[id]) {
        if (piexif.TAGS.Interop[id].type === 'Undefined') {
          return;
        }
        interop[piexif.InteropIFD[key]] = exifData[key].value;
      }
    });

    const exifObj = {
      '0th': zeroth, Exif: exif, GPS: gps, Interop: interop
    };
    const exifbytes = await piexif.dump(exifObj);

    const fileToBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

    const imageBase64 = await fileToBase64(compressedPrintable);
    const imageWithExif = await piexif.insert(exifbytes, imageBase64.toString('binary'));

    // convert base64 to arraybuffer
    // refer to: https://gist.github.com/borismus/1032746
    const BASE64_MARKER = ';base64,';
    const base64Index = imageWithExif.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = imageWithExif.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const arrayBuffer = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      arrayBuffer[i] = raw.charCodeAt(i);
    }
    response = packFileData(arrayBuffer, name, lastModified);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('failed to write exif data', err);
    response = packFileData(compressedPrintable, name, lastModified);
  }

  return response;
}

export default async function processForPrintAndThumbnail(
  originalImage,
  name,
  exifData,
) {
  const lastModified = getModifiedDateByMetaData(exifData) ?? originalImage.lastModified;
  const printable = await convertToPrintable(originalImage, name, lastModified, exifData);
  // getting thumbnail from printable instead of original image to reduce file reading process
  const thumbnail = await convertToThumbnail(printable, name, lastModified);
  return {
    printable, thumbnail
  };
}
