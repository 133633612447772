import PropTypes from 'prop-types';
import React from 'react';

const Card = ({ children, className = '' }) => (
  <div className={`px-4 py-8 lg:p-8 bg-white ${className}`}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Card;
