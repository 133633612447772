import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PhotobookMissingIcon } from '../../assets/images';
import { Button } from '../../components/button';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { NotFoundTopNavigation } from '../top-navigation';

const PhotobookNotFound = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookNotFound);
  const navigate = useNavigate();

  return (
    <div className="absolute h-full w-full flex flex-col">
      <NotFoundTopNavigation />
      <div className="h-screen flex flex-col justify-center items-center px-4">
        <img src={PhotobookMissingIcon} alt="not found" />
        <p className="mt-2 text-center text-2xl">{t('header', 'Oops! The photobook is not found')}</p>
        <div className="flex flex-col lg:max-w-[560px] mt-2 mb-8 text-center">
          <p>{t('content_line', 'You may have the incorrect website address or the Photobook’s privacy has been updated. Please contact the owner to grant you access to the Photobook.')}</p>
        </div>
        <Button
          type="button"
          color="primary"
          variant="contained"
          className="w-full lg:w-[200px] py-2"
          abTestId="photobookNotFoundNavigateButton"
          onClick={() => navigate('/')}
        >
          {t('go_back_cta', 'Go to Library')}
        </Button>
      </div>
    </div>
  );
};

export default PhotobookNotFound;
