import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DownloadIcon } from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import useToast from '../../hooks/useToast';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setAuthModalOpen } from '../../slices/modal';
import download from '../../utils/download';

const DownloadPhotoButton = ({ downloadData: { from, saveAs } }) => {
  const { isAccountHolder } = useUserType();
  const dispatch = useDispatch();
  const { errorToast } = useToast();
  const { t } = useTranslation([TRANS_NAMESPACES.galleryViewer, TRANS_NAMESPACES.misc]);

  const handleClick = () => {
    if (!isAccountHolder) {
      dispatch(setAuthModalOpen());
      return;
    }
    download(from, saveAs)
      .catch(() => errorToast(t('download_failed_single', 'Failed to download this photo. The photo may have been removed by the owner.', { ns: TRANS_NAMESPACES.misc })));
  };

  return (
    <button type="button" onClick={handleClick} data-abtestid="downloadPhoto">
      <IconPaddedText
        icon={<img className="w-5 h-5" src={DownloadIcon} alt="download icon" />}
        text={t('download', 'Download')}
        textOnLargeScreenOnly
      />
    </button>
  );
};

DownloadPhotoButton.propTypes = {
  downloadData: PropTypes.exact({
    from: PropTypes.string,
    saveAs: PropTypes.string,
  }),
};

export default DownloadPhotoButton;
