import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

// https://usehooks.com/useWindowSize/
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const setDimension = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const debouncedSetDimension = debounce(setDimension, 150);

    window.addEventListener('resize', debouncedSetDimension);
    setDimension();

    return () => window.removeEventListener('resize', debouncedSetDimension);
  }, []);

  return windowSize;
};

export default useWindowSize;
