import ExifReader from 'exifreader';
import heic2any from 'heic2any';
import processForPrintAndThumbnail from './CoreProcess';

const targetExtension = 'jpg';
const intermediateType = 'image/png';

const getJpegName = (fileName) => {
  const nameComponents = fileName.split('.');
  if (!nameComponents[1]
    || nameComponents[1] !== targetExtension
  ) {
    nameComponents[1] = targetExtension;
  }
  return nameComponents.join('.');
};

const getFileType = (fileMimeType) => {
  if (fileMimeType.match(/jpeg/g)) return 'jpg';
  if (fileMimeType.match(/heic/g)) return 'heic';
  if (fileMimeType.match(/png/g)) return 'png';
  if (fileMimeType.match(/gif/g)) return 'gif';
  return 'NOT_SUPPORTED';
};

const processImageByShrinking = async (sourceImage) => {
  const name = getJpegName(sourceImage.name);
  const fileType = getFileType(sourceImage.type);
  const exifData = await ExifReader.load(sourceImage);

  switch (fileType) {
    case 'png':
    case 'jpg':
      return processForPrintAndThumbnail(sourceImage, name, exifData);
    case 'heic': {
      const convertedImageBlob = await heic2any({ blob: sourceImage, toType: intermediateType });
      const convertedImageFile = (new File([convertedImageBlob], name));
      return processForPrintAndThumbnail(convertedImageFile, name, exifData);
    }
    case 'gif':
    case 'NOT_SUPPORTED':
    default:
      console.error(`file type ${fileType}  not supported. Returning file without change`); // eslint-disable-line no-console
      return {
        thumbnail: sourceImage,
        printable: sourceImage
      };
  }
};

export default processImageByShrinking;
