import React from 'react';
import { LandingPage } from '../features/landing-page';
import { Library } from '../features/library';
import useUserType from '../hooks/useUserType';

const Main = () => {
  const { isRegistered } = useUserType();

  return (
    isRegistered
      ? <Library />
      : <LandingPage />
  );
};

export default Main;
