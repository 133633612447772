import { useEffect } from 'react';

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component/42234988#42234988
const useOutsideElementClick = (ref, handleClick) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const scrollbarWidth = 15;
        const userClicksOnScrollbar = window.innerWidth - scrollbarWidth <= event.clientX;

        if (!userClicksOnScrollbar) {
          handleClick();
        }
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref, handleClick]);
};

export default useOutsideElementClick;
