import PropTypes from 'prop-types';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';
import React, { useEffect, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetPhotobookQuery } from '../../api/photobook';
import { LeftArrowIcon, RightArrowIcon } from '../../assets/images';
import Loader from '../../components/Loader';
import useToast from '../../hooks/useToast';
import { noRenderReplaceUrl } from '../../utils/generic';
import SlideChangedListener from './SlideChangedListener';

const dontShowContextMenu = (e) => e.preventDefault();

const Carousel = ({ width, height, setDownloadData }) => {
  const { photobookUrlIdentifier } = useParams();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data,
    error,
    isError,
    isSuccess,
    isLoading,
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });

  const [photoIndex, setPhotoIndex] = useState(undefined);
  const { whoopsToast } = useToast();
  const { pathname } = useLocation();
  const pathnameWithoutPhotoUuid = pathname.substring(0, pathname.lastIndexOf('/'));
  const initialPhotoUuid = pathname.substring(pathname.lastIndexOf('/') + 1);
  const navigate = useNavigate();
  const preloadImageCount = 3;

  useEffect(() => {
    if (isSuccess && photoIndex === undefined) {
      // first load only
      const initialPhotoIndex = data.data.photobook.images.list.findIndex(
        (photo) => photo.uuid === initialPhotoUuid
      );
      if (initialPhotoIndex > -1) {
        setPhotoIndex(initialPhotoIndex);
        return;
      }
      navigate('/404');
      return;
    }

    if (isError) {
      if ([404, 422].includes(error.status)) {
        navigate('/404');
        return;
      }

      whoopsToast();
    }
  }, [isSuccess, initialPhotoUuid, isError, data, error, whoopsToast, navigate, photoIndex]);

  useEffect(() => {
    if (isSuccess
      && photoIndex !== undefined) {
      // required because refetching upon deletion causes the hook to rerun before the carousel
      // updates the index -- leading to out of bounds errors
      const correctedPhotoIndex = (photoIndex < data.data.photobook.images.list.length)
        ? photoIndex : data.data.photobook.images.list.length - 1;
      const photo = data.data.photobook.images.list[correctedPhotoIndex];
      noRenderReplaceUrl(`${pathnameWithoutPhotoUuid}/${photo.uuid}`);
      setDownloadData({ from: photo.image.print, saveAs: photo.uuid });
    }
  }, [isSuccess, photoIndex, data, pathnameWithoutPhotoUuid, setDownloadData]);

  const loadImage = (imageIndex) => {
    const elem = document.getElementById(`photo-${imageIndex}`);
    if (elem && elem.hasAttribute('data-src')) {
      elem.setAttribute('src', elem.getAttribute('data-src'));
      elem.removeAttribute('data-src');
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (photoIndex > -1) {
        loadImage(photoIndex);
        const upperBoundIndex = Math.min(
          photoIndex + preloadImageCount,
          data.data.photobook.images.list.length - 1
        );
        const lowerBoundIndex = Math.max(photoIndex - preloadImageCount, 0);
        let indexAfter = photoIndex + 1;
        let indexBefore = photoIndex - 1;
        let preloadAfter = indexAfter <= upperBoundIndex;
        let preloadBefore = indexBefore >= lowerBoundIndex;
        while (preloadAfter || preloadBefore) {
          if (preloadAfter) {
            loadImage(indexAfter);
            indexAfter += 1;
            preloadAfter = indexAfter <= upperBoundIndex;
          }

          if (preloadBefore) {
            loadImage(indexBefore);
            indexBefore -= 1;
            preloadBefore = indexBefore >= lowerBoundIndex;
          }
        }
      }
    }
  }, [photoIndex, isSuccess, data]);

  return (
    (isLoading || photoIndex === undefined)
      ? <Loader />
      : (isSuccess && (
        <CarouselProvider
          naturalSlideWidth={width}
          naturalSlideHeight={height}
          totalSlides={data.data.photobook.images.list.length}
          className="relative"
          currentSlide={photoIndex}
        >
          <SlideChangedListener setChangedSlide={setPhotoIndex} />
          <Slider>
            {data.data.photobook.images.list.map((photo, i) => (
              <Slide key={photo.uuid} innerClassName="flex justify-center items-center" index={i}>
                <img id={`photo-${i}`} onContextMenu={dontShowContextMenu} className="max-h-full" alt={`photo ${i + 1}`} data-src={photo.image.print} />
              </Slide>
            ))}
          </Slider>
          <ButtonBack data-abtestid="previousImage" className="absolute top-1/2 left-2 lg:left-4 -translate-y-1/2 flex justify-center items-center w-8 h-8 lg:w-14 lg:h-14 bg-white rounded-full opacity-80 drop-shadow-md">
            <img className="scale-50 lg:scale-100" alt="left arrow" src={LeftArrowIcon} />
          </ButtonBack>
          <ButtonNext data-abtestid="nextImage" className="absolute top-1/2 right-2 lg:right-4 -translate-y-1/2 flex justify-center items-center w-8 h-8 lg:w-14 lg:h-14 bg-white rounded-full opacity-80 drop-shadow-md">
            <img className="scale-50 lg:scale-100" alt="right arrow" src={RightArrowIcon} />
          </ButtonNext>
        </CarouselProvider>
      )
      )
  );
};

Carousel.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  setDownloadData: PropTypes.func.isRequired,
};

export default Carousel;
