import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { WarningIcon } from '../../assets/images';
import { Button } from '../../components/button';
import FixedWidthModal from '../../components/modal/FixedWidthModal';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setPrintOddPhotosModalClose } from '../../slices/modal';
import { allowPrintOddPhotos } from '../../slices/projectCreation';
import { noop } from '../../utils/generic';

const PrintOddPhotoModal = ({ open }) => {
  const dispatch = useDispatch();
  const isLargeScreen = useLargeScreenMediaQuery();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);

  const handleConfirmButton = () => {
    dispatch(allowPrintOddPhotos());
    dispatch(setPrintOddPhotosModalClose());
  };

  const renderContent = () => (
    <>
      <div className="flex flex-col gap-y-2 text-center">
        <img className="mx-auto" src={WarningIcon} alt="warning icon" />
        <div className="text-2xl">
          {t('print_odd_photo_modal_header', 'Ops!')}
        </div>
        <div>
          {t('print_odd_photo_modal_description', 'You have an odd number of photos selected. This will add an extra blank page when printed. Would you like to continue?')}
        </div>
      </div>
      <div className="mt-8 flex flex-col-reverse lg:flex-row items-center justify-center gap-y-4 lg:gap-x-8">
        <Button
          className="w-52"
          onClick={() => dispatch(setPrintOddPhotosModalClose())}
          abTestId="printOddPhotoModalNo"
        >
          {t('print_odd_photo_modal_no', 'No')}
        </Button>
        <Button
          className="w-52"
          color="warning"
          variant="outlined"
          onClick={handleConfirmButton}
          abTestId="printOddPhotoModalYes"
        >
          {t('print_odd_photo_modal_yes', 'Yes')}
        </Button>
      </div>
    </>
  );

  return (
    (isLargeScreen) ? (
      <FixedWidthModal
        className="w-full"
        open={open}
        handleClose={noop}
        withClosebutton={false}
        abTestId="printOddPhotoModal"
      >
        {renderContent()}
      </FixedWidthModal>
    ) : (
      <FixedWidthModal
        className="mx-4 w-full"
        open={open}
        handleClose={noop}
        widthClass=""
        withClosebutton={false}
        abTestId="printOddPhotoModal"
      >
        {renderContent()}
      </FixedWidthModal>
    )
  );
};

PrintOddPhotoModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default PrintOddPhotoModal;
