import React from 'react';
import { LoaderGif } from '../assets/images';

const Loader = () => (
  <div className="flex w-screen h-screen items-center justify-center">
    <img src={LoaderGif} alt="loading" />
  </div>
);

export default Loader;
