import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CharcoalUploadIcon } from '../../assets/images';
import { Button } from '../../components/button';
import Dropzone from '../../components/Dropzone';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setGuestModalOpen } from '../../slices/modal';

const EmptyPhotoGallery = () => {
  const isLargeScreen = useLargeScreenMediaQuery();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const { isRegistered } = useUserType();
  const dispatch = useDispatch();

  const emptyPhotoGalleryText = isLargeScreen
    ? t('empty_photo_gallery', 'There aren\'t any photos in this Photobook yet.\nDrag and drop or')
    : t('empty_photo_gallery_mobile', 'There aren\'t any photos in this Photobook yet.');
  const addPhotosButtonText = isLargeScreen
    ? t('empty_photo_gallery_add_photos', 'Click Here to Add Photos')
    : t('add_photos', 'Add Photos');

  return (
    <div className="h-full bg-white">
      {
        isRegistered
          ? (
            <Dropzone
              isDefaultStyle={false}
              className="h-full flex flex-col items-center justify-center"
              abTestId="emptyPhotoGalleryDropzone"
            >
              <img src={CharcoalUploadIcon} alt="Upload image" />
              <p className="mt-2.5 mx-8 text-center whitespace-pre-line">
                {emptyPhotoGalleryText}
              </p>
              <Button className="mt-4 mx-8 self-stretch lg:self-auto" abTestId="emptyGalleryAddPhotos">
                {addPhotosButtonText}
              </Button>
            </Dropzone>
          )
          : (
            <div className="h-full flex flex-col items-center justify-center" onClick={() => dispatch(setGuestModalOpen())}>
              <img src={CharcoalUploadIcon} alt="Upload image" />
              <p className="mt-2.5 mx-8 text-center whitespace-pre-line">
                {emptyPhotoGalleryText}
              </p>
              <Button className="mt-4 mx-8 self-stretch lg:self-auto" abTestId="emptyGalleryAddPhotos">
                {addPhotosButtonText}
              </Button>
            </div>
          )
      }
    </div>
  );
};

export default EmptyPhotoGallery;
