import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useState, useRef
} from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PRINT_SELECTION_MAX_PHOTOS } from '../../utils/constants';
import AddPhotosButton from './AddPhotosButton';
import DeselectAllButton from './DeselectAllButton';
import EllipsisMenuButton from './EllipsisMenuButton';
import PhotoCard from './PhotoCard';
import SelectAllButton from './SelectAllButton';

const PER_PAGE = 10;
const MOBILE_VIEW_HORIZONTAL_PADDING = 8;
const MOBILE_VIEW_HORIZONTAL_GAP = 8;

const getMobileViewGridWidth = () => (
  Math.ceil(
    (window.innerWidth - (MOBILE_VIEW_HORIZONTAL_PADDING * 2) - MOBILE_VIEW_HORIZONTAL_GAP) / 2
  )
);

const PhotoGallery = ({ list }) => {
  const [lastCard, setLastCard] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const totalPages = Math.ceil(list.length / PER_PAGE);
  const targetImageRef = useRef(null);
  const [mobileViewGridWidth, setMobileViewGridWidth] = useState(getMobileViewGridWidth());
  const [searchParams] = useSearchParams();
  const targetNewPhotoUuid = searchParams.get('new_photo_uuid');
  const [targetImageLoaded, setTargetImageLoaded] = useState(false);
  const inSelectionMode = useSelector((state) => state.download.inSelectionMode);
  const selectedPhotos = useSelector((state) => state.download.selectedPhotos);
  const allPhotosSelected = list.length === selectedPhotos.length;
  const inCoverSelectionMode = useSelector((state) => state.projectCreation.inCoverSelectionMode);
  const inPrintSelectionMode = useSelector((state) => state.projectCreation.inPrintSelectionMode);
  const selectedPrintPhotos = useSelector((state) => state.projectCreation.selectedPrintPhotos);
  const allPrintPhotosSelected = (selectedPrintPhotos.length === PRINT_SELECTION_MAX_PHOTOS)
                                  || (list.length === selectedPrintPhotos.length);

  useEffect(() => {
    const handleResize = () => {
      setMobileViewGridWidth(getMobileViewGridWidth());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (targetNewPhotoUuid !== null) {
      const targetedImageIndex = list.findIndex((photo) => photo.uuid === targetNewPhotoUuid);
      if (targetedImageIndex === -1) {
        return;
      }
      const numOfPageToLoad = Math.ceil(targetedImageIndex + 1 / PER_PAGE);
      setPageNum(numOfPageToLoad);
    }
  }, [targetNewPhotoUuid, list]);

  useEffect(() => {
    if (targetImageRef.current && targetImageLoaded) {
      targetImageRef.current.scrollIntoView();
    }
  }, [targetImageRef, targetImageLoaded]);

  const observer = useMemo(() => new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        setPageNum((prev) => prev + 1);
      }
    },
    { threshold: 0.5 }
  ), []);

  useEffect(() => {
    const notLastPage = pageNum < totalPages;
    const currentLastCard = lastCard;

    if (notLastPage && currentLastCard) {
      observer.observe(currentLastCard);
    }

    return () => {
      if (currentLastCard) {
        observer.unobserve(currentLastCard);
      }
    };
  }, [pageNum, totalPages, observer, lastCard]);

  const photos = list.slice(0, pageNum * PER_PAGE);

  const renderFloatingButtons = () => {
    if (inSelectionMode) {
      return allPhotosSelected
        ? <DeselectAllButton />
        : <SelectAllButton photos={list} />;
    }

    if (inCoverSelectionMode) {
      return <div />;
    }

    if (inPrintSelectionMode) {
      return allPrintPhotosSelected
        ? <DeselectAllButton />
        : <SelectAllButton photos={list} />;
    }
    return (
      <>
        <EllipsisMenuButton />
        <AddPhotosButton />
      </>
    );
  };

  return (
    <div className="grid grid-cols-2 photo-gallery-grid auto-rows-[10px] gap-x-2 lg:gap-x-4 lg:justify-center w-full lg:pb-8 px-2 lg:px-4">
      {(
        list.length > 0
        && photos.map((item, index) => {
          const isLastCard = index === photos.length - 1;
          return isLastCard ? (
            <PhotoCard
              item={item}
              mobileViewGridWidth={mobileViewGridWidth}
              index={index}
              key={item.uuid}
              isTargetImage={targetNewPhotoUuid === item.uuid}
              targetImageRef={targetImageRef}
              setTargetImageLoaded={setTargetImageLoaded}
              lastCardRef={setLastCard}
            />
          ) : (
            <PhotoCard
              item={item}
              mobileViewGridWidth={mobileViewGridWidth}
              index={index}
              key={item.uuid}
              isTargetImage={targetNewPhotoUuid === item.uuid}
              targetImageRef={targetImageRef}
              setTargetImageLoaded={setTargetImageLoaded}
            />
          );
        }))}
      <div className="fixed flex justify-center inset-x-0 bottom-4 gap-4">
        {renderFloatingButtons()}
      </div>
    </div>
  );
};

PhotoGallery.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    image: PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
      print: PropTypes.string
    }),
    user: PropTypes.shape({
      uuid: PropTypes.string,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  })),
};

export default PhotoGallery;
