import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { CloseButton } from '../button';

const PORTAL_ID = 'modal-portal';

const FullscreenModal = ({
  children,
  className,
  open,
  handleClose,
  withClosebutton,
  abTestId,
  modalImage = '',
}) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [open]);

  if (!open) {
    return null;
  }

  // https://github.com/testing-library/react-testing-library/issues/62#issuecomment-438653348
  let portal = document.getElementById(PORTAL_ID);
  if (!portal) {
    portal = document.createElement('div');
    portal.setAttribute('id', PORTAL_ID);
    document.body.appendChild(portal);
  }

  return ReactDOM.createPortal(
    <div className="fixed bg-white inset-0 z-50 overflow-y-auto" data-testid="fullscreen-modal-backdrop">
      <div className="w-full min-h-full" data-testid="fullscreen-modal-dialog" data-abtestid={abTestId}>
        { modalImage
          && (
            <img src={modalImage} alt="modal image" />
          )}
        { withClosebutton
          && (
          <div className="absolute top-4 lg:top-8 right-4 lg:right-8">
            <CloseButton onClick={handleClose} />
          </div>
          )}
        <div className={`${className}`}>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById(PORTAL_ID)
  );
};

FullscreenModal.defaultProps = {
  className: '',
  withClosebutton: true,
};

FullscreenModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  withClosebutton: PropTypes.bool,
  abTestId: PropTypes.string.isRequired,
  modalImage: PropTypes.string,
};

export default FullscreenModal;
