import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

export const TOAST_GUTTER = 16;

const ToastContainer = () => {
  const containerStyle = useSelector((state) => state.toastContainerStyle);
  return <Toaster containerStyle={containerStyle} gutter={TOAST_GUTTER} />;
};

export default ToastContainer;
