import PropTypes from 'prop-types';
import React from 'react';
import {
  FacebookIcon,
  LineIcon,
  LinkedInIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsAppIcon
} from '../../assets/images';
import { SocialMediaButton } from '../../components/button';

const SocialMediaIcons = ({
  link,
  title,
  abTestId = '',
}) => {
  const encodedLink = encodeURIComponent(link);
  const encodedTitle = encodeURIComponent(title);
  const encodedPreviewImage = '';
  const facebookShareUrl = `https://facebook.com/sharer/sharer.php?u=${encodedLink}`;
  const lineShareUrl = `https://social-plugins.line.me/lineit/share?url=${encodedLink}`;
  const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&summary=${encodedTitle}&title${encodedTitle}&url=${encodedLink}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet/?text=${encodedTitle}&url=${encodedLink}`;
  const pinterestShareUrl = `https://pinterest.com/pin/create/button/?url=${encodedLink}&media=${encodedPreviewImage}&description=${encodedTitle}`;
  const whatsAppShareUrl = `https://api.whatsapp.com/send?text=${encodedLink}`;

  return (
    <div className="grid grid-cols-3 gap-8 justify-center lg:flex">
      <SocialMediaButton shareUrl={facebookShareUrl} platform="Facebook" src={FacebookIcon} abTestId={`${abTestId}Facebook`} />
      <SocialMediaButton shareUrl={linkedInShareUrl} platform="LinkedIn" src={LinkedInIcon} abTestId={`${abTestId}LinkedIn`} />
      <SocialMediaButton shareUrl={twitterShareUrl} platform="Twitter" src={TwitterIcon} abTestId={`${abTestId}Twitter`} />
      <SocialMediaButton shareUrl={whatsAppShareUrl} platform="WhatsApp" src={WhatsAppIcon} abTestId={`${abTestId}WhatsApp`} />
      <SocialMediaButton shareUrl={lineShareUrl} platform="LINE" src={LineIcon} abTestId={`${abTestId}Line`} />
      <SocialMediaButton shareUrl={pinterestShareUrl} platform="Pinterest" src={PinterestIcon} abTestId={`${abTestId}Pinterest`} />
    </div>
  );
};

SocialMediaIcons.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  abTestId: PropTypes.string,
};

export default SocialMediaIcons;
