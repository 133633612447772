import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EmptyImagePlaceholder } from '../../assets/images';
import { TRANS_NAMESPACES } from '../../services/i18next';

const PhotobookCard = ({ photobook, index }) => {
  const {
    coverImage,
    isAdmin,
    name,
    urlIdentifier
  } = photobook;

  const { t } = useTranslation(TRANS_NAMESPACES.library);

  return (
    <Link to={`/photobook/${urlIdentifier}`} className="flex flex-col gap-y-2">
      <div className="relative w-full">
        {
          coverImage ? (
            <img
              src={coverImage}
              className="object-cover aspect-square rounded-lg w-full"
              alt="cover photo"
              data-abtestid={`photobookThumbnail${index + 1}`}
            />
          )
            : (
              <div className="flex rounded-lg bg-lightGrey w-full aspect-square">
                <img
                  src={EmptyImagePlaceholder}
                  className="m-auto h-12 w-12 object-cover"
                  alt="empty image placeholder"
                  data-abtestid={`photobookThumbnail${index + 1}`}
                />
              </div>
            )
        }
        { !isAdmin && (
        <span className="bg-white/[.32] py-1 px-3 text-xs text-white rounded-lg font-bold absolute right-2 bottom-2">
          {t('shared_to_me_badge', 'Shared to Me')}
        </span>
        )}
      </div>
      <div>
        <p className="truncate" data-abtestid={`photobookName${index + 1}`}>{name}</p>
      </div>
    </Link>
  );
};

PhotobookCard.propTypes = {
  photobook: PropTypes.shape({
    coverImage: PropTypes.string,
    isAdmin: PropTypes.bool,
    name: PropTypes.string,
    urlIdentifier: PropTypes.string
  }),
  index: PropTypes.number,
};

export default PhotobookCard;
