import PropTypes from 'prop-types';
import React from 'react';

const SocialMediaButton = ({
  shareUrl,
  platform,
  src,
  abTestId = ''
}) => (
  <a className="inline-block" href={shareUrl} target="_blank" rel="noopener noreferrer" aria-label={platform} {...(abTestId ? { 'data-abtestid': abTestId } : {})}>
    <div>
      <img className="w-6 h-6" src={src} alt={platform} />
    </div>
  </a>
);

SocialMediaButton.propTypes = {
  shareUrl: PropTypes.string,
  platform: PropTypes.string,
  src: PropTypes.string,
  abTestId: PropTypes.string,
};

export default SocialMediaButton;
