import React, {
  useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLibraryQuery } from '../api/library';
import { useGetPhotobookQuery } from '../api/photobook';
import Loader from '../components/Loader';
import PhotobookSettingsBody from '../features/photobook-settings';
import { PhotobookSettingsTopNavigation } from '../features/top-navigation';
import useClearAuthTokens from '../hooks/useClearAuthTokens';
import useToast from '../hooks/useToast';
import useUserType from '../hooks/useUserType';
import {
  setAddedToLibrary,
  setIsAdmin,
} from '../slices/photobook';
import decodeJWT from '../utils/jwt';

const getUserUuid = (token) => {
  if (token) {
    return decodeJWT(token).sub;
  }
  return '';
};

const PhotobookSettings = () => {
  const { photobookUrlIdentifier } = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.user.token);
  const navigate = useNavigate();
  const { whoopsToast } = useToast();
  const { isRegistered } = useUserType();
  const clearAuthTokens = useClearAuthTokens();
  const sortOption = useSelector((state) => state.photobook.sortOption);
  const {
    data,
    error,
    isError,
    isLoading,
    isSuccess
  } = useGetPhotobookQuery({ photobookUrlIdentifier, sortOption });

  const {
    data: getLibraryResponse,
    error: getLibraryError,
    isLoading: isGetLibraryLoading,
    isError: isGetLibraryError,
    isSuccess: isGetLibrarySuccess
  } = useGetLibraryQuery(
    { details: false },
    { skip: !isRegistered, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isGetLibrarySuccess) {
      dispatch(setAddedToLibrary(getLibraryResponse.data.includes(photobookUrlIdentifier)));
      return;
    }

    if (isGetLibraryError) {
      if (getLibraryError.status === 401) {
        clearAuthTokens();
        return;
      }
      whoopsToast();
    }
  }, [
    isGetLibrarySuccess,
    getLibraryResponse,
    photobookUrlIdentifier,
    isGetLibraryError,
    getLibraryError,
    whoopsToast,
    dispatch,
    clearAuthTokens
  ]);

  useEffect(() => {
    if (isSuccess) {
      const isAdmin = data.data.photobook.admin_uuid === getUserUuid(token);

      dispatch(setIsAdmin(isAdmin));
      return;
    }

    if (isError) {
      if ([404, 422].includes(error.status)) {
        navigate('/404');
        return;
      }

      whoopsToast();
    }
  }, [
    isSuccess,
    isError,
    error,
    data,
    token,
    whoopsToast,
    dispatch,
    navigate
  ]);

  return (
    (isLoading || isGetLibraryLoading)
      ? <div className="h-full"><Loader /></div>
      : (
        <div>
          {error
            ? <div />
            : (
              <div>
                <PhotobookSettingsTopNavigation />
                <PhotobookSettingsBody />
              </div>
            )}
        </div>
      )
  );
};

export default PhotobookSettings;
