import PropTypes from 'prop-types';
import { CarouselContext } from 'pure-react-carousel';
import { useContext, useEffect } from 'react';

const SlideChangedListener = ({ setChangedSlide }) => {
  const carouselContext = useContext(CarouselContext);

  useEffect(() => {
    const onChange = () => setChangedSlide(carouselContext.state.currentSlide);
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, setChangedSlide]);

  // renders nothing
};

SlideChangedListener.propTypes = {
  setChangedSlide: PropTypes.func.isRequired,
};

export default SlideChangedListener;
