const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    return serializedState === null ? {} : JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore
  }
};

export { loadState, saveState };
