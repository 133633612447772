import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span'],
      useSuspense: false,
    }
  });

export const TRANS_NAMESPACES = {
  auth: 'auth',
  common: 'common',
  createPhotobookPage: 'create_photobook_page',
  landingPage: 'landing_page',
  library: 'library',
  liveMode: 'live_mode',
  misc: 'misc',
  photobookHomepage: 'photobook_homepage',
  photobookSettings: 'photobook_settings',
  photobookNotFound: 'photobook_not_found',
  recentVisits: 'recent_visits',
  upload: 'upload',
  validation: 'validation',
  galleryViewer: 'gallery_viewer',
  notFound: 'not_found',
  termsOfUse: 'terms_of_use',
  privacyPolicy: 'privacy_policy',
  sharePhotobook: 'share_photobook'
};

export default i18next;
