import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserType from './useUserType';

const useRedirectIfNotLoggedIn = () => {
  const { isAccountHolder } = useUserType();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAccountHolder) {
      navigate('/');
    }
  }, [
    isAccountHolder,
    navigate,
  ]);
};

export default useRedirectIfNotLoggedIn;
