import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PhotobookLogo } from '../../assets/images';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setPrivacypolicyModalOpen, setTermofuseModalOpen } from '../../slices/modal';

const LibraryFooterBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.library);

  const termModalHandleClick = () => {
    dispatch(setTermofuseModalOpen());
  };

  const privacyModalHandleClick = () => {
    dispatch(setPrivacypolicyModalOpen());
  };

  return (
    <div className="fixed bottom-0 flex items-center justify-between bg-lightGrey w-full min-h-[48px] px-4 lg:px-8 py-4">
      <img className="hidden lg:block w-28" src={PhotobookLogo} alt="Photobook logo" />
      <div className="flex gap-x-8">
        <div className="text-xs text-darkGrey leading-[18px]" data-abtestid="libraryFooterBarContent">
          {t('footer_copyright', `Copyright © 2005 - ${new Date().getFullYear()} Photobook Worldwide. All rights reserved.`, { currentYear: new Date().getFullYear() })}
          &nbsp;|&nbsp;
          <span className="cursor-pointer" onClick={privacyModalHandleClick} data-abtestid="libraryFooterBarPrivacyPolicyLink">{t('footer_privacy_policy', 'Privacy Policy')}</span>
          &nbsp;|&nbsp;
          <span className="cursor-pointer" onClick={termModalHandleClick} data-abtestid="libraryFooterBarTermsOfUseLink">{t('footer_terms_of_use', 'Terms of Use')}</span>
          &nbsp;|&nbsp;
          <span className="cursor-pointer"><a href="https://forms.gle/4gPiV8oG23wUwpHM7" target="_blank" rel="noreferrer">{t('footer_contact_us', 'Contact Us')}</a></span>
        </div>
      </div>
    </div>
  );
};

export default LibraryFooterBar;
