import { useRef, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useGeneratePresignedUrlsMutation, useUploadPhotoMutation } from '../../api/photobook';
import useToast from '../../hooks/useToast';
import createGalleryUploader from './gallery-uploader';

const useGalleryUploader = () => {
  const { photobookUrlIdentifier } = useParams();
  const isProcessingRef = useRef(false);
  const pendingItemsRef = useRef([]);
  const totalItemsRef = useRef(0);
  const [generatePresignedUrls] = useGeneratePresignedUrlsMutation();
  const [uploadPhoto] = useUploadPhotoMutation();
  const { uploadProgressToast } = useToast();
  const hideUploadProgressToastRef = useRef(false);

  const onProcessedHook = (totalProcessedItems, totalItems) => {
    if (!hideUploadProgressToastRef.current) {
      uploadProgressToast(totalProcessedItems, totalItems);
    }
  };

  const setFlagToHideUploadProgress = useCallback(() => {
    if (isProcessingRef.current) {
      hideUploadProgressToastRef.current = true;
    }
  }, []);

  // To prevent infinite re-renders when galleryUploader
  // is used as a useEffect dependency somewhere else
  const galleryUploader = useMemo(() => createGalleryUploader(
    photobookUrlIdentifier,
    isProcessingRef,
    pendingItemsRef,
    generatePresignedUrls,
    uploadPhoto,
    totalItemsRef,
    onProcessedHook
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  return { galleryUploader, setFlagToHideUploadProgress };
};

export default useGalleryUploader;
