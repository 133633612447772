import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectAllIcon } from '../../assets/images';
import { FloatingButton } from '../../components/button';
import IconPaddedText from '../../components/IconPaddedText';
import { TOAST_GUTTER } from '../../components/ToastContainer';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { replaceSelection } from '../../slices/download';
import { replacePrintSelection } from '../../slices/projectCreation';
import { clearBottomOffset as clearToastContainerBottomOffset, setBottomOffset as setToastContainerBottomOffset } from '../../slices/toastContainerStyle';
import { PRINT_SELECTION_MAX_PHOTOS } from '../../utils/constants';
import { noop } from '../../utils/generic';

const SelectAllButton = ({ photos }) => {
  const selectAllButtonRef = useRef(null);
  const isLargeScreen = useLargeScreenMediaQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const inSelectionMode = useSelector((state) => state.download.inSelectionMode);
  const inPrintSelectionMode = useSelector((state) => state.projectCreation.inPrintSelectionMode);
  const selectedPrintPhotos = useSelector((state) => state.projectCreation.selectedPrintPhotos);

  const getPrintSelectAllPhotos = () => {
    const remainingPhotoCount = PRINT_SELECTION_MAX_PHOTOS - selectedPrintPhotos.length;
    return selectedPrintPhotos.concat(
      photos.filter((photo) => !selectedPrintPhotos.includes(photo.uuid))
        .slice(0, remainingPhotoCount)
        .map((photo) => photo.uuid)
    );
  };

  const selectButtonOnClick = () => {
    if (inSelectionMode) {
      dispatch(replaceSelection(photos.map((photo) => photo.uuid)));
    } else if (inPrintSelectionMode) {
      dispatch(replacePrintSelection(getPrintSelectAllPhotos()));
    }
  };

  useEffect(() => {
    if (isLargeScreen) {
      return noop;
    }

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const buttonOffset = vh - selectAllButtonRef.current.getBoundingClientRect().y;

    dispatch(setToastContainerBottomOffset(buttonOffset + TOAST_GUTTER));

    return () => dispatch(clearToastContainerBottomOffset());
  }, [
    dispatch,
    isLargeScreen,
  ]);

  return (
    <div ref={selectAllButtonRef}>
      <FloatingButton onClick={selectButtonOnClick} abTestId="selectAllButton">
        <IconPaddedText
          icon={<img className="w-5 h-5" src={SelectAllIcon} alt="select all icon" />}
          text={t('select_all', 'Select All')}
        />
      </FloatingButton>
    </div>
  );
};

export default SelectAllButton;

SelectAllButton.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    image: PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
      print: PropTypes.string
    }),
    user: PropTypes.shape({
      uuid: PropTypes.string,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  }))
};
