import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ShareIcon,
  SpecialImageIcon,
  PhotobookLiveLogo,
  TimeIcon,
} from '../../assets/images';
import { Button } from '../../components/button';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setAuthModalOpen } from '../../slices/modal';

const LandingPage = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.landingPage);
  const dispatch = useDispatch();

  return (
    <div className="flex h-full flex-col items-center gap-y-4 px-4 pt-4 lg:mx-auto lg:w-defaultLgContainerWidth">
      <div>
        <div className="flex justify-center">
          <img
            src={PhotobookLiveLogo}
            alt="Photobook Live logo"
            data-abtestid="landingPageLogo"
          />
        </div>
        <p className="text-center whitespace-pre-line">
          {t('welcome_line1', 'Capturing Moments, Sharing Memories in Real-Time. Create Your LIVE Photo Library.')}
        </p>
      </div>
      <div>
        <img
          src={`${process.env.REACT_APP_S3_ASSETS_URL}/general/landing-page-banner.jpg`}
          alt="Landing Page Banner"
          data-abtestid="landingPageBanner"
        />
      </div>
      <div className="flex flex-col gap-y-1 lg:justify-center">
        <p className="text-center font-bold lg:text-2xl lg:leading-9">
          {t('welcome_line2_title', 'Snap It, Share It, Print It')}
        </p>
        <p className="text-center whitespace-pre-line">
          {t(
            'welcome_line2',
            'With Photobook Live, we instantly create a link or QR Code for your event so you can capture the essence of your celebration. Share the link or QR Code and spare yourself the hassle of collecting photos from different chat apps and social media. Create your LIVE photo album with no fuss and then choose to print them in a personalized photobook.\nIf there’s an event worth celebrating or photos worth sharing, PBLive is your BEST platform.'
          )}
        </p>
      </div>
      <div className="flex flex-col gap-y-4 lg:flex-row lg:justify-between lg:gap-y-1 lg:gap-x-8">
        <div className="flex flex-row items-center gap-x-4 lg:w-1/3 lg:flex-col lg:gap-y-1">
          <div className="shrink-0">
            <img src={ShareIcon} alt="Share Photos" />
          </div>
          <Trans i18nKey="TRANS_NAMESPACES.landingPage">
            <p className="lg:text-center">
              {t(
                'col_1_description',
                '<strong>Upload and share</strong> photos from events.'
              )}
            </p>
          </Trans>
        </div>
        <div className="flex flex-row items-center gap-x-4 lg:w-1/3 lg:flex-col lg:gap-y-1">
          <div className="shrink-0">
            <img src={TimeIcon} alt="Real Time" />
          </div>
          <Trans i18nKey="TRANS_NAMESPACES.landingPage">
            <p className="lg:text-center">
              {t(
                'col_2_description',
                '<strong>Automatic</strong> real-time photo arrangement in chronological order with timestamp.'
              )}
            </p>
          </Trans>
        </div>
        <div className="flex flex-row items-center gap-x-4 lg:w-1/3 lg:flex-col lg:gap-y-1">
          <div className="shrink-0">
            <img src={SpecialImageIcon} alt="Download Photos" />
          </div>
          <Trans i18nKey="TRANS_NAMESPACES.landingPage">
            <p className="lg:text-center">
              {t(
                'col_3_description',
                '<strong>Download</strong> photos in high quality for slideshows, presentations and print.'
              )}
            </p>
          </Trans>
        </div>
      </div>
      <div className="sticky bottom-0 mt-auto w-screen bg-white p-4 shadow-[0px_-2px_8px_rgba(0,0,0,0.16)] lg:relative lg:m-0 lg:w-auto lg:px-0 lg:pb-4 lg:pt-0 lg:shadow-none">
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatch(setAuthModalOpen())}
          className="w-full lg:px-4"
          abTestId="letsGetStarted"
        >
          {t('lets_get_started_button', "Let's Get Started")}
        </Button>
      </div>
    </div>
  );
};

export default LandingPage;
