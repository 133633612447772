import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../api/auth';
import { PhotobookLiveLogo } from '../assets/images';
import { SubmitButton } from '../components/button';
import Card from '../components/Card';
import useToast from '../hooks/useToast';
import { TRANS_NAMESPACES } from '../services/i18next';
import { clearUser } from '../slices/auth';

const ResetPassword = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.auth);
  const { whoopsToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValidating, isSubmitting, errors },
  } = useForm();

  const [
    resetPassword,
    {
      isLoading,
      isSuccess,
      isError
    }
  ] = useResetPasswordMutation();

  const onSubmit = (data) => {
    if (data.new_password !== data.new_password_confirmation) {
      setError('new_password_confirmation', { message: t('password_does_not_match', 'Password does not match') });
      return;
    }
    const apiData = {
      email: urlParams.get('email'),
      password_reset_token: urlParams.get('token'),
      ...data
    };
    resetPassword(apiData);
  };

  useEffect(() => {
    dispatch(clearUser());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
      return;
    }

    if (isError) {
      whoopsToast();
    }
  }, [isSuccess, isError, navigate, whoopsToast]);

  return (
    <div className="flex flex-col h-full bg-cloud">
      <img className="justify-self-center self-center py-4" src={PhotobookLiveLogo} alt="Photobook Live logo" />
      <div className="flex flex-col bg-cloud h-full p-4 gap-y-4 lg:py-8 lg:px-72 lg:gap-y-8">
        <Card>
          <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="new_password" className="font-bold">{t('lets_reset_your_password', 'Let\'s Reset Your Password')}</label>
            <div className="lg:grid gap-x-4 grid-row-2 pb-4">
              <input {...register('new_password', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })} className={`w-full py-3 px-4 mt-4 border rounded-lg ${errors.new_password ? 'border-blush' : 'border-grey'}`} type="password" placeholder={t('new_password', 'New Password')} autoComplete="new-password" data-abtestid="newPassword" />
              {errors.new_password && <div className="mt-2 text-blush">{errors.new_password.message}</div>}
              <input {...register('new_password_confirmation', { required: t('required_field_validator', 'This field is required.', { ns: TRANS_NAMESPACES.validation }) })} className={`w-full py-3 px-4 mt-4 border rounded-lg ${errors.new_password_confirmation ? 'border-blush' : 'border-grey'}`} type="password" placeholder={t('retype_password', 'Retype Password')} autoComplete="new-password" data-abtestid="newPasswordConfirmation" />
              {errors.new_password_confirmation && <div className="mt-2 text-blush">{errors.new_password_confirmation.message}</div>}
            </div>
            <SubmitButton className="ml-4 w-44 self-end" variant="contained" color="primary" disabled={isLoading || isValidating || isSubmitting} abTestId="resetPasswordSubmit">
              {t('reset_password', 'Reset Password')}
            </SubmitButton>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
