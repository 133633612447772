import { createSlice } from '@reduxjs/toolkit';

const liveModeCacheSlice = createSlice({
  name: 'liveModeCache',
  initialState: {
    historyList: [],
    currentIterationList: []
  },
  reducers: {
    setCurrentIterationList: (state, { payload }) => ({ ...state, currentIterationList: payload }),
    setHistoryList: (state, { payload }) => ({ ...state, historyList: payload }),
    clearLiveModeCache: (state) => ({ ...state, historyList: [], currentIterationList: [] }),
  },
});

export const {
  setExistingList,
  setCurrentIterationList,
  setHistoryList,
  clearLiveModeCache
} = liveModeCacheSlice.actions;

export default liveModeCacheSlice;
