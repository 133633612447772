import baseApi from './base';

const accountApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    userInfo: builder.query({
      query: () => ({
        url: '/user',
        method: 'GET'
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: '/user',
        method: 'PUT',
        body: data,
      }),
    })
  }),
});

export const {
  useUserInfoQuery,
  useUpdateUserMutation
} = accountApi;
