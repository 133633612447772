import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PlayIcon } from '../../assets/images';
import IconPaddedText from '../../components/IconPaddedText';
import { TRANS_NAMESPACES } from '../../services/i18next';

const LiveModeButton = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('livemode')}
      type="button"
      data-abtestid="ellipsisMenuLiveModeButton"
    >
      <IconPaddedText
        className="text-charcoal font-bold"
        icon={<img className="w-5 h-5" src={PlayIcon} alt="Live mode" />}
        text={t('photo_gallery_live_mode', 'Live Mode')}
      />
    </button>
  );
};

export default LiveModeButton;
