import { CarouselProvider } from 'pure-react-carousel';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetPhotobookQuery } from '../api/photobook';
import {
  LiveMode, liveModeAlgorithm, LiveModeSlideChangedListener, LiveModeTransitionPage
} from '../features/live-mode';
import {
  setIsPlaying,
  setCurrList,
  setPhotoIndex,
  setUndisplayedList,
  setPlayTimeout
} from '../slices/liveMode';
import { setCurrentIterationList, setHistoryList } from '../slices/liveModeCache';
import { LIVE_MODE_MAX_PHOTOS } from '../utils/constants';

const PhotobookLiveMode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { photobookUrlIdentifier } = useParams();
  const useRefNow = useRef(null); // this is somehow needed to trigger the timeout useEffect
  const photoList = useRef([]);
  const apiList = useRef([]);
  const currentIterationList = useSelector((state) => state.liveModeCache.currentIterationList);
  const historyList = useSelector((state) => state.liveModeCache.historyList);
  const undisplayedList = useSelector((state) => state.liveMode.undisplayedList);
  const currList = useSelector((state) => state.liveMode.currList);
  const isPlaying = useSelector((state) => state.liveMode.isPlaying);
  const photoIndex = useSelector((state) => state.liveMode.photoIndex);
  const playTimeout = useSelector((state) => state.liveMode.playTimeout);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const updatePhotoListTimeout = useRef(null);
  const UPDATE_PHOTO_LIST_TIMEOUT = 3000;
  const UPDATE_PHOTO_LIST_ERROR_TIMEOUT = 0;
  const TRANSITION_PAGE_DURATION = 1000;
  const {
    data,
    isLoading,
    isSuccess,
  } = useGetPhotobookQuery({ photobookUrlIdentifier }, { pollingInterval: 5000 });

  useEffect(() => {
    const element = document.getElementById('PhotobookLiveMode');
    if (element.requestFullscreen) { /* Chrome */
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullscreen) { /* Firefox */
      element.mozRequestFullscreen();
    }
  }, []);

  useEffect(() => {
    let eventName = '';
    if (document.fullscreenElement !== undefined) {
      eventName = 'fullscreenchange';
    } else if (document.webkitFullscreenElement !== undefined) {
      eventName = 'webkitfullscreenchange';
    } else if (document.mozFullscreenElement !== undefined) {
      eventName = 'mozfullscreenchange';
    }

    const closeLiveMode = () => {
      if (document.fullscreenElement === null
        || document.webkitFullscreenElement === null
        || document.mozFullscreenElement === null) {
        navigate(`/photobook/${photobookUrlIdentifier}`);
      }
    };
    window.addEventListener(eventName, closeLiveMode);
    return () => window.removeEventListener(eventName, closeLiveMode);
  }, [navigate, photobookUrlIdentifier]);

  useEffect(() => {
    const transitionTimeout = setTimeout(
      () => { setIsTransitioning(false); },
      TRANSITION_PAGE_DURATION
    );
    return () => clearTimeout(transitionTimeout);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (data.data.photobook.images.list.length > 0) {
        apiList.current = [...data.data.photobook.images.list];
        if (photoList.current.length === 0) {
          const [
            newExistingList,
            newHistoryList,
            newUndisplayedList,
            newCurrList,
            newCurrentIterationList,
          ] = liveModeAlgorithm(
            apiList.current,
            currentIterationList,
            historyList,
            undisplayedList,
            currList
          );
          newExistingList.slice(-1).forEach((photo) => {
            const img = new Image();
            img.onerror = () => {
              apiList.current = apiList.current.filter(
                (apiPhoto) => apiPhoto.uuid !== photo.uuid
              );
              dispatch(setUndisplayedList(newUndisplayedList));
              dispatch(setCurrList(newCurrList));
              dispatch(setPlayTimeout(UPDATE_PHOTO_LIST_ERROR_TIMEOUT));
              photoList.current = historyList.concat(currentIterationList);
              dispatch(setPhotoIndex(historyList.length + currentIterationList.length - 1));
            };
            img.onload = () => {
              photoList.current = newHistoryList.concat(newExistingList);
              dispatch(setPhotoIndex(newHistoryList.length + newExistingList.length - 1));
              dispatch(setCurrentIterationList(newCurrentIterationList));
              dispatch(setHistoryList(newHistoryList));
              dispatch(setUndisplayedList(newUndisplayedList));
              dispatch(setCurrList(newCurrList));
              dispatch(setPlayTimeout(UPDATE_PHOTO_LIST_TIMEOUT));
            };
            img.src = photo.image.print;
          });
        }
      }
    }
  // this is needed to prevent adding liveMode and liveModeCache lists into dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSuccess,
    data,
    navigate,
    dispatch
  ]);

  useEffect(() => {
    // TODO: Rewrite timeout logic -- possible reference: https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
    updatePhotoListTimeout.current = setTimeout(() => {
      const previousPhotoListLength = photoList.current.length;
      const [
        newExistingList,
        newHistoryList,
        newUndisplayedList,
        newCurrList,
        newCurrentIterationList
      ] = liveModeAlgorithm(
        apiList.current,
        currentIterationList,
        historyList,
        undisplayedList,
        currList
      );
      newExistingList.slice(-1).forEach((photo) => {
        const img = new Image();
        img.onerror = () => {
          apiList.current = apiList.current.filter(
            (apiPhoto) => apiPhoto.uuid !== photo.uuid
          );
          useRefNow.current = Math.ceil(Math.random() * 10000);
          dispatch(setUndisplayedList(newUndisplayedList));
          dispatch(setCurrList(newCurrList));
          dispatch(setPlayTimeout(UPDATE_PHOTO_LIST_ERROR_TIMEOUT));
        };
        img.onload = () => {
          photoList.current = newHistoryList.concat(newExistingList);
          dispatch(setCurrentIterationList(newCurrentIterationList));
          dispatch(setHistoryList(newHistoryList));
          dispatch(setUndisplayedList(newUndisplayedList));
          dispatch(setCurrList(newCurrList));
          dispatch(setPlayTimeout(UPDATE_PHOTO_LIST_TIMEOUT));

          // when there are photos deleted, new list will contain lesser photos
          // photo index = last index, if index >= new list length
          // photo index stays, if index < new list length, regardless of playing or not
          if (previousPhotoListLength > photoList.current.length) {
            if (photoIndex >= photoList.current.length) {
              dispatch(setPhotoIndex(photoList.current.length - 1));
              return;
            }
            return;
          }

          // if < max photos, pause = index stay, play = index + 1
          // if max photos, pause = index - 1, play = index stay
          if (previousPhotoListLength < LIVE_MODE_MAX_PHOTOS) {
            if (isPlaying) {
              if (photoIndex + 1 < photoList.current.length) {
                dispatch(setPhotoIndex(photoIndex + 1));
              }
            }
            return;
          }

          if (!isPlaying) {
            const newPhotoIndex = photoIndex - 1;
            if (newPhotoIndex <= 0) {
              dispatch(setIsPlaying(true));
            }
            if (newPhotoIndex >= 0) {
              dispatch(setPhotoIndex(newPhotoIndex));
            }
          }
        };
        img.src = photo.image.print;
      });
    }, playTimeout);

    return () => {
      clearTimeout(updatePhotoListTimeout.current);
    };
  }, [
    isPlaying,
    dispatch,
    photoIndex,
    currentIterationList,
    historyList,
    undisplayedList,
    currList,
    playTimeout,
    useRefNow
  ]);

  return (
    <div className="h-full" id="PhotobookLiveMode">
      {isLoading || isTransitioning
        ? <LiveModeTransitionPage />
        : (
          <div className="h-full bg-black">
            <CarouselProvider
              className="bg-black"
              naturalSlideWidth={window.innerWidth}
              naturalSlideHeight={window.innerHeight}
              totalSlides={photoList.current.length}
              currentSlide={photoIndex}
              touchEnabled={false}
              dragEnabled={false}
            >
              <LiveModeSlideChangedListener />
              <LiveMode
                currentPhotoIndex={photoIndex}
                photoList={photoList.current}
              />
            </CarouselProvider>
          </div>
        )}
    </div>
  );
};

export default PhotobookLiveMode;
