import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user, guest } = getState().auth;
      if (endpoint === 'uploadPhoto' || endpoint === 'resetPassword') {
        return headers;
      }
      if (user.token) {
        headers.set('Authorization', `Bearer ${user.token}`);
      } else if (guest.token) {
        headers.set('X-Guest-Token', guest.token);
      }
      return headers;
    },
  }),
  endpoints: () => ({})
});

export default baseApi;
