import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlueTickIcon,
  SortIcon
} from '../../assets/images';
import { Button } from '../../components/button';
import IconPaddedText from '../../components/IconPaddedText';
import { TOAST_GUTTER } from '../../components/ToastContainer';
import useOutsideElementClick from '../../hooks/useOutsideElementClick';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { setSortOption } from '../../slices/photobook';
import { clearBottomOffset as clearToastContainerBottomOffset, setBottomOffset as setToastContainerBottomOffset } from '../../slices/toastContainerStyle';

const SortOptionsMobile = () => {
  const [defaultOffset, setDefaultOffset] = useState(0);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState('asc');
  const previousOffset = useSelector((state) => state.toastContainerStyle.bottom);
  const elementRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  useOutsideElementClick(elementRef, () => setShowSortOptions(false));

  useEffect(() => {
    if (showSortOptions) {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const dropUpOffset = vh - elementRef.current.getBoundingClientRect().y;

      dispatch(setToastContainerBottomOffset(dropUpOffset + TOAST_GUTTER));
    } else {
      dispatch(setToastContainerBottomOffset(defaultOffset));
    }

    return () => dispatch(clearToastContainerBottomOffset());
  }, [defaultOffset, dispatch, showSortOptions]);

  const handleClick = () => {
    if (defaultOffset === 0) {
      setDefaultOffset(previousOffset);
    }
    setShowSortOptions((previousShowSortOptions) => !previousShowSortOptions);
  };

  const sortImages = (sortOption) => {
    dispatch(setSortOption(sortOption));
  };

  const sortOptionAsc = t('photo_gallery_time_taken_ascending', 'Time Taken (Ascending)');
  const sortOptionDesc = t('photo_gallery_time_taken_descending', 'Time Taken (Descending)');
  const isSortAsc = selectedSorting === 'asc';

  return (
    <div className="contents">
      <div className="flex items-center ml-auto" onClick={handleClick}>
        <img className="my-3" src={SortIcon} alt="sort icon" />
      </div>
      {
        showSortOptions && (
          <div className="fixed bg-black/50 inset-0 z-50">
            <div ref={elementRef} className="absolute w-full max-h-1/2 landscape:h-full p-4 bg-white bottom-0 left-1/2 rounded-t-lg shadow-custom translate-x-[-50%] z-50" data-abtestid="sortOptionsMobile">
              <p className="text-charcoal font-bold">{t('sorting', 'Sorting')}</p>
              <div className={`flex my-6 hover:cursor-pointer ${isSortAsc ? 'text-photobookBlue' : ''}`} onClick={() => setSelectedSorting('asc')} data-abtestid="sortOptionsMobileAsc">
                <IconPaddedText
                  className={`${!isSortAsc ? '' : 'font-bold'}`}
                  icon={<img className={`${isSortAsc ? '' : 'invisible'} mr-3`} src={BlueTickIcon} alt="sort asc selected" />}
                  text={sortOptionAsc}
                />
              </div>
              <div className={`flex my-6 hover:cursor-pointer ${!isSortAsc ? 'text-photobookBlue' : ''}`} onClick={() => setSelectedSorting('desc')} data-abtestid="sortOptionsMobileDesc">
                <IconPaddedText
                  className={`${isSortAsc ? '' : 'font-bold'}`}
                  icon={<img className={`${!isSortAsc ? '' : 'invisible'} mr-3`} src={BlueTickIcon} alt="sort desc selected" />}
                  text={sortOptionDesc}
                />
              </div>
              <Button
                type="button"
                color="primary"
                variant="contained"
                className="w-full my-2"
                abTestId="sortOptionMobileApply"
                onClick={() => sortImages(selectedSorting)}
              >
                {t('photo_gallery_apply', 'Apply')}
              </Button>

              <Button
                type="button"
                color="grey"
                variant="contained"
                className="w-full my-2"
                abTestId="sortOptionMobileClose"
                onClick={() => setShowSortOptions(false)}
              >
                {t('photo_gallery_close', 'Close')}
              </Button>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default SortOptionsMobile;
