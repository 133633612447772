const identifierStartIndexes = {
  jpg: 0,
  png: 0,
  heic: 8,
};

const identifierEndIndexes = {
  jpg: 5,
  png: 7,
  heic: 23,
};

const generateHexString = (uint8Array) => uint8Array.reduce((acc, curr) => acc + curr.toString(16).padStart(2, '0'), '');

const isUnprocessableFile = (header) => {
  const identifiers = [
    // unprocessable by heic2any library
    '00000018667479706d69663100000000686569636d6966310000021b6d657461',
    '00000018667479706d69663100000000686569636d696631000002826d657461',
    '00000018667479706d69663100000000686569636d696631000003176d657461',
    '0000001c667479706d736631000000006d7366316865766369736f38000002e8',
    '0000001c667479706d736631000000006d7366316865766369736f3800000386',
    '0000001c667479706d736631000000006d7366316865766369736f3800000318',
    '00000020667479706d736631000000006d7366316865766369736f386d703431',
    '0000001c667479706d736631000000006d7366316865766369736f380000056f',
    '0000001c667479706d736631000000006d7366316865766369736f380000030c',
    '00000018667479706d696631000000006d69663168656963000002006d657461',
    '0000001c667479706d736631000000006d7366316865766369736f38000003c0',
    '00000020667479706d69663100000000686569636d6966316d6961664d694841',
    '0000001c6674797068656973000000006d69663168656973617663690000025f',
  ];

  return identifiers.includes(header);
};

const isJpg = (header) => {
  // https://en.wikipedia.org/wiki/JPEG
  const identifier = 'ffd8ff';

  const foundIdentifier = header.slice(
    identifierStartIndexes.jpg,
    identifierEndIndexes.jpg + 1
  );

  return foundIdentifier === identifier;
};

const isPng = (header) => {
  // https://gist.github.com/leommoore/f9e57ba2aa4bf197ebc5#image-files
  const identifier = '89504e47';

  const foundIdentifier = header.slice(
    identifierStartIndexes.png,
    identifierEndIndexes.png + 1
  );

  return foundIdentifier === identifier;
};

const isHeic = (header) => {
  // https://github.com/nokiatech/heif/issues/74#issuecomment-629595062
  const identifiers = [
    '6674797068656963', // ftypheic
    '6674797068656973', // ftypheis
    '6674797068657663', // ftyphevc
    '667479706d696631', // ftypmif1
    '667479706d696632', // ftypmif2
    '667479706d736631', // ftypmsf1
  ];

  const foundIdentifier = header.slice(
    identifierStartIndexes.heic,
    identifierEndIndexes.heic + 1
  );

  return identifiers.includes(foundIdentifier);
};

const checkFiles = async (files) => {
  const validFiles = [];
  const unprocessableFiles = [];

  // get the first 32 bytes from all files
  const arrayBuffers = await Promise.all(files.map((file) => file.slice(0, 32).arrayBuffer()));

  // convert to hex
  const headers = arrayBuffers.map((arrayBuffer) => generateHexString(new Uint8Array(arrayBuffer)));

  for (let i = 0; i < headers.length; i++) {
    const header = headers[i];

    if (isUnprocessableFile(header)) {
      unprocessableFiles.push(files[i]);
    } else if (isJpg(header) || isPng(header) || isHeic(header)) {
      validFiles.push(files[i]);
    }
  }

  return { validFiles, unprocessableFiles };
};

export default checkFiles;
