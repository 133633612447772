import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAuthModalClose,
  setSendForgotPasswordEmailModalClose,
  setSentForgotPasswordEmailModalClose,
  setGuestModalClose,
  setTermofuseModalClose,
  setPrivacypolicyModalClose,
} from '../../slices/modal';
import AuthModal from '../auth/AuthModal';
import GuestRegistrationModal from '../auth/GuestRegistrationModal';
import MergeModal from '../auth/MergeModal';
import SendForgotPasswordEmailModal from '../auth/SendForgotPasswordEmailModal';
import SentForgotPasswordEmailModal from '../auth/SentForgotPasswordEmailModal';
import PrintErrorModal from '../print/PrintErrorModal';
import PrintLoadingModal from '../print/PrintLoadingModal';
import PrintOddPhotoModal from '../print/PrintOddPhotoModal';
import PrintPhotoMaxLimitModal from '../print/PrintPhotoMaxLimitModal';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import TermsOfUseModal from './TermsOfUseModal';

const ModalBase = () => {
  const authModaltab = useSelector((state) => state.modal.authModalTab);
  const authModalOpen = useSelector((state) => state.modal.authModalOpen);
  const sendForgotPasswordEmailModalOpen = useSelector(
    (state) => state.modal.sendForgotPasswordEmailModalOpen
  );
  const sentForgotPasswordEmailModalOpen = useSelector(
    (state) => state.modal.sentForgotPasswordEmailModalOpen
  );
  const guestModalOpen = useSelector((state) => state.modal.guestModalOpen);
  const mergeModalOpen = useSelector((state) => state.modal.mergeModalOpen);
  const termsofuseModalOpen = useSelector((state) => state.modal.termsOfUseModalOpen);
  const privacypolicyModalOpen = useSelector((state) => state.modal.privacyPolicyModalOpen);
  const printPhotoMaxLimitModalOpen = useSelector(
    (state) => state.modal.printPhotoMaxLimitModalOpen
  );
  const printLoadingModalOpen = useSelector((state) => state.modal.printLoadingModalOpen);
  const printErrorModalOpen = useSelector((state) => state.modal.printErrorModalOpen);
  const printOddPhotosModalOpen = useSelector((state) => state.modal.printOddPhotosModalOpen);
  const dispatch = useDispatch();
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

  useEffect(() => {
    if (guestModalOpen && authModalOpen) {
      dispatch(setGuestModalClose());
    }
  }, [guestModalOpen, authModalOpen, dispatch]);

  useEffect(() => {
    if (authModalOpen && sendForgotPasswordEmailModalOpen) {
      dispatch(setAuthModalClose());
    }
  }, [authModalOpen, sendForgotPasswordEmailModalOpen, dispatch]);

  return (
    <div id="MODAL_BASE">
      <AuthModal
        open={authModalOpen}
        handleClose={() => dispatch(setAuthModalClose())}
        targetTab={authModaltab}
      />
      <SendForgotPasswordEmailModal
        open={sendForgotPasswordEmailModalOpen}
        handleClose={() => dispatch(setSendForgotPasswordEmailModalClose())}
        setForgotPasswordEmail={setForgotPasswordEmail}
      />
      <SentForgotPasswordEmailModal
        open={sentForgotPasswordEmailModalOpen}
        handleClose={() => dispatch(setSentForgotPasswordEmailModalClose())}
        forgotPasswordEmail={forgotPasswordEmail}
      />
      <MergeModal
        open={mergeModalOpen}
      />
      <GuestRegistrationModal
        open={guestModalOpen}
        closeModal={() => dispatch(setGuestModalClose())}
      />
      <TermsOfUseModal
        open={termsofuseModalOpen}
        closeModal={() => dispatch(setTermofuseModalClose())}
      />
      <PrivacyPolicyModal
        open={privacypolicyModalOpen}
        closeModal={() => dispatch(setPrivacypolicyModalClose())}
      />
      <PrintPhotoMaxLimitModal
        open={printPhotoMaxLimitModalOpen}
      />
      <PrintLoadingModal
        open={printLoadingModalOpen}
      />
      <PrintErrorModal
        open={printErrorModalOpen}
      />
      <PrintOddPhotoModal
        open={printOddPhotosModalOpen}
      />
    </div>
  );
};

export default ModalBase;
