import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../api/auth';
import { BlueProfileIcon, CharcoalProfileIcon } from '../../assets/images';
import useOutsideElementClick from '../../hooks/useOutsideElementClick';
import usePromptWhenUploading from '../../hooks/usePromptWhenUploading';
import useUserType from '../../hooks/useUserType';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { clearUser } from '../../slices/auth';
import { setAuthModalOpen } from '../../slices/modal';
import { GalleryUploaderContext } from '../gallery-upload';

const MENU_ITEM_CLASS = 'px-4 py-4 bg-white hover:cursor-pointer';

const AccountDropdown = () => {
  const { t } = useTranslation(TRANS_NAMESPACES.common);
  const { isAccountHolder } = useUserType();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const elementRef = useRef(null);
  useOutsideElementClick(elementRef, () => setShowAccountMenu(false));
  const galleryUploader = useContext(GalleryUploaderContext);
  const { promptWhenUploadingPhoto } = usePromptWhenUploading(galleryUploader);

  const handleClick = () => {
    if (isAccountHolder) {
      setShowAccountMenu(!showAccountMenu);
    } else {
      dispatch(setAuthModalOpen());
    }
  };

  const [logout] = useLogoutMutation();

  const doLogout = () => {
    logout();
    dispatch(clearUser());
    navigate('/');
  };

  return (
    <div ref={elementRef} className="h-5 relative">
      <button type="button" onClick={handleClick} className="h-full" data-abtestid="account">
        <img className="min-w-[20px] w-5 h-5 hover:cursor-pointer" src={showAccountMenu ? BlueProfileIcon : CharcoalProfileIcon} alt="account" />
      </button>
      {showAccountMenu && (
        <div className="absolute flex flex-col right-0 z-10 w-60 mt-2 rounded shadow">
          <Link to="/account" onClick={promptWhenUploadingPhoto()} className={MENU_ITEM_CLASS} data-abtestid="accountInformationLink">{t('account_information', 'Account Information')}</Link>
          <div onClick={promptWhenUploadingPhoto(doLogout)} className={MENU_ITEM_CLASS} data-abtestid="logoutLink">{t('logout', 'Logout')}</div>
        </div>
      )}
    </div>
  );
};

export default AccountDropdown;
