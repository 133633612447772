import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DeselectAllIcon } from '../../assets/images';
import { FloatingButton } from '../../components/button';
import IconPaddedText from '../../components/IconPaddedText';
import { TOAST_GUTTER } from '../../components/ToastContainer';
import useLargeScreenMediaQuery from '../../hooks/useLargeScreenMediaQuery';
import { TRANS_NAMESPACES } from '../../services/i18next';
import { clearSelection } from '../../slices/download';
import { clearPrintSelection } from '../../slices/projectCreation';
import { clearBottomOffset as clearToastContainerBottomOffset, setBottomOffset as setToastContainerBottomOffset } from '../../slices/toastContainerStyle';
import { noop } from '../../utils/generic';

const DeselectAllButton = () => {
  const deselectAllButtonRef = useRef(null);
  const isLargeScreen = useLargeScreenMediaQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANS_NAMESPACES.photobookHomepage);
  const inSelectionMode = useSelector((state) => state.download.inSelectionMode);
  const inPrintSelectionMode = useSelector((state) => state.projectCreation.inPrintSelectionMode);

  const deselectButtonOnClick = () => {
    if (inSelectionMode) {
      dispatch(clearSelection());
    } else if (inPrintSelectionMode) {
      dispatch(clearPrintSelection());
    }
  };

  useEffect(() => {
    if (isLargeScreen) {
      return noop;
    }

    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const buttonOffset = vh - deselectAllButtonRef.current.getBoundingClientRect().y;

    dispatch(setToastContainerBottomOffset(buttonOffset + TOAST_GUTTER));

    return () => dispatch(clearToastContainerBottomOffset());
  }, [
    dispatch,
    isLargeScreen,
  ]);

  return (
    <div ref={deselectAllButtonRef}>
      <FloatingButton onClick={deselectButtonOnClick} abTestId="deselectAllButton">
        <IconPaddedText
          icon={<img className="w-5 h-5" src={DeselectAllIcon} alt="deselect all icon" />}
          text={t('deselect_all', 'Deselect All')}
        />
      </FloatingButton>
    </div>
  );
};

export default DeselectAllButton;
